import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/core/services/helper.service';
import { UserService } from '../../user.service';
import { ConfirmationService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';

interface link {
  name: string,
  value: any
}
@Component({
  selector: 'app-crew-media',
  templateUrl: './crew-media.component.html',
  styleUrls: ['./crew-media.component.scss']
})

export class CrewMediaComponent implements OnInit {
  activeTab: number = 0;
  photosForm: FormGroup;
  videosForm: FormGroup;
  voiceOverForm: FormGroup;
  submittedPhotosForm: boolean = false;
  submittedVideosForm: boolean = false;
  submittedVoiceOverForm: boolean = false;
  items: any = [];
  feature: any = [];
  imageFeature: any = []
  uploadedFiles: any[] = [];
  imageData: any;
  imageName: any;
  imageType: any;
  loading: boolean = true;
  videoData: any;
  videoName: any;
  voiceData: any;
  voiceName: any;
  urls: any[] = [];
  public file: any;
  imageDataList: any = []
  audioDataList: any = []
  videoDataList: any = []
  mediaType: any;
  mediaName: any
  imgLoader: boolean = false
  videoLoader: boolean = false
  audioLoader: boolean = false
  selectedItem: any = null;
  mediaId: any
  id: any = []
  btnTitle: string = 'Save'
  description: any
  photoDiv: boolean = false;
  videoDiv: boolean = false;
  voiceDiv: boolean = false;
  pageLoader: boolean = false;
  addImageBtn: boolean = false;
  addVideoBtn: boolean = false;
  addAudioBtn: boolean = false
  photoFormDiv: boolean = false
  videoFormDiv: boolean = false
  audioFormDiv: boolean = false
  selectedValue: string = 'val1';
  linkType: link[]
  videoLink: any
  audioLink: any
  displayCustom: boolean = false;
  activeIndex: number = 0;
  photos: any = []
  voiceDisable = false
  videoDisable = false
  photoDisable = false
  safeUrl: any
  mediaDetails: any = {}
  youTubePlayListLink : any
  youTubePlayListLinkType : any
  validLinkError: boolean = false

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  @Input() data: any;

  @ViewChild('fileUpload') fileUpload: any;

  /**
  * @constructor
  * @param {UserService} userService - The subservice service for call API.
  * @param {HelperService} helper - The helper service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {FormBuilder} formBuilder - FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.  
  * @param {ConfirmationService} confirmationService - Confirmation services is required to display it by calling confirm method.
  * @param {DomSanitizer} sanitizer - turning it into a value that's safe to insert into the DOM.
  */
  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer
  ) {
    // for dropdown in video
    this.linkType = [
      { name: 'YouTube', value: 1 },
      { name: 'Vimeo', value: 2 },
      { name: 'Other', value: 3 },

    ];
    this.items = [
      { label: 'Photos', page: 'Photos', number: 0 },
      { label: 'Videos', page: 'Videos', number: 1 },
      { label: 'Voice Over', page: 'Voice Over', number: 2 },
    ]
    // for form declaration.
    this.photosForm = this.formBuilder.group({
      title: new FormControl(''),
      description: new FormControl(''),
    })

    this.videosForm = this.formBuilder.group({
      title: new FormControl(''),
      description: new FormControl(''),
      uploadVideo: new FormControl(1),
      addLink: new FormControl(''),
      linkType: new FormControl(''),
      link: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
    })

    this.voiceOverForm = this.formBuilder.group({
      title: new FormControl(''),
      description: new FormControl(''),
      linkType: new FormControl("3"),
      link: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      uploadAudio: new FormControl(1),
    })

  }

  listClick(event: any, newValue: any) {
    this.activeTab = newValue.number;
    sessionStorage.setItem('selectedItemTabMedia', JSON.stringify(this.activeTab))
  }

  ngOnInit(): void {

    this.feature = [
      {
        label: 'Set as featured', page: 'Featured', number: 0, command: () => {
          this.featuredImage(this.selectedItem.id, this.selectedItem.mediaType)
        }
      },

      {
        label: 'Edit title / Description', page: 'Edit', number: 1, command: () => {
          this.updateMediaDetails(this.selectedItem.id)
        }
      },
      {
        label: 'Delete', page: 'Delete', number: 2, command: () => {
          this.deleteMedia(this.selectedItem.id)
        }
      },
    ];
    this.imageFeature = [
      {
        label: 'Edit title / Description', page: 'Edit', number: 1, command: () => {
          this.updateMediaDetails(this.selectedItem.id)
        }
      },
      {
        label: 'Delete', page: 'Delete', number: 2, command: () => {
          this.deleteMedia(this.selectedItem.id)
        }
      },
    ];

    this.getMediaDetails()
  }

   // for form control
  get f() { return this.photosForm.controls; }
  get g() { return this.videosForm.controls; }
  get h() { return this.voiceOverForm.controls; }

  onLoad() {
    this.loading = false;
  }

  //for image gallery
  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
    this.pageLoader = true
    setTimeout(() => {
      this.pageLoader = false;
      },2000)
  }

  //for photo upload event for upload image
  onUpload(event: any) {
    this.imageData = ''
    this.imgLoader = true;
    this.photoDisable = false
    if(event.currentFiles.length == 0){
      this.imgLoader = false
      this.photoDisable = true
      return
    }
    let file = event.currentFiles[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.helper.uploadFile(formData, 4).subscribe(data => {
      this.imgLoader = false;
      this.photoDisable = false
      this.imageData = data.url
      this.imageName = data.filename
      this.loading = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'File added, now press the save button to save it'
      });
    }, (error: any) => {
      this.imgLoader = false
      this.photoDisable = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }

  // for add photo use 
  addMediaPhotoData() {
    this.submittedPhotosForm = true;
    if (this.photosForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return
    }
    if (this.imageData == null || this.imageData == '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please upload an image'
      });
      return
    }
    let obj: any = {}
    // obj = this.photosForm.value
    obj['title'] = this.photosForm.value.title == null ? '' : this.photosForm.value.title
    if (this.photosForm.value.description !== null || this.photosForm.value.description == '') {
      obj['description'] = this.photosForm.value.description
    }
    obj['mediaName'] = this.imageName
    obj['mediaType'] = 1
    if (this.mediaId) {
      this.userService.updateMediaData(this.mediaId, obj).subscribe((data) => { //for update image use 
        this.submittedPhotosForm = false;
        this.mediaId = ''
        this.btnTitle = 'Save'
        this.photosForm.reset()
        this.fileUpload.clear();
        this.getMediaDetails()
        this.photoDiv = false;
        this.addImageBtn = true
        this.imageData = ''
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Record updated successfully'
        });

      }, (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    } else {
      this.userService.addMediaPhotoInfo(obj).subscribe((data) => {
        this.submittedPhotosForm = false;
        this.photosForm.reset()
        this.fileUpload.clear();
        this.getMediaDetails()
        this.addImageBtn = true
        this.imageData = ''
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Record updated successfully'
        });

      }, (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    }

  }

  //for video upload event use
  onUploadVideo(event: any) {
    this.videoData = ''
    this.videoLoader = true;
    this.videoDisable = false
    if(event.currentFiles.length == 0){
      this.videoLoader = false
      this.videoDisable = true
      return
    }
    let file = event.currentFiles[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.helper.uploadFile(formData, 5).subscribe(data => {
      this.videoLoader = false
      this.videoDisable = false
      this.videoData = data.url
      this.videoName = data.filename
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'File added, now press the save button to save it'
      });
    }, (error: any) => {
      this.videoLoader = false
      this.videoDisable = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  
  //for add video use
  addMediaVideoData() {
    this.submittedVideosForm = true;
    if (this.videosForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return
    }
    if (this.videosForm.value.uploadVideo == 1) {
      if (this.videoData == null || this.videoData == '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please upload a video'
        });
        return
      }
    } else {
      if (this.videosForm.value.link == null || this.videosForm.value.link == '' || this.videosForm.value.linkType == null || this.videosForm.value.linkType == '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a link and link type'
        });
        return
      }
    }

  if(this.validLinkError == true){
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Please provide a valid link'
    });
      return
    }
     
    let obj: any = {}
    obj['title'] = this.videosForm.value.title == null ? '' : this.videosForm.value.title
    if (this.videosForm.value.description !== null || this.videosForm.value.description == '') {
      obj['description'] = this.videosForm.value.description
    }
    // obj['description'] =  this.videosForm.value.description
    if (this.videosForm.value.uploadVideo == 1) {
      obj['mediaName'] = this.videoName
      obj['mediaType'] = 2
    } else {
      obj['mediaName'] = this.videosForm.value.link
      obj['mediaType'] = 4
      obj['linkType'] = parseInt(this.videosForm.value.linkType)
    }
    // console.log(obj)
    // return
    if (this.mediaId) {
      this.userService.updateMediaData(this.mediaId, obj).subscribe((data) => {
        this.submittedVideosForm = false;
        this.mediaId = ''
        this.btnTitle = 'Save'
        this.videosForm.reset()
        this.addVideoBtn = true
        this.videoData = ''
        this.videoDiv = false;
        this.videoLink == null ? this.fileUpload.clear() : ''
        this.videoLink = ''
        this.getMediaDetails()
      }, (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    } else {
      this.userService.addMediaVideoInfo(obj).subscribe((data) => {
        this.submittedVideosForm = false;
        this.videosForm.reset()
        obj['mediaType'] == 2 ? this.fileUpload.clear() : ''
        this.getMediaDetails()
        this.addVideoBtn = true
        this.videoData = ''
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Record updated successfully'
        });
      }, (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    }

  }

  //for voice upload event use
  onUploadVoice(event: any) {
    this.voiceData = ''
    this.audioLoader = true
    this.voiceDisable = false
    if(event.currentFiles.length == 0){
      this.audioLoader = false
      this.voiceDisable = true
      return
    }
    let file = event.currentFiles[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.helper.uploadFile(formData, 6).subscribe(data => {
      this.audioLoader = false
      this.voiceDisable = false
      this.voiceData = data.url
      this.voiceName = data.filename
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'File added, now press the save button to save it'
      });
    }, (error: any) => {
      this.audioLoader = false
      this.voiceDisable = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  // for add voice/audio use 
  addMediaVoiceData() {
    this.submittedVoiceOverForm = true;
    if (this.voiceOverForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return
    }
    if (this.voiceOverForm.value.uploadAudio == 1) {
      if (this.voiceData == null || this.voiceData == '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please upload a audio'
        });
        return
      }
    } else {
      if (this.voiceOverForm.value.link == null || this.voiceOverForm.value.link == '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a link'
        });
        return
      }
    }
    let obj: any = {}
    obj['title'] = this.voiceOverForm.value.title == null ? '' : this.voiceOverForm.value.title
    if (this.voiceOverForm.value.description !== null || this.voiceOverForm.value.description == '') {
      obj['description'] = this.voiceOverForm.value.description
    }
    if (this.voiceOverForm.value.uploadAudio == 1) {
      obj['mediaName'] = this.voiceName
      obj['mediaType'] = 3
    } else {
      obj['mediaName'] = this.voiceOverForm.value.link
      obj['mediaType'] = 5
      obj['linkType'] = 3
    }
    // console.log(obj)
    // return
    if (this.mediaId) {
      this.userService.updateMediaData(this.mediaId, obj).subscribe((data) => {   //update voice /audio
        this.submittedVoiceOverForm = false;
        this.mediaId = ''
        this.btnTitle = 'Save'
        this.voiceOverForm.reset()
        this.voiceDiv = false;
        this.addAudioBtn = true
        this.voiceData = ''
        this.audioLink == null ? this.fileUpload.clear() : ''
        this.audioLink = ''
        this.getMediaDetails()
      }, (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    } else {
      this.userService.addMediaVoiceInfo(obj).subscribe((data) => {
        this.submittedVoiceOverForm = false;
        this.voiceOverForm.reset()
        obj['mediaType'] == 3 ? this.fileUpload.clear() : ''
        this.getMediaDetails()
        this.addAudioBtn = true
        this.voiceData = ''
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Record updated successfully'
        });
      }, (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    }

  }

  //get details of media
  getMediaDetails() {
    this.pageLoader = true
    this.imageDataList = []
    this.audioDataList = []
    this.videoDataList = []
    this.userService.getMediaData().subscribe((data) => {
      this.pageLoader = false
      let dataList: any = []
      dataList = data
      const promise = new Promise(async (resolve, reject) => {
        //create form array 
        await dataList.map(async (item: any, index: any) => {
          if (item.mediaType == '1') {
            this.imageDataList.push(item);
          } else if (item.mediaType == '2' || item.mediaType == '4') {
            let urlsplit = item.mediaName.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|playlist\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
            if(urlsplit[1] == "playlist?"){
              item.youTubeLinkType = 1
              item.mediaName = "https://www.youtube.com/embed/videoseries?" + urlsplit[3]
            }
            this.videoDataList.push(item);
          } else if (item.mediaType == '3' || item.mediaType == '5') {
            this.audioDataList.push(item);
          }
        })
        resolve('Success!');
      });
      this.imageDataList.length > 0 ? this.addImageBtn = true : this.addImageBtn = false
      this.videoDataList.length > 0 ? this.addVideoBtn = true : this.addVideoBtn = false
      this.audioDataList.length > 0 ? this.addAudioBtn = true : this.addAudioBtn = false

      this.imageDataList.length > 0 ? this.photoFormDiv = false : this.photoFormDiv = true
      this.videoDataList.length > 0 ? this.videoFormDiv = false : this.videoFormDiv = true
      this.audioDataList.length > 0 ? this.audioFormDiv = false : this.audioFormDiv = true

      if (this.data) {
        this.data == 0 ? this.activeTab = this.items[0].number : ''
        this.data == 1 ? this.activeTab = this.items[1].number : ''
        this.data == 2 ? this.activeTab = this.items[2].number : ''
        sessionStorage.setItem('selectedItemTabMedia', JSON.stringify(this.activeTab))
      }

    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  
  safePlayListUrl(url :any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  // for veryfy link
  verifyLink(event : any){
    this.validLinkError = false
    if(this.g['linkType'].value == 1){
      let urlsplit:any = event.target.value.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|playlist\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
      //console.log(urlsplit)
      if(urlsplit[1] == 'playlist?' || urlsplit[1] == 'watch?' || urlsplit[3]){
        this.validLinkError = false
      }else{
        this.validLinkError = true
        return
      }
    } 
    if(this.g['linkType'].value == 2){
      const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
      const matches: any = re.exec(event.target.value);
      if(matches == null || matches == ''){
        this.validLinkError = true
        return
      }
    }
    }

  //delete media image /video / Audio
  deleteMedia(id: any) {
    this.confirmationService.confirm({
      message: 'Do you really wish to delete?',
      accept: () => {
        this.userService.deleteMediaData(id).subscribe((data) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Record deleted successfully'
          });
          this.selectedItem = null;
          this.getMediaDetails()
        }, (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.error.message
          });
        })
      }, reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: 'No changes made'
        });
      }
    });
  }
  // for update media image/ video/ Audio use 
  updateMediaDetails(id: any) {
    this.mediaId = id
    this.userService.getMediaId(id).subscribe((data: any) => {
      this.btnTitle = 'Update'
      this.mediaDetails = data
      let mediaType = data.mediaType
      this.mediaType = mediaType
      if (mediaType == '1') {
        this.photoDiv = true
        this.addImageBtn = false
        this.photoFormDiv = true
        this.photosForm.controls['title'].setValue(data.title != null ? data.title : '');
        this.photosForm.controls['description'].setValue(data.description);
        this.imageName = data.mediaName
        this.imageData = data.source
      } else if (mediaType == '2') {
        this.videoDiv = true;
        this.addVideoBtn = false
        this.videoFormDiv = true
        this.videosForm.controls['uploadVideo'].setValue(1);
        this.videosForm.controls['title'].setValue(data.title != null ? data.title : '');
        this.videosForm.controls['description'].setValue(data.description);
        this.videoName = data.mediaName
        this.videoData = data.source
      } else if (mediaType == '3') {
        this.voiceDiv = true;
        this.addAudioBtn = false
        this.audioFormDiv = true
        this.voiceOverForm.controls['uploadAudio'].setValue(1);
        this.voiceOverForm.controls['title'].setValue(data.title != null ? data.title : '');
        this.voiceOverForm.controls['description'].setValue(data.description);
        this.voiceName = data.mediaName
        this.voiceData = data.source
      } else if (mediaType == '4') {
        this.videoDiv = true;
        this.addVideoBtn = false
        this.videoFormDiv = true
        this.videosForm.controls['uploadVideo'].setValue(0);
        this.videosForm.controls['title'].setValue(data.title != null ? data.title : '');
        this.videosForm.controls['description'].setValue(data.description);
        this.videosForm.controls['link'].setValue(data.mediaName);
        this.videosForm.controls['linkType'].setValue(data.linkType);
        this.videoLink = data.mediaName
        let urlsplit = data.mediaName.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|playlist\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
        if(urlsplit[1] == "playlist?"){
          this.youTubePlayListLink = 1
          this.youTubePlayListLinkType = "https://www.youtube.com/embed/videoseries?" + urlsplit[3]
        }
      } else if (mediaType == '5') {
        this.voiceDiv = true;
        this.addAudioBtn = false
        this.audioFormDiv = true
        this.voiceOverForm.controls['uploadAudio'].setValue(0);
        this.voiceOverForm.controls['title'].setValue(data.title != null ? data.title : '');
        this.voiceOverForm.controls['description'].setValue(data.description);
        this.voiceOverForm.controls['link'].setValue(data.mediaName);
        this.voiceOverForm.controls['linkType'].setValue(data.linkType);
        this.audioLink = data.mediaName
      }
    }, (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })

  } 
  // for back on click events.
  backClicked() {
    if (this.mediaType == 1) {
      this.photoDiv = false;
      this.addImageBtn = true
      this.photoFormDiv = false
      this.imageData = ''
      this.photosForm.reset();
      this.fileUpload.clear();
      this.btnTitle = 'Save'
    } else if (this.mediaType == 2 || this.mediaType == 4) {
      this.videoDiv = false;
      this.addVideoBtn = true
      this.videoFormDiv = false
      this.videoData = ''
      this.videosForm.reset();
      this.mediaType == 2 ? this.fileUpload.clear() : ''
      this.videoLink = ''
    } else if (this.mediaType == 3 || this.mediaType == 5) {
      this.voiceDiv = false;
      this.addAudioBtn = true
      this.audioFormDiv = false
      this.voiceData = ''
      this.voiceOverForm.reset();
      this.mediaType == 3 ? this.fileUpload.clear() : ''
      this.audioLink = ''
    }
  }

  openForm(number: any) {
    if (number == 0) {
      this.photoFormDiv = true
      this.addImageBtn = false
    } else if (number == 1) {
      this.videoFormDiv = true
      this.addVideoBtn = false
    } else if (number == 2) {
      this.audioFormDiv = true
      this.addAudioBtn = false
    }
  } 
 
  /**
   * for featured image.
   * @method featuredImage
   * @returns { Object } Object
   */
  featuredImage(id: any, mediaType: any) {
    this.userService.featuerdMediaData(id, mediaType).subscribe((data) => {
      this.getMediaDetails()
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Record updated successfully'
      });
    }, (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })

  }
  getId(url: any) {
    let urlsplit = url.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
    return urlsplit[3]
  }
  parseVimeo(url : any) {
    const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
    const matches: any = re.exec(url);
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+matches[1]);
  }
}
