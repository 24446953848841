<div class="progress-spinner gallery-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<!-- profile -->
<div class="surface-ground px-4 py-3 md:px-6">
    <!-- <div class="mb-3 cursor-pointer" (click)="backClicked()" 
    style="align-items: center; display:flex;"><i class="pi pi-angle-left"></i><span>Back to search results</span></div> -->
    <div class="shadow-2 border-round surface-card p-3 lg:p-5">
        <div class="grid mt-0">
            <div class="col-12 lg:col-7 lg:pr-5">
                <div class="flex">
                    <!-- for profile image -->
                    <div class="block md:flex">
                        <img *ngIf="!profileData.personalInfo?.profileThumbnail"
                            src="assets/images/profilePlaceholder.png" class="mr-4 mainprofilephoto" height="170"
                            width="170" style="border-radius:50% ;object-fit: cover;" />
                        <img *ngIf="profileData.personalInfo?.profileThumbnail"
                            src="{{profileData.personalInfo?.profileThumbnail}}" class="mb-2 md:mr-4 mainprofilephoto"
                            height="170" width="170" style="border-radius:50% ; object-fit: cover;" />
                        <!-- <p-image *ngIf="profileData.personalInfo?.profileImageSrc" src="{{profileData.personalInfo?.profileImageSrc}}" [preview]="true"
                            height="170" width="170" imageClass="mr-4 mainprofilephoto" 
                            imageStyle="border-radius:50% ; object-fit: cover;"></p-image> -->
                        <div>
                            <!--user name -->
                            <div class="text-900  text-xl md:text-3xl font-medium"
                                *ngIf="profileData.personalInfo?.name">{{profileData.personalInfo?.name}}</div>
                            <div class="text-900  text-xl-custom  mb-3" *ngIf="profileData.personalInfo?.tagLine">
                                {{profileData.personalInfo?.tagLine}}</div>
                            <div class="mb-2 text-left" *ngIf="profileData.address?.fullAddress">
                                <i class="pi pi-map-marker mr-2" style="vertical-align: middle;"></i>
                                <label><span *ngIf="profileData.address['city']">{{profileData.address['city']}},
                                    </span><span *ngIf="profileData.address['state']">{{profileData.address['state']}},
                                    </span> <span
                                        *ngIf="profileData.address['country']">{{profileData.address['country']}}</span></label>
                            </div>
                            <!-- user bio read more less -->
                            <div class="mb-2 text-700" *ngIf="profileData.personalInfo?.bio">
                                <div class="container text-justify" [class.show]="showMore">
                                    <label>{{profileData.personalInfo?.bio}}</label>
                                </div>
                                <div style="width: 100%;" class="mt-1 text-blue-500 cursor-pointer text-left"
                                    *ngIf="profileData.personalInfo?.bio.length > 200" (click)="showMore = !showMore">{{
                                    showMore ? 'Read less': 'Read More' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-5">
                <div
                    class="label-width border-top-1 lg:border-top-none lg:border-left-1 surface-border flex-auto pl-0 lg:pl-5 pt-3 lg:pt-0">
                    <!-- guardianinfo -->
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.guardian">
                        <label class="contact-label">Guardian</label>
                        <div class="top-label-value xl:ml-3 text-700">{{profileData.contactInfo?.guardian}}</div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.guardianPhone">
                        <label class="contact-label">Guardian's Phone</label>
                        <div class="top-label-value">
                            <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.guardianPhone}}">{{profileData.contactInfo?.guardianPhone}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.guardianEmail">
                        <label class="contact-label">Guardian's Email</label>
                        <div class="top-label-value">
                            <a class="line-height-3 mb-2 text-700 xl:ml-3 word-break" style="text-decoration: none;"
                                href="mailto:{{profileData.contactInfo?.guardianEmail}}">
                                {{profileData.contactInfo?.guardianEmail}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.cellPhone">
                        <label class="contact-label">Cell Phone</label>
                        <div>
                            <span class="top-label-value" *ngIf="profileData.contactInfo?.cellPhone">
                                <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                    href="tel: {{profileData.contactInfo?.cellPhone}}">{{profileData.contactInfo?.cellPhone}}</a></span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.otherPhone">
                        <label class="contact-label">Other Phone</label>
                        <div>
                            <span class="top-label-value" *ngIf="profileData.contactInfo?.otherPhone">
                                <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                    href="tel: {{profileData.contactInfo?.otherPhone}}">{{profileData.contactInfo?.otherPhone}}</a></span>
                        </div>
                    </div>
                    <!-- <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.whatsAppNumber">
                        <label class="contact-label">WhatsApp</label>
                        <div class="top-label-value">
                            <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.whatsAppNumber}}">{{profileData.contactInfo?.whatsAppNumber}}</a>
                        </div>
                    </div> -->
                    <div class="xl:flex mb-2" *ngIf="profileData.email">
                        <label class="contact-label">Email</label><span class="top-label-value"
                            style="width: calc(100% - 180px);">
                            <a class="line-height-3 text-700 xl:ml-3 text-ellipsis" title="{{profileData.email}}"
                                style="text-decoration: none;"
                                href="mailto:{{profileData.email}}">{{profileData.email}}</a>
                        </span>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.website">
                        <label class="contact-label">Main Website</label><span class="top-label-value"
                            style="width: calc(100% - 180px);">
                            <a href="{{profileData.contactInfo?.website}}" title="{{profileData.contactInfo?.website}}"
                                target="_blank" class="xl:ml-3 text-ellipsis"
                                style="text-decoration: none;">{{profileData.contactInfo?.website}}</a></span>
                    </div>
                    <!-- social media app link -->
                    <div class="mt-3 lg:mt-5" style="display: flex; flex-wrap: wrap;">
                        <div class="mr-3" *ngIf="profileData.contactInfo?.whatsAppNumber">
                            <a [href]="getWhatsAppLink()" target="_blank"
                                class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                                <img src="assets/images/social/whatsapp.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.facebook">
                            <a href="{{profileData.contactInfo?.facebook}}" target="_blank"
                                class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                                <img src="assets/images/social/facebook.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.twitter">
                            <a href="{{profileData.contactInfo?.twitter}}" target="_blank"
                                class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                                <img src="assets/images/social/x.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.instagram">
                            <a href="{{profileData.contactInfo?.instagram}}" target="_blank"
                                class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                                <img src="assets/images/social/instagram.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.linkedin">
                            <a href="{{profileData.contactInfo?.linkedin}}" target="_blank"
                                class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                                <img src="assets/images/social/linkedin.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.imdbLink">
                            <a href="{{profileData.contactInfo?.imdbLink}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/imdb.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.imdbProLink">
                            <a href="{{profileData.contactInfo?.imdbProLink}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/imdbpro.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.youtubeLink">
                            <a href="{{profileData.contactInfo?.youtubeLink}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/youtube.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.tiktokLink">
                            <a href="{{profileData.contactInfo?.tiktokLink}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/tiktok.png" width="20" height="20">
                            </a>
                        </div>
                        <!-- social media external  link -->
                        <div class="mr-3" *ngIf="profileData.contactInfo?.actorsAccess">
                            <a href="{{profileData.contactInfo?.actorsAccess}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/actorsAccess02.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.castingNetworks">
                            <a href="{{profileData.contactInfo?.castingNetworks}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/castingnetworks.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.spotlight">
                            <a href="{{profileData.contactInfo?.spotlight}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/spotlight.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.castingFrontier">
                            <a href="{{profileData.contactInfo?.castingFrontier}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/casting_frontier.png" width="20" height="20">
                            </a>
                        </div>
                        <!-- <div class="mr-3" *ngIf="profileData.contactInfo?.nyCastings">
                            <a href="{{profileData.contactInfo?.nyCastings}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/nycastings.png" width="20" height="20">
                            </a>
                        </div> -->
                        <div class="mr-3" *ngIf="profileData.contactInfo?.applePodcasts">
                            <a href="{{profileData.contactInfo?.applePodcasts}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/apple_podcasts.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.spotify">
                            <a href="{{profileData.contactInfo?.spotify}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/spotify.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.threads">
                            <a href="{{profileData.contactInfo?.threads}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/threads.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.showcast">
                            <a href="{{profileData.contactInfo?.showcast}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/Showcast.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.workbook">
                            <a href="{{profileData.contactInfo?.workbook}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/workbook.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.enCast">
                            <a href="{{profileData.contactInfo?.enCast}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/enCast.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.amazonMusic">
                            <a href="{{profileData.contactInfo?.amazonMusic}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/amazonmusic.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.amazonPodcasts">
                            <a href="{{profileData.contactInfo?.amazonPodcasts}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/otherlink.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.castBox">
                            <a href="{{profileData.contactInfo?.castBox}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/castbox.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.youTubePodcasts">
                            <a href="{{profileData.contactInfo?.youTubePodcasts}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/youTubePodcasts.png" width="20" height="20">
                            </a>
                        </div>
                        <div class="mr-3" *ngIf="profileData.contactInfo?.spotifyPodcast">
                            <a href="{{profileData.contactInfo?.spotifyPodcast}}" target="_blank"
                                class="cursor-pointer line-height-3 text-ellipsis font-bold"
                                style="text-decoration: none;">
                                <img src="assets/images/social/otherlink.png" width="20" height="20">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:pr-5">
                <div class="flex">
                    <!-- profile button like connect, resume, edit profile and Featuredvideo -->
                    <div class="mr-4 button-spacing lg:block hidden"></div>
                    <div class="connect-button-group w-12 md:w-auto">
                        <button pButton pRipple class="p-button-success mr-2 md:mr-3 mt-2 md:mt-0" label="Connect"
                            (click)="showConnect()"></button>
                        <button pButton pRipple class="p-button-success mr-2 md:mr-3 mt-2 md:mt-0"
                            (click)="backClicked()" [disabled]="userSubscribe == true" label="Edit Profile"></button>
                        <button pButton pRipple class="p-button-outlined mr-2 md:mr-3 mt-2 md:mt-0"
                            *ngIf="profileData.personalInfo?.resumes.length > 0" (click)="showDialog()"
                            label="Resumes"></button>
                        <button pButton pRipple class="p-button-outlined mr-2 md:mr-3 mt-2 md:mt-0"
                            *ngIf="profileData.videos?.length > 0" (click)="show()" label="Featured Video"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="surface-ground px-4 pt-4 pb-7 md:px-6 lg:px-6">
    <div class="grid">
        <!-- <div class="col-12 lg:col-3 sticky-panel"> -->
        <div class="col-12 lg:col-3 sticky-panel">
            <div class="shadow-2 border-round surface-card" style="height: 100%;">
                <div class="p-3 pb-0" *ngIf="profileData.usercategories?.length > 0">
                    <div class="pb-3" *ngFor="let item of profileData.usercategories">
                        <div class="md:flex text-black-600 mb-2">
                            <span class="label-width-110 inline-block">Platform</span>
                            <span class="line-height-3 text-ellipsis text-700 md:ml-3">{{item.mediaPlatform}}</span>
                        </div>
                        <div class="md:flex text-black-600 mb-2">
                            <span class="label-width-110 inline-block">Department</span>
                            <span class="line-height-3 text-ellipsis text-700 md:ml-3">{{item.name}}</span>
                        </div>
                        <div class="md:flex text-black-600 mb-2">
                            <span class="label-width-110 inline-block">Job Title</span>
                            <span class="line-height-3 text-ellipsis text-700 md:ml-3">{{item.subcategories}}</span>
                        </div>
                    </div>
                    <div class="border-top-1 surface-border"></div>
                </div>

                <div class="p-3 pb-0" *ngIf="contactInfo > 0">
                    <h3 class="block text-black-600 mb-4 mt-0">Contact Information</h3>

                    <div class="mb-3" *ngIf="profileData.contactInfo?.guardian">
                        <div class="block text-black-900 mb-2">Guardian</div>
                        <span class="block text-600 mb-2">{{profileData.contactInfo?.guardian}}</span>
                    </div>
                    <div class="mb-3" *ngIf="profileData.contactInfo?.guardianPhone">
                        <div class="block text-black-900 mb-2">Guardian's Phone</div>
                        <span class="block text-600 mb-2">{{profileData.contactInfo?.guardianPhone}}</span>
                    </div>
                    <div class="mb-3" *ngIf="profileData.contactInfo?.guardianEmail">
                        <div class="block text-black-900 mb-2">Guardian's Email</div>
                        <a class="line-height-3 mb-2 text-600 word-break" style="text-decoration: none;"
                            href="mailto:{{profileData.contactInfo?.guardianEmail}}">
                            {{profileData.contactInfo?.guardianEmail}}</a>
                    </div>

                    <div *ngIf="profileData.contactInfo?.hasAgent == 0">
                        <div class="mb-3 border-bottom-1 surface-border">
                            <div class="block text-black-900 mb-2">Point Agent</div>
                            <span class="block text-600 mb-2">Not at this time</span>
                        </div>
                    </div>
                    <div *ngIf="profileData.contactInfo?.hasAgent == 1">
                        <div class="mb-2 pb-2 border-bottom-1 surface-border"
                            *ngFor="let item of profileData.contactInfo?.agents">
                            <div class="block text-black-900 mb-2 font-medium">
                                <ng-container *ngIf="item.agentTypeId == 17; else showAgentTypeName">
                                    {{item.agentType}}
                                </ng-container>
                                <ng-template #showAgentTypeName>
                                    {{item.agentTypeName}}
                                </ng-template>
                                <span *ngIf="item.location">({{item.location}})</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="item.agentName">
                                <span class="label-width-110 inline-block text-black-900">Name</span>
                                <span class="line-height-3 text-ellipsis text-600 md:ml-3">{{item.agentName}}</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="item.agentPhone">
                                <span class="label-width-110 inline-block text-black-900">Phone</span>
                                <a class="line-height-3 text-ellipsis text-600 md:ml-3" style="text-decoration: none;"
                                    href="tel: {{item.agentPhone}}">{{item.agentPhone}}</a>
                            </div>
                            <div class="md:flex mb-2" *ngIf="item.agentEmail">
                                <span class="label-width-110 inline-block text-black-900">Email</span>
                                <a class="line-height-3 text-ellipsis text-600 md:ml-3" style="text-decoration: none;"
                                    href="mailto:{{item.agentEmail}}">
                                    {{item.agentEmail}}</a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="profileData.contactInfo?.hasManager == 0">
                        <div class="mb-3 border-bottom-1 surface-border">
                            <div class="block text-black-900 mb-2">Manager</div>
                            <span class="block text-600 mb-2">Not at this time</span>
                        </div>
                    </div>

                    <div *ngIf="profileData.contactInfo?.hasManager == 1">
                        <div class="mb-2 pb-2 border-bottom-1 surface-border">
                            <div class="block text-black-900 mb-2 font-medium">
                                <span>Manager</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.manager">
                                <div class="label-width-110 inline-block text-black-900 ">Name</div>
                                <span
                                    class="line-height-3 text-ellipsis text-600 md:ml-3">{{profileData.contactInfo?.manager}}</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.managerPhone">
                                <div class="label-width-110 inline-block text-black-900 ">Phone</div>
                                <a class="line-height-3 text-ellipsis text-600 md:ml-3" style="text-decoration: none;"
                                    href="tel: {{profileData.contactInfo?.managerPhone}}">{{profileData.contactInfo?.managerPhone}}</a>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.managerEmail">
                                <div class="label-width-110 inline-block text-black-900 ">Email</div>
                                <a class="line-height-3 word-break text-600 md:ml-3" style="text-decoration: none;"
                                    href="mailto:{{profileData.contactInfo?.managerEmail}}">
                                    {{profileData.contactInfo?.managerEmail}}</a>
                            </div>
                        </div>
                    </div>



                    <div *ngIf="profileData.contactInfo?.hasAttorny == 0">
                        <div class="mb-3 border-bottom-1 surface-border">
                            <div class="block text-black-900 mb-2">Attorney</div>
                            <span class="block text-600 mb-2">Not at this time</span>
                        </div>
                    </div>

                    <div *ngIf="profileData.contactInfo?.hasAttorny == 1">
                        <div class="mb-2 pb-2 border-bottom-1 surface-border">
                            <div class="block text-black-900 mb-2 font-medium">
                                <span>Attorney</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.attorney">
                                <div class="label-width-110 inline-block text-black-900 ">Name</div>
                                <span
                                    class="line-height-3 text-600 md:ml-3">{{profileData.contactInfo?.attorney}}</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.attorneyPhone">
                                <div class="label-width-110 inline-block text-black-900 ">Phone</div>
                                <a class="line-height-3 text-ellipsis text-600 md:ml-3" style="text-decoration: none;"
                                    href="tel: {{profileData.contactInfo?.attorneyPhone}}">{{profileData.contactInfo?.attorneyPhone}}</a>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.attorneyEmail">
                                <div class="label-width-110 inline-block text-black-900">Email</div>
                                <a class="line-height-3 word-break text-600 md:ml-3" style="text-decoration: none;"
                                    href="mailto:{{profileData.contactInfo?.attorneyEmail}}">
                                    {{profileData.contactInfo?.attorneyEmail}}</a>
                            </div>
                        </div>
                        <!-- <div class="mb-3" *ngIf="profileData.contactInfo?.attorney">
                            <div class="block text-black-900 mb-2">Attorney</div>
                            <span class="block text-600 mb-2">{{profileData.contactInfo?.attorney}}</span>
                        </div>
                        <div class="mb-3" *ngIf="profileData.contactInfo?.attorneyPhone">
                            <div class="block text-black-900 mb-2">Attorney's Phone</div>
                            <a class="line-height-3 mb-2 text-600" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.attorneyPhone}}">
                                {{profileData.contactInfo?.attorneyPhone}}</a>
                        </div>
                        <div class="mb-3" *ngIf="profileData.contactInfo?.attorneyEmail">
                            <div class="block text-black-900 mb-2">Attorney's Email</div>
                            <a class="line-height-3 mb-2 text-600 word-break" style="text-decoration: none;"
                                href="mailto:{{profileData.contactInfo?.attorneyEmail}}">{{profileData.contactInfo?.attorneyEmail}}</a>
                        </div> -->
                    </div>

                    <div *ngIf="profileData.contactInfo?.hasPublicist == 0">
                        <div class="">
                            <div class="block text-black-900 mb-2">Publicist</div>
                            <span class="block text-600 mb-2">Not at this time</span>
                        </div>
                    </div>

                    <div *ngIf="profileData.contactInfo?.hasPublicist == 1">
                        <div class="pb-2">
                            <div class="block text-black-900 mb-2 font-medium">
                                <span>Publicist</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.publicist">
                                <div class="label-width-110 inline-block text-black-900">Name</div>
                                <span
                                    class="line-height-3 text-ellipsis text-600 md:ml-3">{{profileData.contactInfo?.publicist}}</span>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.publicistPhone">
                                <div class="label-width-110 inline-block text-black-900">Phone</div>
                                <a class="line-height-3 text-ellipsis text-600 md:ml-3" style="text-decoration: none;"
                                    href="tel: {{profileData.contactInfo?.publicistPhone}}">{{profileData.contactInfo?.publicistPhone}}</a>
                            </div>
                            <div class="md:flex mb-2" *ngIf="profileData.contactInfo?.publicistEmail">
                                <div class="label-width-110 inline-block text-black-900">Email</div>
                                <a class="line-height-3 word-break text-600 md:ml-3" style="text-decoration: none;"
                                    href="mailto:{{profileData.contactInfo?.publicistEmail}}">
                                    {{profileData.contactInfo?.publicistEmail}}</a>
                            </div>
                        </div>
                        <!-- <div div *ngIf="profileData.contactInfo?.publicist">
                            <div class="block text-black-900 mb-2">Publicist</div>
                            <span class="block text-600 mb-2">{{profileData.contactInfo?.publicist}}</span>
                        </div>
                        <div class="mb-3" *ngIf="profileData.contactInfo?.publicistPhone">
                            <div class="block text-black-900 mb-2">Publicist's Phone</div>
                            <a class="line-height-3 mb-2 text-600" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.publicistPhone}}">
                                {{profileData.contactInfo?.publicistPhone}}</a>
                        </div>
                        <div div *ngIf="profileData.contactInfo?.publicistEmail">
                            <div class="block text-black-900 mb-2">Publicist's Email</div>
                            <a class="line-height-3 mb-2 text-600 word-break" style="text-decoration: none;"
                                href="mailto:{{profileData.contactInfo?.publicistEmail}}">{{profileData.contactInfo?.publicistEmail}}</a>
                        </div> -->
                    </div>
                    <div class="flex">

                    </div>
                    <div class="border-top-1 surface-border mt-3"></div>
                </div>
                <div>
                    <div class="p-3 pb-0" *ngIf="profileData.videos?.length > 0">
                        <div style="display: flex;">
                            <h3 class="block text-black-600 mb-4 mt-0" style="width: 50%;">Videos</h3>
                            <a *ngIf="userSubscribe == false" [routerLink]="['/dashboard',1]"
                                style="width: 50%; text-align: right; text-decoration: none;" class="mt-1">See
                                All</a>
                            <a *ngIf="userSubscribe == true" [routerLink]="['/dashboard',3]"
                                style="width: 50%; text-align: right; text-decoration: none;" class="mt-1">See
                                All</a>
                        </div>

                        <div *ngFor="let item of profileData?.videos">
                            <div *ngIf="item.featured == 1">
                                <video controls style="width: 100%;" *ngIf="item.source">
                                    <source src="{{item.source}}" alt="Image" type="video/mp4">
                                </video>
                                <div *ngIf="!item.source">
                                    <div class="linkDiv surface-border border-1"
                                        *ngIf="item.linkType == 2 || item.linkType == 3">
                                        <a style="word-break: break-all;height: 100%;" class="block" target="_blank"
                                            href="{{item.mediaName}}">{{item.mediaName}}</a>

                                    </div>
                                    <youtube-player class="responsive-video-small iframe" *ngIf="item.linkType == 1"
                                        videoId="{{this.getId(item.mediaName)}}" suggestedQuality="highres">
                                    </youtube-player>
                                </div>

                            </div>
                        </div>
                        <div class="border-top-1 surface-border mt-3"></div>
                    </div>
                    <div class="p-3 pb-0" *ngIf="profileData.voices?.length > 0">
                        <div style="display: flex;">
                            <h3 class="block text-black-600 mb-4 mt-0" style="width: 50%;">Voice Over</h3>
                            <a *ngIf="userSubscribe == false" [routerLink]="['/dashboard',2]"
                                style="width: 50%; text-align: right; text-decoration: none;" class="mt-1">See
                                All</a>
                            <a *ngIf="userSubscribe == true" [routerLink]="['/dashboard',3]"
                                style="width: 50%; text-align: right; text-decoration: none;" class="mt-1">See
                                All</a>
                        </div>

                        <div class="relative" *ngFor="let item of profileData.voices">
                            <audio controls style="width: 100%;" *ngIf="item.featured == 1 && item.mediaType == 3">
                                <source type="audio/ogg" src="{{item.source}}">
                            </audio>
                            <div class="linkDiv surface-border border-1"
                                *ngIf="item.featured == 1 && item.mediaType == 5">
                                <a style="word-break: break-all; height: 100%;" class="block" target="_blank"
                                    href="{{item.mediaName}}">{{item.mediaName}}</a>
                            </div>
                            <!-- <div class="hide-3dot"></div> -->
                        </div>
                        <div class="border-top-1 surface-border mt-3"></div>
                    </div>
                    <div class="p-3" *ngIf="profileData.photos?.length > 0">
                        <div style="display: flex;">
                            <h3 class="block text-black-600 mb-4 mt-0" style="width: 50%;">Photos</h3>
                            <a *ngIf="userSubscribe == false" [routerLink]="['/dashboard',0]"
                                style="width: 50%; text-align: right; text-decoration: none;" class="mt-1">See
                                All</a>
                            <a *ngIf="userSubscribe == true" [routerLink]="['/dashboard',3]"
                                style="width: 50%; text-align: right; text-decoration: none;" class="mt-1">See
                                All</a>
                        </div>
                        <div>
                            <!-- <img class="img_profile" *ngFor="let item of profileData?.photos" src="{{item.source}}" alt="Image"> -->
                            <!-- <p-image (onShow)="imageOpen($event)" (onHide)="imageHide($event)" class="img_grid_profile" 
                        *ngFor="let item of profileData?.photos" src="{{item.source}}" [preview]="true"></p-image> -->
                            <p-galleria [value]="photos" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
                                [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}"
                                [numVisible]="6" [circular]="true" [fullScreen]="true" [showItemNavigators]="true"
                                [showThumbnails]="false" [baseZIndex]="100000">
                                <ng-template pTemplate="item" let-item>
                                    <img src="{{item.source}}" class="gallery-image-fullscreen" />
                                </ng-template>
                                <ng-template pTemplate="thumbnail" let-item>
                                    <div class="grid grid-nogutter justify-content-center">
                                        <img src="{{item.source}}" style="display: block;" />
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="caption" let-item>
                                    <h4 style="margin-bottom: .5rem; color: #ffffff;">{{item.title}}</h4>
                                    <p>{{item.description}}</p>
                                </ng-template>
                            </p-galleria>
                            <div class="grid">
                                <div *ngFor="let image of profileData.photos; let index = index;"
                                    class="col-4 md:col-2 lg:col-4" key="index">
                                    <img src="{{image.thumnail}}" class="profile-image-gallery"
                                        (click)="imageClick(index)" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6">
            <div class="shadow-2 border-round sticky-panel surface-card">
                <div class="p-3 pb-0">
                    <!-- for Personal Information div -->
                    <h3 class="block text-black-600 mb-4 mt-0">Personal Information</h3>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.gender">
                        <span class="label-width-220 inline-block">Gender Identity</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.gender}}</span>
                    </div>
                    <!-- <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.countryofOrigin">
                       <span class="label-width-220 inline-block">Country Of Origin</span>
                        <span class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.countryofOrigin}}</span>
                    </div> -->
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.nationality">
                        <span class="label-width-220 inline-block">Nationality</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.nationality}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.multipleBirths">
                        <span class="label-width-220 inline-block">Multiple Births</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.multipleBirths}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.covidVaccination">
                        <span class="label-width-220 inline-block">Covid-19 Vaccination</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.covidVaccination}}</span>
                    </div>
                    <!-- <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.covidPassport">
                        <span class="label-width-220 inline-block">Covid-19 Passport</span>
                        <span class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.covidPassport}}</span>
                    </div> -->
                    <!-- <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.covid19ComplianceDepartment">
                        <span class="label-width-220 inline-block">Covid-19 Compliance Department</span>
                        <span class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.covid19ComplianceDepartment}}</span>
                    </div> -->
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.ethnicity">
                        <span class="label-width-220 inline-block">Ethnicity</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.ethnicity}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.lifeChallenges">
                        <span class="label-width-220 inline-block">Life's Challenges</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.lifeChallenges}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.personalInfo?.dualCitizenship">
                        <span class="label-width-220 inline-block">Dual Citizenship</span>
                        <span
                            class="line-height-3 text-ellipsis text-700 md:ml-3">{{profileData.personalInfo?.dualCitizenship}}</span>
                    </div>
                    <div class="border-top-1 surface-border mt-3"></div>

                </div>

                <div class="p-3 flex flex-column pb-0" *ngIf="professionalInfo > 0">
                    <!-- Professional Information div -->
                    <h3 class="block text-black-600 mb-4 mt-0">Professional Information</h3>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.loanOutCompany">
                        <span class="label-width-220 inline-block">Loan Out Company</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.loanOutCompany}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.unionsAndGuilds">
                        <span class="label-width-220 inline-block">Unions & Guilds</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.unionsAndGuilds}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.union || profileData.professionalInfo?.otherCrewUnion">
                        <span class="label-width-220 inline-block">Union</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.union}}{{profileData.professionalInfo?.union  && profileData.professionalInfo?.otherCrewUnion  ? ', ' : '' }} {{profileData.professionalInfo?.otherCrewUnion}}</span>
                    </div>
            
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.dayRate">
                        <span class="label-width-220 inline-block">Non-Union Day Rate</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.dayRate}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.willTravel">
                        <span class="label-width-220 inline-block">Will Travel</span>
                        <span
                            class="line-height-3  text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.willTravel}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.visa">
                        <span class="label-width-220 inline-block">Visa & Work Permit</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.visa}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.passport">
                        <span class="label-width-220 inline-block">Passport</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.passport}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.hasKitOrGear">
                        <span class="label-width-220 inline-block">Has Kit/Gear</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.hasKitOrGear}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.filmBudget">
                        <span class="label-width-220 inline-block">Budget of My Projects</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.filmBudget}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.workingLocations">
                        <span class="label-width-220 inline-block">Work Locations</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.workingLocations}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.professionalInfo?.specialTalents">
                        <span class="label-width-220 inline-block">Special Talents</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.specialTalents}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.professionalInfo?.additionalInformation">
                        <span class="label-width-220 inline-block">Additional Information</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.professionalInfo?.additionalInformation}}</span>
                    </div>
                    <div class="mt-3 border-top-1 surface-border"></div>
                </div>

                <div class="p-3 flex flex-column" *ngIf="skillInfo > 0">
                    <!-- Skills, Training & More div -->
                    <h3 class="block text-black-600 mb-4 mt-0">Skills, Training & More</h3>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.actorTraining">
                        <span class="label-width-220 inline-block">Actor Training</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.actorTraining}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.vocalRange">
                        <span class="label-width-220 inline-block">Vocal Range</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.vocalRange}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.dance">
                        <span class="label-width-220 inline-block">Dance</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.dance}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.bands">
                        <span class="label-width-220 inline-block">Bands & Instruments</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.bands}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.cycling">
                        <span class="label-width-220 inline-block">Cycling</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.cycling}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.skillAndTrainings?.militaryWeaponsAndFirearmsTraining">
                        <span class="label-width-220 inline-block">Military, Weapons & Firearms Training</span>
                        <span
                            class="line-height-3  text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.militaryWeaponsAndFirearmsTraining}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.gymnastic">
                        <span class="label-width-220 inline-block">Gymnastics</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.gymnastic}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.languages">
                        <span class="label-width-220 inline-block">Languages</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.languages}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.hobbies">
                        <span class="label-width-220 inline-block">Hobbies</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.hobbies}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.outdoorSports">
                        <span class="label-width-220 inline-block">Outdoor & Recreational Sports</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.outdoorSports}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.rodeo">
                        <span class="label-width-220 inline-block">Rodeo</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.rodeo}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.skillAndTrainings?.racquetAndPaddleSports">
                        <span class="label-width-220 inline-block">Racquet & Paddle Sports</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.racquetAndPaddleSports}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.teamSports">
                        <span class="label-width-220 inline-block">Team Sports</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.teamSports}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.singingStyles">
                        <span class="label-width-220 inline-block">Singing Styles</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.singingStyles}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.circusMagicClowning">
                        <span class="label-width-220 inline-block">Circus, Magic, Clowning</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.circusMagicClowning}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.skillAndTrainings?.extremeWinterSportsAndWaterSports">
                        <span class="label-width-220 inline-block">Extreme Winter Sports & Water Sports</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.extremeWinterSportsAndWaterSports}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.equestrianSports">
                        <span class="label-width-220 inline-block">Equestrian</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.equestrianSports}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2" *ngIf="profileData.skillAndTrainings?.dialects">
                        <span class="label-width-220 inline-block">Dialects</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.dialects}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.skillAndTrainings?.driversPilotsCerificatesTransportation">
                        <span class="label-width-220 inline-block">Drivers, Pilots, Certificates,
                            Transportation</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.driversPilotsCerificatesTransportation}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.skillAndTrainings?.indoorAndOutdoorSports">
                        <span class="label-width-220 inline-block">Indoor & Outdoor Sports</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.indoorAndOutdoorSports}}</span>
                    </div>
                    <div class="md:flex text-black-600 mb-2"
                        *ngIf="profileData.skillAndTrainings?.stuntsMovesCombatMartialArts">
                        <span class="label-width-220 inline-block">Stunts, Moves, Combat, Martial Arts</span>
                        <span
                            class="line-height-3 text-700 md:ml-3 text-ellipsis">{{profileData.skillAndTrainings?.stuntsMovesCombatMartialArts}}</span>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 lg:col-3 sticky-panel" *ngIf="attribute > 0">
            <div class="shadow-2 border-round surface-card">
                <!-- Attributes div -->
                <div class="p-3">
                    <h3 class="block text-black-600 mb-4 mt-0">Attributes</h3>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.height">
                        <span class="label-width-150 inline-block">Height</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.height}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.weight">
                        <span class="label-width-150 inline-block">Weight</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.weight}}
                            lbs</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.eyeColor">
                        <span class="label-width-150 inline-block">Eye Color</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.eyeColor}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.tattoo">
                        <span class="label-width-150 inline-block">Tattoo</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.tattoo}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.pregnant">
                        <span class="label-width-150 inline-block">Pregnant</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.pregnant}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2"
                        *ngIf="profileData.attributes?.dueDateFrom || profileData.attributes?.dueDateTo">
                        <span class="label-width-150 inline-block">Due Date</span>
                        <span class="line-height-3 text-700 text-ellipsis">
                            {{profileData.attributes?.dueDateFrom | date: 'MM/dd/yyyy'}} to
                            {{profileData.attributes?.dueDateTo | date: 'MM/dd/yyyy'}}
                        </span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.piercing">
                        <span class="label-width-150 inline-block">Piercing</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.piercing}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.smoking">
                        <span class="label-width-150 inline-block">Smoking</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.smoking}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.hairColor">
                        <span class="label-width-150 inline-block">Hair Color</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.hairColor}}</span>
                    </div>

                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.hairLength">
                        <span class="label-width-150 inline-block">Hair Length</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.hairLength}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.facialHair">
                        <span class="label-width-150 inline-block">Facial Hair</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.facialHair}}</span>
                    </div>
                    <div class="md:flex block text-black-600 mb-2" *ngIf="profileData.attributes?.complexion">
                        <span class="label-width-150 inline-block">Complexion</span>
                        <span class="line-height-3 text-700 text-ellipsis">{{profileData.attributes?.complexion}}</span>
                    </div>

                </div>
            </div>
            <div class="shadow-2 border-round surface-card mt-4" *ngIf="profileData.equipment?.length > 0">
                <!-- Equipment div -->
                <div class="p-3">
                    <h3 class="block text-black-600 mb-4 mt-0" *ngIf="profileData.equipment">Other Contributions</h3>
                    <span class="line-height-3 text-700">{{profileData.equipment}}</span>

                </div>

            </div>
        </div>

    </div>
</div>
<!-- dialog box for resume -->
<p-dialog header="Resumes" [(visible)]="display1" [style]="{width: '50vw'}"
    [breakpoints]="{'960px': '75vw', '640px': '90vw'}">
    <div class="border-top-2 surface-border"></div>
    <div *ngFor="let item of profileData.personalInfo?.resumes">
        <div *ngIf="item.resume">
            <div class="flex py-3 surface-border border-bottom-1">
                <div class="font-medium">{{item.title}}</div>
                <div class="ml-auto">
                    <a *ngIf="item.resume" href="{{item.resumeSrc}}" target="_blank" style="text-decoration: none;"
                        class="text-blue-500 font-bold">View Resume</a>
                </div>
            </div>

        </div>
    </div>
    <div class="mt-3 text-right">
        <p-button label="Close" (click)="display1=false" class="p-button-secondery"></p-button>
    </div>


</p-dialog>
<!-- dialog box for Featured Video -->
<p-dialog header="Featured Video" [(visible)]="display2" [style]="{width: '50vw'}"
    [breakpoints]="{'960px': '75vw', '640px': '90vw'}">
    <div class="border-top-2 surface-border"></div>
    <div *ngIf="profileData.videos?.length > 0">
        <div *ngFor="let item of profileData?.videos">
            <div *ngIf="item.featured == 1">
                <video height="100%" controls style="width: 100%;" *ngIf="item.source">
                    <source src="{{item.source}}" class="media-image-edit border-round-sm " style="object-fit: cover;"
                        type="video/mp4">
                </video>
                <div *ngIf="!item.source">
                    <div class="linkDiv surface-border border-1" *ngIf="item.linkType == 2 || item.linkType == 3">
                        <a style="word-break: break-all;height: 100%;" class="block" target="_blank"
                            href="{{item.mediaName}}">{{item.mediaName}}</a>
                    </div>
                    <youtube-player class="responsive-video" *ngIf="item.linkType == 1"
                        videoId="{{this.getId(item.mediaName)}}" suggestedQuality="highres">
                    </youtube-player>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="profileData.videos?.length == 0">
        <span>No Featured Video Found</span>
    </div>
    <!-- <div class="mt-3 text-right">
        <p-button label="Close" (click)="display2=false" class="p-button-secondery"></p-button>
    </div> -->
</p-dialog>
<!-- dialog box for Connect -->
<p-dialog header="Connect" [(visible)]="display3" [style]="{width: '50vw'}"
    [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div class="border-top-2 surface-border mb-2"></div>
    <div>
        <div>
            <div class=" mb-2 border-bottom-1 surface-border">
                <div class="mb-2">
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.guardian">
                        <label class="contact-label">Guardian</label>
                        <div class="top-label-value xl:ml-3 text-700">{{profileData.contactInfo?.guardian}}</div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.guardianPhone">
                        <label class="contact-label">Guardian's Phone</label>
                        <div class="top-label-value">
                            <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.guardianPhone}}">{{profileData.contactInfo?.guardianPhone}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.guardianEmail">
                        <label class="contact-label">Guardian's Email</label>
                        <div class="top-label-value">
                            <a class="line-height-3 mb-2 text-700 xl:ml-3 word-break inline-block"
                                style="text-decoration: none;" href="mailto:{{profileData.contactInfo?.guardianEmail}}">
                                {{profileData.contactInfo?.guardianEmail}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.otherPhone">
                        <label class="contact-label">Cell Phone</label>
                        <div>
                            <span class="top-label-value" *ngIf="profileData.contactInfo?.cellPhone">
                                <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                    href="tel:{{profileData.contactInfo?.cellPhone}}">{{profileData.contactInfo?.cellPhone}}</a></span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.otherPhone">
                        <label class="contact-label">Other Phone</label>
                        <div>
                            <span class="top-label-value" *ngIf="profileData.contactInfo?.otherPhone">
                                <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                    href="tel:{{profileData.contactInfo?.otherPhone}}">{{profileData.contactInfo?.otherPhone}}</a></span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.whatsAppNumber">
                        <label class="contact-label">WhatsApp</label>
                        <div class="top-label-value">
                            <a class="line-height-3 mb-2 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel:{{profileData.contactInfo?.whatsAppNumber}}">{{profileData.contactInfo?.whatsAppNumber}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.email">
                        <label class="contact-label">Email</label><span class="top-label-value"
                            style="width: calc(100% - 180px);">
                            <a class="line-height-3 text-700 xl:ml-3 word-break inline-block"
                                title="{{profileData.email}}" style="text-decoration: none;"
                                href="mailto:{{profileData.email}}">{{profileData.email}}</a>
                        </span>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.website">
                        <label class="contact-label">Main Website</label><span class="top-label-value"
                            style="width: calc(100% - 180px);">
                            <a href="{{profileData.contactInfo?.website}}" title="{{profileData.contactInfo?.website}}"
                                target="_blank" class="xl:ml-3 word-break inline-block"
                                style="text-decoration: none;">{{profileData.contactInfo?.website}}</a></span>
                    </div>
                </div>
            </div>
            <div class="mb-2 border-bottom-1 surface-border" *ngIf="profileData.contactInfo?.hasAgent == 0">
                <div class="xl:flex mb-2">
                    <label class="contact-label">Point Agent</label>
                    <div class="top-label-value">
                        <span class="xl:ml-3">Not at this time</span>
                    </div>
                </div>
            </div>
            <div *ngIf="profileData.contactInfo?.hasAgent == 1">
                <div class="mb-2 border-bottom-1 surface-border" *ngFor="let item of profileData.contactInfo?.agents">
                    <div class="mb-2 font-medium">
                        <ng-container *ngIf="item.agentTypeId == 17; else showAgentTypeName">
                            {{item.agentType}}
                        </ng-container>
                        <ng-template #showAgentTypeName>
                            {{item.agentTypeName}}
                        </ng-template>
                        <span *ngIf="item.location">({{item.location}})</span>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="item.agentName">
                        <label class="contact-label">Name</label>
                        <div class="top-label-value">
                            <span class="xl:ml-3">{{item.agentName}}</span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="item.agentPhone">
                        <label class="contact-label">Phone</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3 " style="text-decoration: none;"
                                href="tel:{{item.agentPhone}}">{{item.agentPhone}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="item.agentEmail">
                        <label class="contact-label">Email</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3 word-break inline-block"
                                style="text-decoration: none;" href="mailto:{{item.agentEmail}}">
                                {{item.agentEmail}}</a>
                        </div>
                    </div>
                </div>


                <!-- <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.agent">
                    <label class="contact-label">Agent</label>
                    <div class="top-label-value">
                        <span class="xl:ml-3">{{profileData.contactInfo?.agent}}</span>
                    </div>
                </div>
                <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.agentPhone">
                    <label class="contact-label">Agent's Phone</label>
                    <div class="top-label-value">
                        <a class="line-height-3 text-700 xl:ml-3 " style="text-decoration: none;"
                            href="tel:{{profileData.contactInfo?.agentPhone}}">{{profileData.contactInfo?.agentPhone}}</a>
                    </div>
                </div>
                <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.agentEmail">
                    <label class="contact-label">Agent's Email</label>
                    <div class="top-label-value">
                        <a class="line-height-3 text-700 xl:ml-3 word-break inline-block" style="text-decoration: none;"
                            href="mailto:{{profileData.contactInfo?.agentEmail}}">
                            {{profileData.contactInfo?.agentEmail}}</a>
                    </div>
                </div> -->
            </div>

            <div class="mb-2 border-bottom-1 surface-border" *ngIf="profileData.contactInfo?.hasManager == 0">
                <div class="xl:flex mb-2 ">
                    <label class="contact-label">Manager</label>
                    <div class="top-label-value">
                        <span class="xl:ml-3">Not at this time</span>
                    </div>
                </div>
            </div>

            <div *ngIf="profileData.contactInfo?.hasManager == 1">
                <div class="mb-2 border-bottom-1 surface-border">

                    <div class="mb-2 font-medium">
                        <span>Maneger</span>
                    </div>

                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.manager">
                        <label class="contact-label">Name</label>
                        <div class="top-label-value">
                            <span class="xl:ml-3">{{profileData.contactInfo?.manager}}</span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.managerPhone">
                        <label class="contact-label">Phone</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel:{{profileData.contactInfo?.managerPhone}}">{{profileData.contactInfo?.managerPhone}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.managerEmail">
                        <label class="contact-label">Email</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3 word-break inline-block"
                                style="text-decoration: none;" href="mailto:{{profileData.contactInfo?.managerEmail}}">
                                {{profileData.contactInfo?.managerEmail}}</a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mb-2 border-bottom-1 surface-border" *ngIf="profileData.contactInfo?.hasAttorny == 0">
                <div class="xl:flex mb-2">
                    <label class="contact-label">Attorney</label>
                    <div class="top-label-value">
                        <span class="xl:ml-3">Not at this time</span>
                    </div>
                </div>
            </div>

            <div *ngIf="profileData.contactInfo?.hasAttorny == 1">
                <div class="mb-2 border-bottom-1 surface-border">

                    <div class="mb-2 font-medium">
                        <span>Attorney</span>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.attorney">
                        <label class="contact-label">Name</label>
                        <div class="top-label-value">
                            <span class="xl:ml-3">{{profileData.contactInfo?.attorney}}</span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.attorneyPhone">
                        <label class="contact-label">Phone</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.attorneyPhone}}">
                                {{profileData.contactInfo?.attorneyPhone}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.attorneyEmail">
                        <label class="contact-label">Email</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3 word-break inline-block"
                                style="text-decoration: none;"
                                href="mailto:{{profileData.contactInfo?.attorneyEmail}}">{{profileData.contactInfo?.attorneyEmail}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="profileData.contactInfo?.hasPublicist == 0">
                <div class="xl:flex mb-2">
                    <label class="contact-label">Publicist</label>
                    <div class="top-label-value">
                        <span class="xl:ml-3">Not at this time</span>
                    </div>
                </div>
            </div>

            <div *ngIf="profileData.contactInfo?.hasPublicist == 1">
                <div class="">
                    <div class="mb-2 font-medium">
                        <span>Publicist</span>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.publicist">
                        <label class="contact-label">Name</label>
                        <div class="top-label-value">
                            <span class="xl:ml-3">{{profileData.contactInfo?.publicist}}</span>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.publicistPhone">
                        <label class="contact-label">Phone</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3" style="text-decoration: none;"
                                href="tel: {{profileData.contactInfo?.publicistPhone}}">
                                {{profileData.contactInfo?.publicistPhone}}</a>
                        </div>
                    </div>
                    <div class="xl:flex mb-2" *ngIf="profileData.contactInfo?.publicistEmail">
                        <label class="contact-label">Email</label>
                        <div class="top-label-value">
                            <a class="line-height-3 text-700 xl:ml-3 word-break inline-block"
                                style="text-decoration: none;"
                                href="mailto:{{profileData.contactInfo?.publicistEmail}}">{{profileData.contactInfo?.publicistEmail}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="flex mt-4">
                
                <div class="mr-3" *ngIf="profileData.contactInfo?.facebook">
                    <a href="{{profileData.contactInfo?.facebook}}" target="_blank" class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                        <i class="pi pi-facebook text-xl"></i> 
                    </a>
                </div>
                <div class="mr-3" *ngIf="profileData.contactInfo?.twitter">
                    <a href="{{profileData.contactInfo?.twitter}}" target="_blank" class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                        <i class="pi pi-twitter text-xl"></i>
                    </a>
                </div>
                <div class="mr-3" *ngIf="profileData.contactInfo?.instagram">
                    <a href="{{profileData.contactInfo?.instagram}}" target="_blank" class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                        <i class="pi pi-instagram text-xl"></i>
                    </a>
                </div>
               <div class="mr-3" *ngIf="profileData.contactInfo?.linkedin">
                    <a href="{{profileData.contactInfo?.linkedin}}" target="_blank" class="cursor-pointer text-500 transition-colors text-primary transition-duration-150 hover:text-700">
                        <i class="pi pi-linkedin text-xl"></i>
                    </a>
               </div>
               <div class="mr-3" *ngIf="profileData.contactInfo?.imdbLink">
                <a href="{{profileData.contactInfo?.imdbLink}}" target="_blank" class="line-height-3 text-ellipsis font-bold" style="text-decoration: none;">
                    <img src="assets/images/imdb.png" width="20" height="20">
                </a>
            </div>
            <div class="mr-3" *ngIf="profileData.contactInfo?.imdbProLink">
                <a href="{{profileData.contactInfo?.imdbProLink}}" target="_blank" class="line-height-3 text-ellipsis font-bold" style="text-decoration: none;">
                    <img src="assets/images/imdbpro.png" width="20" height="20">
                </a>
            </div>
            <div class="mr-3" *ngIf="profileData.contactInfo?.youtubeLink">
                <a href="{{profileData.contactInfo?.youtubeLink}}" target="_blank" class="line-height-3 text-ellipsis font-bold" style="text-decoration: none;">
                    <img src="assets/images/youtube.png" width="20" height="20">
                </a>
            </div>
            <div class="mr-3" *ngIf="profileData.contactInfo?.tiktokLink">
                <a href="{{profileData.contactInfo?.tiktokLink}}" target="_blank" class="line-height-3 text-ellipsis font-bold" style="text-decoration: none;">
                    <img src="assets/images/tiktok.png" width="20" height="20">
                </a>
            </div>
        </div>   -->
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button (click)="display3=false" class="p-button-secondery" label="Close"></p-button>
    </ng-template>
</p-dialog>