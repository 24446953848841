<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="">
    <!-- <div class="text-900 text-2xl  mb-3" *ngIf="addressDiv">
        Please provide your address to view available plans.
    </div>
    <div class="text-900 text-2xl  mb-3" *ngIf="planDiv">
        Select your subscription and pay
    </div> -->
    <div class="grid">
        <div class="col-12 lg:col-8" *ngIf="addressDiv">
            <div class="surface-section p-4 surface-card shadow-2">
                <!-- [formGroup]="addressFrom" used -->
                <form [formGroup]="addressFrom">
                    <div class="formgrid p-fluid">
                        <div class="field mb-4">
                            <label for="Address" class="">Address</label>
                            <input id="Address" type="text" class="w-full" placeholder="Enter Address"
                                #placesRef="ngx-places" ngx-google-places-autocomplete autocomplete="off"
                                (onAddressChange)="handleAddressChange($event)" formControlName="address"
                                [ngClass]="{ 'ng-dirty': submitted3 && h['address'].errors }" pInputText>
                            <div *ngIf="submitted3 && h['address'].errors" class="invalid-feedback">
                                <small *ngIf="h['address'].errors['required']" class="p-error block">Address is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4" *ngIf="countryNames">
                            <label for="Address" class="">Country</label>
                            <p for="Address" class="text-700" style="margin: 0;">{{countryNames}}</p>
                        </div>
                    </div>
                    <!--on (click)="getAddress()" for next step-->
                    <div class="text-right">
                        <button pButton pRipple label="Next" (click)="getAddress()" class="p-button-primary"></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 lg:col-12" *ngIf="planDiv">
            <div class="surface-section p-4 surface-card shadow-2 renew-error-message">
                <p-messages severity="error" *ngIf="paymentSubscription" class="hidden md:flex">
                    <ng-template pTemplate>
                        <div class="md:mr-4">Your account has been deactivated. Please renew your subscription below to
                            enable access to your account.</div>
                        <!-- <button pButton pRipple label="Renew"
                         class="p-button-danger md:ml-auto mt-3 md:mt-0"></button> -->
                        <i class="pi pi-arrow-down text-2xl ml-auto"></i>
                    </ng-template>
                </p-messages>
                <div>
                    <div class="mb-3 mr-3">
                        <div class="mb-2"><label>Address</label></div>
                        <span class="text-700">{{userData.address?.fullAddress}}</span>
                    </div>
                    <div class="flex">
                        <div class="mb-3 mr-3">
                            <div class="mb-2"><label class="ml-auto">Country</label></div>
                            <span class="ml-auto inline-block text-700"
                                style="min-width: 200px;">{{userData.address?.country}}</span>
                        </div>
                        <!-- <div class="mb-3 ml-auto">
                            <button pButton pRipple label="Edit" class="p-button-primary"  [disabled]="categorieBtn" (click)="editAddress()"></button>
                        </div> -->
                    </div>
                </div>
                <div class="border-top-1 surface-border mb-4"></div>
                <!-- [formGroup]="categoryForm" used -->
                <form [formGroup]="categoryForm">
                    <div class="grid" *ngIf="paymentPay">
                        <div class="col-12 lg:col-6">
                            <div class="mb-2">
                                <label>Select a Plan</label>
                            </div>
                            <div class="p-4 border-1 border-primary mb-3 md:mb-4 cursor-pointer"
                                style="border-radius: 8px">
                                <div class="flex align-items-center mb-4">
                                    <p-radioButton [value]="0" class="mr-3" formControlName="plan"></p-radioButton>
                                    <div class="flex w-full">
                                        <div class="text-900 text-xl ">Monthly</div>
                                        <div class="text-900 text-xl  ml-auto">${{monthlyAmount}}</div>
                                    </div>
                                </div>
                                <p class="text-600 m-0 line-height-3">Per Month No Contract</p>
                            </div>
                        </div>

                    </div>
                    <ng-container formArrayName="categories"
                        *ngFor="let item of getCategoriesControls();let i = index;first as first, last as last">
                        <div class="formgrid p-fluid grid" [formGroupName]="i"
                            *ngIf="categories.controls[i].get('catStatus')?.value == 1">
                            <div class="field mb-4 col-12 lg:col-4">
                                <label for="Category" class="">Select a Media Platform *</label>
                                <p-dropdown [options]="mediaPlatform" optionLabel="label" optionValue="value"
                                    placeholder="Select a Media Platform" formControlName="mediaPlatform"
                                    [ngClass]="{ 'ng-dirty': submitted && categories.controls[i].get('mediaPlatform')?.hasError('required') }">
                                </p-dropdown>
                                <div *ngIf="submitted && categories.controls[i].get('mediaPlatform')?.hasError"
                                    class="invalid-feedback">
                                    <small *ngIf="categories.controls[i].get('mediaPlatform')?.hasError('required')"
                                        class="p-error block mb-2">Media
                                        Platform is required</small>

                                </div>
                            </div>
                            <div class="field mb-4 col-12 lg:col-4">
                                <label for="Category" class="">Select a Department *</label>
                                <p-dropdown [options]="departmentList" optionLabel="name"
                                    placeholder="Select a Department" formControlName="categoryId" optionValue="id"
                                    (onChange)="departmentSelect($event,i)"
                                    [ngClass]="{ 'ng-dirty': submitted && categories.controls[i].get('categoryId')?.hasError('required') }">
                                </p-dropdown>
                                <div *ngIf="submitted && categories.controls[i].get('categoryId')?.hasError"
                                    class="invalid-feedback">
                                    <small *ngIf="categories.controls[i].get('categoryId')?.hasError('required')"
                                        class="p-error block mb-2">
                                        Department is required</small>

                                </div>
                            </div>
                            <div class="field mb-4 col-12 lg:col-4">
                                <label for="selectAdditionalDepartment" class="">Select a Job Title *</label>
                                <p-dropdown [options]="subCategoryList[i]" optionLabel="name"
                                    placeholder="Select a Job Title" optionValue="id" formControlName="subcategories"
                                    [ngClass]="{ 'ng-dirty': submitted && categories.controls[i].get('subcategories')?.hasError('required') }">
                                </p-dropdown>
                                <div *ngIf="submitted && categories.controls[i].get('subcategories')?.hasError"
                                    class="invalid-feedback">
                                    <small *ngIf="categories.controls[i].get('subcategories')?.hasError('required')"
                                        class="p-error block mb-2">Job Title is required</small>
                                </div>
                            </div>

                        </div>

                        <div class="formgrid p-fluid grid">
                            <div class="field mb-4 col-12 text-right -mt-3"
                                *ngIf="(i >= 0 && !first || !last) &&  categories.controls[i].get('catStatus')?.value == 1">
                                <span (click)="removeItem(i,item)" [class.disabled]="cardDiv == true"
                                    class="text-red-600 cursor-pointer">Delete Row</span>
                            </div>
                            <div class="field mb-4 col-12 text-right" *ngIf="last">
                                <button pButton pRipple label="Additional Row" icon="pi pi-plus"
                                    [disabled]="categorieBtnAdd" class="w-auto bg-gray-900 border-gray-900"
                                    (click)="addCategories()"></button><br>
                                <small class="text-gray-600" *ngIf="paymentPay"><i>Only @ $1.00/Month Per
                                        Department</i></small>
                            </div>
                        </div>

                    </ng-container>
                    <div *ngIf="paymentPay">
                        <h3 class="mt-0 mb-4 font-normal font-xl">Total amount payable: ${{totalAmount}}</h3>
                    </div>
                    <div class="mb-4" *ngIf="userData.subscribed == 0 && nextSubscriptionData?.status == 0">
                        <span class="text-red-600"> Your subscription will automatically resume on {{nextSubscriptionData?.paymentDate}}. If you want, You can restart your subscription by clicking on "Renew" button.</span>
                    </div>
                    <div class="mb-4" *ngIf="userData.subscribed == 1 && nextSubscriptionData?.status == 3">
                        <span class="text-red-600">Your subscription will be paused from {{nextSubscriptionData?.subscriptionEndDate}}. Your subscription will automatically resume on {{nextSubscriptionData?.paymentDate}}.</span>
                    </div>
                    <!-- on (click)="proceedToPay()" for payments -->
                    <div class="text-right">
                        <button pButton pRipple label="{{processBtn}}" [disabled]="categorieBtn"
                            (click)="proceedToPay()" class="p-button-primary"></button>
                        <button pButton pRipple label="Pause Account for 90 Days"
                            class="p-button-primary ml-2" (click)="pauseSubscription()" *ngIf="userData.subscribed == 1 && nextSubscriptionData?.status == 0"></button>
                        <button pButton pRipple label="Resume"
                            class="p-button-primary ml-2" (click)="resumeSubscription()" *ngIf="userData.subscribed == 1 && nextSubscriptionData?.status == 3"></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- dialog box for Renew Subscription -->
    <p-dialog header="Renew Subscription" [(visible)]="cardDiv" [style]="{width: '40vw'}" [modal]="true"
        [breakpoints]="{'1024px': '70vw', '640px': '90vw'}">
        <!-- <div class="border-top-2 surface-border"></div> -->
        <div>
            <div class=" mt-2">
                <div class="surface-section p-4 surface-card shadow-2">
                    <div class="flex mb-3 md:align-items-center flex-wrap" *ngIf="oldCadDiv">
                        <div class="w-12 md:w-auto">
                            <p-radioButton name="groupname" value="1" [(ngModel)]="selectCard"></p-radioButton>
                            <span class="ml-2">XXXX XXXX XXXX {{cardNumber}}</span>
                        </div>
                        <button pButton pRipple label="Change Card"
                            class="p-button-primary md:ml-auto p-button-text px-0 mt-2 md:mt-0"
                            (click)="changeCard()"></button>
                    </div>
                    <div class="flex mb-3" *ngIf="changeCardDiv">
                        <button pButton pRipple label="Cancel, I'm okay with old card"
                            class="p-button-primary ml-auto p-button-text px-0" (click)="oldCard()"></button>
                    </div>
                    <form [formGroup]="cardForm">
                        <div *ngIf="changeCardDiv">
                            <div class="formgrid p-fluid">
                                <div class="field mb-4">
                                    <label for="Address" class="">Card Number</label>
                                    <p-inputMask mask="9999-9999-9999-9999" placeholder="1234-1234-1234-1234"
                                        autocomplete="off" formControlName="cardNumber"
                                        [ngClass]="{ 'ng-dirty': submitted2 && g['cardNumber'].errors }"></p-inputMask>
                                    <div *ngIf="submitted2 && g['cardNumber'].errors" class="invalid-feedback">
                                        <small *ngIf="g['cardNumber'].errors['required']"
                                            class="p-error block mb-2">Card
                                            Number
                                            is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="grid formgrid p-fluid">
                                <div class="field mb-4 col-6">
                                    <label for="expiryDate" class="">Expiry Date</label>
                                    <p-inputMask mask="99/99" placeholder="MM/YY" formControlName="expiryDate"
                                        autocomplete="off"
                                        [ngClass]="{ 'ng-dirty': submitted2 && g['expiryDate'].errors }">
                                    </p-inputMask>
                                    <div *ngIf="submitted2 && g['expiryDate'].errors" class="invalid-feedback">
                                        <small *ngIf="g['expiryDate'].errors['required']"
                                            class="p-error block mb-2">Expiry
                                            Date
                                            is required</small>
                                    </div>
                                </div>
                                <div class="field mb-4 col-6">
                                    <label for="cvv" class="">CVV</label>
                                    <!-- <p-inputMask mask="9999" id="cvv" placeholder="****" formControlName="cvv"
                             [ngClass]="{ 'ng-dirty': submitted2 && g['cvv'].errors }"></p-inputMask> -->
                                    <input id="cvv" placeholder="Enter CVV" pInputText formControlName="cvv"
                                        type="password" pattern="\d*" maxlength="4" autocomplete="off"
                                        [ngClass]="{ 'ng-dirty': submitted2 && g['cvv'].errors }">
                                    <div *ngIf="submitted2 && g['cvv'].errors" class="invalid-feedback">
                                        <small *ngIf="g['cvv'].errors['required']" class="p-error block mb-2">CVV is
                                            required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid p-fluid">
                                <div class="field mb-4">
                                    <label for="cardHolderName" class="">Cardholder Name</label>
                                    <input id="cardHolderName" type="text" placeholder="Enter Cardholder Name"
                                        formControlName="cardHolderName" autocomplete="off"
                                        [ngClass]="{ 'ng-dirty': submitted2 && g['cardHolderName'].errors }" pInputText>
                                    <div *ngIf="submitted2 && g['cardHolderName'].errors" class="invalid-feedback">
                                        <small *ngIf="g['cardHolderName'].errors['required']"
                                            class="p-error block mb-2">Cardholder Name is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <p-checkbox name="groupname" (onChange)="agree($event)" value="val1"></p-checkbox>
                                <label class="mr-4 ml-2">I agree to pay recurring with this card</label>

                            </div>
                        </div>
                        <div class="border-top-1 surface-border mb-4"></div>
                        <div class="flex mb-3">
                            <label>Subtotal</label>
                            <span class="ml-auto">${{totalAmount}}</span>
                        </div>
                        <div class="flex mb-3">
                            <label>Tax</label>
                            <span class="ml-auto">$0</span>
                        </div>
                        <div class="flex mb-4">
                            <label>Total</label>
                            <span class="ml-auto">${{totalAmount}}</span>
                        </div>
                        <div class="border-top-1 surface-border mb-4"></div>
                        <div class="flex">

                            <button pButton pRipple label="Pay now" (click)="payNow()"
                                class="p-button-primary ml-auto"></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- <div class="mt-3 text-right">
        <p-button label="Close" (click)="display=false" class="p-button-secondery"></p-button>
    </div> -->
    </p-dialog>
    <!-- for toast msg alert -->
    <p-toast position="top-right"></p-toast>
    <!-- confirmDialog box -->
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
        <ng-template pTemplate="footer">
            <button type="button" pButton class="p-button-danger" icon="pi pi-times" label="No"
                (click)="cd.reject()"></button>
            <button type="button" pButton class="p-button-success" icon="pi pi-check" label="Yes"
                (click)="cd.accept()"></button>
        </ng-template>
    </p-confirmDialog>