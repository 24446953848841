import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http:HttpClient
  ) { }
  //For sign up
  signUpStep1(userData:any){
    return this.http.post(environment.apiUrl+'users/registration/step/1',userData);
  }
  //For otp verify
  otpverify(data : any){
    return this.http.post(environment.apiUrl+'users/otp/verify',JSON.stringify(data));
  }
  //For resend otp 
  resentOTP(data : any){
    return this.http.post(environment.apiUrl+'users/otp/send',JSON.stringify(data));
  }
  
}
