<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="surface-ground px-4 pt-4 pb-8 md:px-6">
    <div class="text-900 text-2xl font-medium mb-3" *ngIf="addressDiv">
        Please provide your address to view available plans.
    </div>
    <div class="text-900 text-2xl font-medium mb-3" *ngIf="planDiv">
      <span  *ngIf="paymentPay">Please select your subscription and pay.</span> 
      <span  *ngIf="!paymentPay">Please select your subscription.</span> 
    </div>
    <div class="grid">
        <div class="col-12 lg:col-7 md:pr-3" *ngIf="addressDiv">
            <div class="surface-section p-4 surface-card shadow-2">
                <!-- [formGroup]="addressFrom" used-->
                <form [formGroup]="addressFrom">
                    <div class="formgrid p-fluid">
                        <div class="field mb-4">
                            <label for="Address" class="font-medium">Address</label>
                            <input id="Address" type="text" class="w-full" placeholder="Enter Address" autocomplete="off"
                                #placesRef="ngx-places" ngx-google-places-autocomplete
                                (onAddressChange)="handleAddressChange($event)" formControlName="address"
                                [ngClass]="{ 'ng-dirty': submitted3 && h['address'].errors }" pInputText>
                            <div *ngIf="submitted3 && h['address'].errors" class="invalid-feedback">
                                <small *ngIf="h['address'].errors['required']" class="p-error block">Address is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4" *ngIf="countryNames">
                            <label for="Address" class="font-medium">Country</label>
                            <p for="Address" class="font-medium" style="margin: 0;">{{countryNames}}</p>
                        </div>
                    </div>
                    <div class="text-right">
                        <button pButton pRipple label="Next" (click)="getAddress()" class="p-button-primary"></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 lg:col-8 md:pr-3" *ngIf="planDiv">
            <div class="surface-section p-4 surface-card shadow-2">
                <div class="block md:flex">
                    <div class="mb-3 mr-3">
                        <div class="mb-3"><label>Address</label></div>
                        <span>{{fullAddress}}</span>
                    </div>
                    <div class="mb-3 mr-3">
                        <div class="mb-3"><label class="ml-auto">Country</label></div>
                        <span class="ml-auto inline-block" style="min-width: 200px;">{{countryNames}}</span>
                    </div>
                    <div class="mb-3 ml-auto">
                        <button pButton pRipple label="Edit" class="p-button-primary" [disabled]="categorieBtn" (click)="editAddress()"></button>
                    </div>
                </div>
                <div class="border-top-1 surface-border mb-4"></div>
                <!-- [formGroup]="categoryForm" used -->
                <form [formGroup]="categoryForm">
                    <div class="grid" *ngIf="paymentPay">
                        <div class="col-12 md:col-6">
                            <div class="p-4 border-1 border-primary mb-3 md:mb-4 cursor-pointer"
                                style="border-radius: 8px" [ngClass]="{'border-blue-500': payment === 0}"
                                (click)="payment = 0">
                                <div class="flex align-items-center mb-4">
                                    <p-radioButton [value]="0" class="mr-3" formControlName="plan"></p-radioButton>
                                    <div class="flex w-full">
                                        <div class="text-900 text-xl font-medium">Monthly</div>
                                        <div class="text-900 text-xl font-medium ml-auto">${{monthlyAmount}}</div>
                                    </div>
                                </div>
                                <p class="text-600 m-0 line-height-3">Per Month No Contract.</p>
                            </div>
                        </div>
                        <!-- <div class="col-12 md:col-6">
                            <div class="p-4 border-1 border-primary mb-3 md:mb-4 cursor-pointer"
                                style="border-radius: 8px" [ngClass]="{'border-blue-500': payment === 1}"
                                (click)="payment = 1">
                                <div class="flex align-items-center mb-4">
                                    <p-radioButton [value]="1" class="mr-3" formControlName="plan"></p-radioButton>
                                    <div class="flex w-full">
                                        <div class="text-900 text-xl font-medium">Yearly</div>
                                        <div class="text-900 text-xl font-medium ml-auto">$150.00</div>
                                    </div>
                                </div>
                                <p class="text-600 m-0 line-height-3">Please provide description text.</p>
                            </div>
                        </div> -->

                        <!-- [ngClass]="{ 'is-invalid': submitted && categories.controls[i].get('name').errors?.required }" -->
                    </div>
                    <ng-container formArrayName="categories" *ngFor="let item of getCategoriesControls();let i = index;first as first, last as last">
                    <div class="formgrid p-fluid grid" [formGroupName]="i" *ngIf="categories.controls[i].get('catStatus')?.value == 1">
                        <div class="field mb-4 col-12 md:col-4" >
                            <label for="Category" class="font-medium">Select a Media Platform *</label>
                            <p-dropdown [options]="mediaPlatform" optionLabel="label" optionValue="value"
                                placeholder="Select a Media Platform" formControlName="mediaPlatform"
                                [ngClass]="{ 'ng-dirty': submitted && categories.controls[i].get('mediaPlatform')?.hasError('required') }"
                                >
                            </p-dropdown>
                            <div *ngIf="submitted && categories.controls[i].get('mediaPlatform')?.hasError" class="invalid-feedback">
                                <small *ngIf="categories.controls[i].get('mediaPlatform')?.hasError('required')" class="p-error block mb-2">Media
                                    Platform is required</small>

                            </div>
                        </div>
                        <div class="field mb-4 col-12 md:col-4">
                            <label for="Category" class="font-medium">Select a Department *</label>
                            <p-dropdown [options]="departmentList" optionLabel="name" placeholder="Select a Department"
                                formControlName="categoryId" optionValue="id" (onChange)="departmentSelect($event,i)"
                                [ngClass]="{ 'ng-dirty': submitted && categories.controls[i].get('categoryId')?.hasError('required') }"
                                ></p-dropdown>
                            <div *ngIf="submitted && categories.controls[i].get('categoryId')?.hasError" class="invalid-feedback">
                                <small *ngIf="categories.controls[i].get('categoryId')?.hasError('required')" class="p-error block mb-2">
                                    Department is required</small>

                            </div>
                        </div>
                   
                  
                        <div class="field mb-4 col-12 md:col-4">
                            <label for="selectAdditionalDepartment" class="font-medium">Select a Job Title *</label>
                            <p-dropdown [options]="subCategoryList[i]" optionLabel="name"
                                placeholder="Select a Job Title" optionValue="id"
                                formControlName="subcategories"
                                [ngClass]="{ 'ng-dirty': submitted && categories.controls[i].get('subcategories')?.hasError('required') }"
                                >
                            </p-dropdown>
                            <div *ngIf="submitted && categories.controls[i].get('subcategories')?.hasError" class="invalid-feedback">
                                <small *ngIf="categories.controls[i].get('subcategories')?.hasError('required')"
                                    class="p-error block mb-2">Job Title is required</small>
                            </div>
                        </div>
                        
                    </div>

                    <div class="formgrid p-fluid grid">
                        <div class="field mb-4 col-12 text-right -mt-3" *ngIf="(i >= 0 && !first || !last) && categories.controls[i].get('catStatus')?.value == 1">
                            <span (click)="removeItem(i,item)" [class.disabled]="cardDiv == true" *ngIf="deleteBtn" class="text-red-600 cursor-pointer">Delete Row</span>
                        </div>
                        <div class="field mb-4 col-12 text-right" *ngIf="last">
                            <button pButton pRipple label="Additional Row" icon="pi pi-plus" [disabled]="categorieBtnAdd"
                            class="w-auto bg-gray-900 border-gray-900" (click)="addCategories()"></button><br>
                            <small class="text-gray-600" *ngIf="paymentPay"><i>Only @ $1.00/Month Per Department</i></small>
                        </div>
                    </div>

                    </ng-container>
                    <div *ngIf="paymentPay">
                        <h3 class="mt-0 mb-4 font-xl">Total amount payable: ${{totalAmount}}</h3>
                    </div>
                    <div class="text-right">
                        <button pButton pRipple label="{{processBtn}}" [disabled]="categorieBtn" (click)="proceedToPay()"
                            class="p-button-primary"></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 lg:col-4 md:pl-3" *ngIf="cardDiv && paymentPay">
            <div class="surface-section p-4 surface-card shadow-2">
                <!-- [formGroup]="cardForm" used -->
                <form [formGroup]="cardForm">
                    <div class="formgrid p-fluid">
                        <div class="field mb-4">
                            <label for="Address" class="font-medium">Card Number</label>
                            <p-inputMask mask="9999-9999-9999-9999" placeholder="1234-1234-1234-1234" autocomplete="off"
                                formControlName="cardNumber"
                                [ngClass]="{ 'ng-dirty': submitted2 && g['cardNumber'].errors }"></p-inputMask>
                            <div *ngIf="submitted2 && g['cardNumber'].errors" class="invalid-feedback">
                                <small *ngIf="g['cardNumber'].errors['required']" class="p-error block mb-2">Card Number
                                    is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-6">
                            <label for="expiryDate" class="font-medium">Expiration Date</label>
                            <p-inputMask mask="99/99" placeholder="MM/YY" formControlName="expiryDate" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submitted2 && g['expiryDate'].errors }"></p-inputMask>
                            <div *ngIf="submitted2 && g['expiryDate'].errors" class="invalid-feedback">
                                <small *ngIf="g['expiryDate'].errors['required']" class="p-error block mb-2">Expiry Date
                                    is required</small>
                            </div>
                        </div>
                        <div class="field mb-4 col-6">
                            <label for="cvv" class="font-medium">CVV</label>
                            <!-- <p-inputMask mask="9999" id="cvv" placeholder="****" formControlName="cvv"
                             [ngClass]="{ 'ng-dirty': submitted2 && g['cvv'].errors }"></p-inputMask> -->
                            <input id="cvv" placeholder="Enter CVV" pInputText formControlName="cvv" type="password" autocomplete="off"
                                pattern="\d*" maxlength="4" [ngClass]="{ 'ng-dirty': submitted2 && g['cvv'].errors }">
                            <div *ngIf="submitted2 && g['cvv'].errors" class="invalid-feedback">
                                <small *ngIf="g['cvv'].errors['required']" class="p-error block mb-2">CVV is
                                    required</small>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid p-fluid">
                        <div class="field mb-4">
                            <label for="cardHolderName" class="font-medium">Cardholder Name</label>
                            <input id="cardHolderName" type="text" placeholder="Enter Cardholder Name"
                                formControlName="cardHolderName" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submitted2 && g['cardHolderName'].errors }" pInputText>
                            <div *ngIf="submitted2 && g['cardHolderName'].errors" class="invalid-feedback">
                                <small *ngIf="g['cardHolderName'].errors['required']"
                                    class="p-error block mb-2">Cardholder Name is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <p-checkbox name="groupname" (onChange)="agree($event)" value="val1"></p-checkbox>
                        <label class="mr-4 ml-2">I agree to pay recurring with this card.</label>

                    </div>
                    <div class="border-top-1 surface-border mb-4"></div>
                    <div class="flex mb-3">
                        <label>Subtotal</label>
                        <span class="ml-auto">${{totalAmount}}</span>
                    </div>
                    <div class="flex mb-3">
                        <label>Tax</label>
                        <span class="ml-auto">$0</span>
                    </div>
                    <div class="flex mb-4">
                        <label>Total</label>
                        <span class="ml-auto">${{totalAmount}}</span>
                    </div>
                    <div class="border-top-1 surface-border mb-4"></div>
                    <div class="flex">
                        <button pButton pRipple label="Cancel" (click)="cancel()" class="p-button-secondary p-button-outlined"></button>
                        <button pButton pRipple label="Pay Now" (click)="payNow()" [disabled]="!isAgree"
                            class="p-button-primary ml-auto"></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- toast msg alert -->
<p-toast position="top-right"></p-toast>
<!-- confirm dialog box -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
    <ng-template pTemplate="footer">
        <button type="button" pButton  class="p-button-danger" icon="pi pi-times" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton class="p-button-success"  icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>