<div class="surface-ground px-4 py-7 md:px-6 lg:px-8">
    <div class="flex flex-wrap shadow-2 container">
        <div class="w-full md:w-6 bg-blue-50">
            <!-- <img src="assets/images/bastion-700.svg" alt="Image" height="35" class="mb-6"> -->
            <!-- <p-carousel [value]="features">
                <ng-template let-feature pTemplate="item">
                    <div class="text-center mb-8">
                        <img src="assets/images/blocks/illustration/{{feature.image}}" alt="Image" class="mb-6 w-6">
                        <div class="mx-auto text-xl mb-4 text-blue-900">{{feature.title}}</div>
                        <p class="m-0 text-blue-700 line-height-3">{{feature.text}}</p>
                    </div>
                </ng-template>
            </p-carousel> -->
            <div class="text-center relative h-full">
                <img src="assets/images/resetpassword.jpg" alt="Image" class="reset-banner">
                <div class="mx-auto banner-text p-3">
                    <div class="text-2-line">Each Crew Resumes webpage is 
                    designed to 
                    give the producer quick results leaving no question unanswered and 
                    therefore eliminating time consuming calls, emails and google searches 
                    to hire the crew they need.
                    </div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog4()"></button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="div1" class="w-full md:w-6 p-4 lg:p-7 surface-card">
            <div class="block md:flex align-items-center justify-content-between mb-7">
                <span class="text-2xl text-900">Forgot Password</span>
                <a tabindex="0" class="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150 mt-3 md:mt-0 block" style="text-decoration: none;"  routerLink="/">Back to Log In</a>
            </div>
            <!-- formGroup logForm used-->
           <form [formGroup]="logForm">
                <div class="form-group mb-4">
                    <label for="email" class="block text-900 mb-2">Email</label>
                    <input id="email" type="email" formControlName="email" autocomplete="off"
                    [ngClass]="{ 'ng-dirty': submitted && f['email'].errors }"
                    pInputText class="w-full form-control" placeholder="Enter Email">
                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <small *ngIf="f['email'].errors['required']" class="p-error block">Email is required</small>
                     </div>
                </div>
                <div class="form-group mb-0" style="margin-bottom: 200px;">
                    <button pButton pRipple label="Submit" (click)="onSubmit()" class="w-full"></button>
                </div>  
            </form>      
        </div> 
        <div *ngIf="div2" class="w-full lg:w-6 p-4 lg:p-7 surface-card">
            <div class="flex align-items-center justify-content-between mb-7">
                <span class="text-2xl text-900">OTP Verification</span>
                <a tabindex="0" class=" text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" style="text-decoration: none;"  routerLink="/">Back to Log In</a>
            </div>
            <!-- for otp logForm3 formGroup used -->
           <form [formGroup]="logForm3" (ngSubmit)="getOtp()">
                <div class="form-group mb-4">
                    <div class="field mb-4 mt-2">
                        <div class="text-700">Check your email inbox and spam folder too. We've sent you the OTP at <b>{{f['email'].value}}</b>.<br>Wrong Email ID? Click <a href="javascript:void(0)" (click)="backToSignUp()">here</a> to go back.</div>
                    </div>
                    <label for="otp" class="block text-900  mb-2">OTP</label>
                    <p-inputMask mask="9 9 9 9 9 9" formControlName="otp" min="0" max="99" number-mask="" autocomplete="off"
                    [ngClass]="{ 'ng-dirty': submitted3 && h['otp'].errors }"
                    styleClass="w-full form-control" placeholder="Enter OTP"></p-inputMask>
                   <div *ngIf="submitted3 && h['otp'].errors" class="invalid-feedback">
                        <small *ngIf="h['otp'].errors['required']" class="p-error block">OTP is required</small>
                    </div>
                    <div class="field mb-4 mt-2 text-right">
                        <span class="text-700" *ngIf="timerDiv">Resend OTP in {{timeLeft}} Seconds.</span>
                        <span class="text-red-600 cursor-pointer" (click)="resendOTP()" *ngIf="ResendOTPDiv">Resend OTP</span>
                    </div>
                </div>
            
                <div class="form-group">
                    <button pButton pRipple label="Verify OTP" class="w-full py-3"></button>

                </div>  
            </form>      
        </div>
        <div *ngIf="div3" class="w-full lg:w-6 p-4 lg:p-7 surface-card">
            <div class="flex align-items-center justify-content-between mb-7">
                <span class="text-2xl text-900">Reset Password</span>
                <a tabindex="0" class="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" style="text-decoration: none;"  routerLink="/">Back to Log In</a>
            </div>
            <!-- logForm2 formGroup used -->
           <form [formGroup]="logForm2" (ngSubmit)="changePassword()">
            <div class="form-group mb-4">
                <label for="password" class="block text-900 mb-2">New Password</label>
                <input id="password" [type]="password"  formControlName="password" autocomplete="off"
                [ngClass]="{ 'ng-dirty': submitted2 && g['password'].errors }"
                pInputText class="w-full form-control" placeholder="Enter New Password">
                <span class="eye-tpoggle" (click)="onClick(1)">
                    <i class="pi pi-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                    <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
                </span>
                <div *ngIf="submitted2 && g['password'].errors" class="invalid-feedback">
                    <small *ngIf="g['password'].errors['required']" class="p-error block">New Password is required</small>
                </div>
                <div *ngIf="g['password'].errors" class="invalid-feedback">
                    <small *ngIf="g['password'].errors['pattern']" class="p-error block">Password must contain more than eight characters, at least one uppercase letter, at least one lowercase letter, at least one number, at least one special character($@$!%*?&)
                    </small>
                </div>
            </div>
            <div class="form-group mb-4">
                <label for="confirmPassword" class="block text-900 mb-2">Confirm Password</label>
                <input id="confirmPassword" [type]="connPassword"  formControlName="confirmPassword" autocomplete="off"
                [ngClass]="{ 'ng-dirty': submitted2 && g['confirmPassword'].errors }"
                pInputText class="w-full form-control" placeholder="Enter New Password">
                <span class="eye-tpoggle" (click)="onClick(2)">
                    <i class="pi pi-eye" aria-hidden="true" *ngIf="!showConnPassword"></i>
                    <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showConnPassword"></i>
                </span>
                <div *ngIf="submitted2 && g['confirmPassword'].errors" class="invalid-feedback">
                    <small *ngIf="g['confirmPassword'].errors['required']" class="p-error block">Confirm Password is required</small>
                </div>
                <div  *ngIf="submitted2 && logForm2.hasError('notSame')" class="invalid-feedback mb-2">
                    <small class="p-error block">Confirm Passwords do not match</small> 
                 </div> 
            </div>
            <!-- Save and update password -->
            <div class="form-group">
                <button pButton pRipple label="Change Password" class="w-full"></button>

            </div>  
            </form>      
        </div>
    </div>
</div>
<!-- read more dialogbox -->
<p-dialog header="Welcome to Crew Resumes" [(visible)]="display5" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div>
       <p>Each Crew Resumes webpage is 
        designed to 
        give the producer quick results leaving no question unanswered and 
        therefore eliminating time consuming calls, emails and google searches 
        to hire the crew they need.
        </p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display5=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- toast masg alert -->
<p-toast position="top-right"></p-toast>