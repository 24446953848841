import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators,FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UserService } from '../../user.service';
@Component({
  selector: 'app-crew-payment-method',
  templateUrl: './crew-payment-method.component.html',
  styleUrls: ['./crew-payment-method.component.scss']
})
export class CrewPaymentMethodComponent implements OnInit {
  cardForm: FormGroup;
  submittedCardForm :boolean = false;
  cardDiv :boolean = false;
  cardEditDiv :boolean = false;
  cardAddDiv : boolean = false;
  btnName :any ='Add Card'
  cardData: any = {}
  userId : any
  cardNumber : any
  pageLoader : boolean = false
  cardInfo: any = {}
  updateCardNo : any

  /**
  * @constructor
  * @param {UserService} userService - The service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {FormBuilder} formBuilder -  FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.  
  */
  constructor( 
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    ) { 
    // for form declariation
    this.cardForm = this.formBuilder.group({
      cardNumber: new FormControl('', Validators.required),
      expiryDate: new FormControl('', Validators.required),
      cvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
      cardHolderName: new FormControl('', Validators.required),
    });
  }

  // for form control
  get g() { return this.cardForm.controls; }
  
  ngOnInit(): void {
    this.getCardDetails()
  }
  
  /**
   * For grtting card details.
   * @method getCardDetails
   * @returns { Object } Object
   */
  getCardDetails(){
    this.pageLoader = true
    this.userService.getCardDetails().subscribe((x: any) => {
      this.pageLoader = false
      this.cardData = x
      if(Object.keys(this.cardData).length > 0){
        this.cardEditDiv = true
        this.btnName = 'Edit Card'
      //this.cardNumber = this.cardData.cardNo.toString().replace(/\d(?=\d{4})/g, "x");
      this.cardNumber = this.cardData.cardNo.slice(12, 16);
      this.cardForm.controls['cardNumber'].setValue(this.cardData.cardNo);
      // this.cardForm.controls['expiryDate'].setValue(this.cardData.expMonth.toString() + this.cardData.expYear.toString());
      this.cardForm.controls['cardHolderName'].setValue(this.cardData.cardHolderName);
    }else{
      this.cardAddDiv = true
    }
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
   * For update and save the details.
   * @method updateCard
   * @returns { Object } Object
   */
  updateCard() {
    this.submittedCardForm = true;
    if (this.cardForm.invalid) {
      this.messageService.add({ severity: 'error', 
      summary: 'Error', 
      detail: 'Please fill up all the required fields ' });
      return;
    }
    if(this.cardForm.value.cardNumber.toString().match(/\W/)){
      this.updateCardNo = this.cardForm.value.cardNumber.replace(/-/g, "").toString()  // Contains at least one special character or space
    } else {
      this.updateCardNo = this.cardData.cardNo
    }
    this.cardInfo = {
      cardHolderName: this.cardForm.value.cardHolderName,
      cardNo: this.updateCardNo,
      expMonth: this.cardForm.value.expiryDate.slice(0, 2),
      expYear: this.cardForm.value.expiryDate.slice(3, 5),
      cvv: this.cardForm.value.cvv
    }
    // console.log(this.cardInfo)
    // return
    this.userService.updateCardDetails(this.cardInfo).subscribe((x: any) => {
      this.getCardDetails()
      this.cardDiv = false;
      this.cardEditDiv = true;
      this.messageService.add({ severity: 'success', 
      summary: 'Success', 
      detail: 'Record updated successfully' });
    }, (error: any) => {
      this.messageService.add({ severity: 'error', 
      summary: 'Error', 
      detail: error.error.error.message });
    })
  }

   // for edit card details.
  editCard() {
    if(Object.keys(this.cardData).length > 0){
    this.cardDiv = true;
    this.cardEditDiv = false;
    }else{
      this.cardDiv = true;
      this.cardAddDiv = false;
    }
  }

  // for cancel the transactions.
  cancel(){
    if(Object.keys(this.cardData).length > 0){
      this.cardDiv = false;
      this.cardEditDiv = true;
    }else{
      this.cardDiv = false;
      this.cardAddDiv = true;
    }
  }
}
