<div class="surface-ground px-4 pt-4 pb-8 md:px-6">
    <div style="margin: 50px auto 0;" class="text-center">
        <div class="mb-4"><i class="pi pi-heart" style="font-size: 4rem; color: #3af1bb;"></i></div>
        <div class="text-900 text-xl mb-3" style="font-weight: 300;">
            <img src="assets/images/congratulations.png" alt="" style="max-width: 400px; width: 100%;">
            <div class="mt-3 mb-6">Your account has been created successfully. Finish your profile now by clicking the button below.</div>
        </div>
        <!-- navigate the page -->
        <div class="congrats-btn">
            <button pButton pRipple label="Continue" [routerLink]="['/dashboard']" class="p-button-outlined px-6"></button>
        </div>
    </div>
</div>