
<div class="px-3 md:px-6 surface-ground pt-4 pb-7">
    <div class="dashboard-alert md:hidden" *ngIf="paymentSubscription == 0">
        <p-messages severity="error"> 
            <ng-template pTemplate>
                <div class="mr-4">Your account has been deactivated. Please renew your subscription below to enable access to your account.</div>
                <!-- <button pButton pRipple label="Renew"
                class="p-button-danger md:ml-auto mt-3 md:mt-0"></button> -->
                <i class="pi pi-arrow-down text-2xl ml-auto"></i>
            </ng-template>
        </p-messages>
    </div>
    <div class="grid">
        <div class="col-12 lg:col-3 md:col-4">
            <!-- on click it navigate to crew-profile -->
            <div class="pb-3 md:pb-4">
                <p-button label="Go to Profile Page" routerLink="/crew-profile"  icon="pi pi-arrow-left" styleClass="p-button p-button-lg p-button-success w-12 border-round-sm dashboard-back-btn" ></p-button>
            </div>
            <div class="showMenu" [class.show]="show">
                <div class="block md:hidden menubtnContainer">
                    <button class="w-full p-button-outlined p-button-secondary menu-btn" (click)="show = !show"
                    label="Menu" pButton type="button" icon="pi pi-angle-down" iconPos="right"></button>
                </div>
                <div class="editprofileMenu">
                    <div class="surface-card shadow-2 select-none border-round-sm">
                        <!-- dashboard sidebar menu item -->
                        <ul class="list-none p-2 m-0 mb-3 md:mb-4">
                            <li>
                                <a *ngFor="let item of items" [ngClass]="{'bg-primary-600 text-white activeBack': selectedItem.label == item.label , 'disabled-link' : item.disable === 0 }" (click)="listClick($event, item)"
                                pRipple class="flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150">
                                    <span class="">{{item.label}}</span>
                                </a>
                            </li>
                            <li>
                                <!-- for logout menu -->
                                <a (click)="logout()" pRipple class="flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150">
                                    <!-- <i class="pi pi-calendar mr-2 text-700"></i> -->
                                    <span class="">Log Out</span>
                                    <!-- <span class="inline-flex align-items-center justify-content-center ml-auto bg-blue-100 text-blue-900 border-circle" style="min-width: 1.5rem; height: 1.5rem">3</span> -->
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- on click delete the account  -->
                    <div>
                        <button pButton pRipple label="Delete Account" (click)="delete()" class="p-button-secondary p-button-outlined w-full"></button>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-12 md:col-8 lg:col-9">
            <app-crew-edit-profile *ngIf="selectedItem.page == 'edit'"></app-crew-edit-profile>
            <app-crew-media *ngIf="selectedItem.page == 'media'" [data]="mediaType"></app-crew-media>
            <app-crew-edit-subscription *ngIf="selectedItem.page == 'subscription'"></app-crew-edit-subscription>
            <app-crew-transaction-history *ngIf="selectedItem.page == 'crewTransactionHistory'"></app-crew-transaction-history>
            <app-crew-payment-method *ngIf="selectedItem.page == 'PaymentMethod'"></app-crew-payment-method>
            <app-crew-change-password *ngIf="selectedItem.page == 'changePassword'"></app-crew-change-password>
        </div>
    </div>
</div>
