import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SigninRoutingModule } from './signin-routing.module';

import { SharedModule } from 'src/app/shared/shared.module';
import { SigninComponent } from './signin.component';
import { commonModule } from '../common/common.module';


@NgModule({
  declarations: [
   SigninComponent
  ],
  imports: [
    CommonModule,
    SigninRoutingModule,
    SharedModule,
    commonModule
  ]
})
export class SigninModule { }
