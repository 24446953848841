import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonRoutingModule } from './common-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NonLoginFooterComponent } from './non-login-footer/non-login-footer.component';
import { NonLoginHeaderComponent } from './non-login-header/non-login-header.component';
import { P404Component } from './p404/p404.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NonLoginFooterComponent,
    NonLoginHeaderComponent,
    P404Component,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    SharedModule,
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    P404Component,
    HomeComponent, 
    NonLoginFooterComponent,
    NonLoginHeaderComponent,
  ]
})
export class commonModule { }
