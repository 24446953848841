import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public isUserloggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.getToken == false ? false : true
  );
  constructor( private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = this.getUser();
      if(currentUser)
      {
      return true;
      }else{
        this.router.navigate(['/'])
        return false;
      }
  }
  get isUsersLoggedIn() {
    return this.isUserloggedIn.asObservable();
  }
  setLoggedIn() {
    this.isUserloggedIn.next(this.getToken == false ? false : true);
  }
  signOut(){
    this.isUserloggedIn.next(false) ;
    sessionStorage.clear()
    this.router.navigate(['/'])
  }
  saveUser(data : any){
    sessionStorage.removeItem("loggedIn")
    sessionStorage.setItem("loggedIn",JSON.stringify(data.token))
    sessionStorage.setItem("b-sessionToken",JSON.stringify(data.token))
    sessionStorage.setItem("tokenKey",(data.token))
   }
  getUser():any{
    return sessionStorage.getItem("loggedIn")
  }
  get getToken(): any {
    let token = sessionStorage.getItem('b-sessionToken');
    if (token) {
      return token;
    } else {
      return false;
    }
  }
}
