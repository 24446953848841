import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { ConfirmationService } from 'primeng/api';
import { MediaPlatform } from 'src/app/shared/shared';
import { UserService } from '../../user.service'

@Component({
  selector: 'app-crew-edit-subscription',
  templateUrl: './crew-edit-subscription.component.html',
  styleUrls: ['./crew-edit-subscription.component.scss']
})
export class CrewEditSubscriptionComponent implements OnInit {
  categoryForm: FormGroup;
  mediaPlatform: any = []
  departmentList: any = []
  subCategoryList: any = []
  categories: FormArray;
  categorieBtn = false
  categorieBtnAdd = false
  totalAmount: any
  submitted: boolean = false;
  planDiv = true;
  cardDiv = false;
  cardForm: FormGroup;
  selectedRow: any;
  isAgree = false;
  addressDiv = false
  addressFrom: FormGroup;
  countryNames: string = '';
  stateName: string = '';
  cityName: string = '';
  latituded: string = '';
  longituded: string = '';
  street: string = '';
  zip: string = '';
  locationName: string = '';
  countryCode: string = '';
  fullAddress: any;
  address: any = {}
  categoryObj: any = {}
  userData: any = {}
  submitted2: boolean = false;
  submitted3: boolean = false;
  display: boolean = false;
  paymentPay = false
  pageLoader = false
  categoriesNew: any = []
  processBtn: string = 'Update'
  totalRemainingAmount = 14.00
  paymentSubscription: boolean = false
  cardNumber: any
  cardData: any = {}
  changeCardDiv: boolean = false
  oldCadDiv: boolean = true
  selectCard: any
  monthlyAmount: any
  nextSubscriptionData: any;
  @ViewChild("placesRef") placesRef!: GooglePlaceDirective;
  // submitted: boolean = false;

  /**
  * @constructor
  * @param {UserService} userService - The subservice service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {FormBuilder} formBuilder -  FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.  
  * @param {ConfirmationService} confirmationService - Confirmation services is required to display it by calling confirm method.
  */
  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private userService: UserService,
  ) {
    this.selectCard = 1
    this.userService.getFiels().subscribe((data: any) => {
      this.mediaPlatform = data['mediaPlatform']
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
    this.userService.getCategory().subscribe((x: any) => {
      this.departmentList = x
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    });
    this.userService.getSubscriptionPlan().subscribe((x: any) => {      
      this.monthlyAmount = x.amount
      this.totalAmount = x.amount
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    });

    // Form declaration
    this.addressFrom = this.formBuilder.group({
      address: new FormControl('', Validators.required),
    });
    this.categoryForm = new FormGroup({
      categories: this.formBuilder.array([]),
      plan: new FormControl(''),

    })

    this.categories = this.categoryForm.get('categories') as FormArray;
    //for card details only
    this.cardForm = this.formBuilder.group({
      cardNumber: new FormControl('', Validators.required),
      expiryDate: new FormControl('', Validators.required),
      cvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
      cardHolderName: new FormControl('', Validators.required),
    });
    this.addCategories()
  }
  // for form controls
  get f() { return this.categoryForm.controls; }
  get g() { return this.cardForm.controls; }
  get h() { return this.addressFrom.controls; }

  ngOnInit(): void {
    this.getData()
    this.getNextSubscription()
  }

  /**
   * select in dropdown list on change event.
   * @method departmentSelect
   * @returns { Object } Object
   */
  departmentSelect(ev: any, i: any) {
    this.pageLoader = true
    //this.cat.controls[i].get('subcategories')?.reset()
    let value = ev.value
    this.userService.getSubCategoryList(value).subscribe((data: any) => {
      this.pageLoader = false
      this.subCategoryList[i] = data
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
  * for geting all item of input list.
  * @method getData
  * @returns { Object } Object
  */
  async getData() {
    this.pageLoader = true
    this.categories = this.categoryForm.get('categories') as FormArray;
    this.categories.controls = [];
    this.userService.getUserData().subscribe((data: any) => {
      this.pageLoader = false
      this.userData = data
      this.countryCode = data.address.countryCode
      if ((this.countryCode == 'US' || this.countryCode == 'CA')) {
        this.paymentPay = true
        this.userService.renewState$.next(this.userData.subscribed);
        if (this.userData.subscribed == 0) {
          this.getCardDetails()
          this.processBtn = "Renew"
          this.paymentSubscription = true
        } else {
          this.processBtn = "Update"
          this.paymentSubscription = false
        }
      }
      //console.log(data)
      const promise = new Promise(async (resolve, reject) => {
        //create form array 
        await data.usercategories.map(async (item: any, index: any) => {
          //console.log(item);
          this.userService.getSubCategoryList(item.categoryId).subscribe((data: any) => {
            this.subCategoryList[index] = data
          })
          const tempRows: any = {};
          tempRows['id'] = new FormControl(item.id);
          tempRows['mediaPlatform'] = new FormControl(item.mediaPlatform);
          tempRows['categoryId'] = new FormControl(item.categoryId);
          tempRows['subcategories'] = new FormControl(item.subcategories);
          tempRows['catStatus'] = new FormControl(1);
          this.categories.push(new FormGroup(tempRows));
        })
        this.totalRemainingAmount = 14.00 + this.categories.length
        this.totalAmount = this.totalRemainingAmount
        if (this.categories.length >= 5) {
          this.categorieBtnAdd = true
        }
        resolve('Success!');
      });
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  /**
   * For getting card details.
   * @method getCardDetails
   * @returns { Object } Object
   */
  getCardDetails() {
    this.userService.getCardDetails().subscribe((x: any) => {
      this.cardData = x
      if (Object.keys(this.cardData).length > 0) {
        //this.cardNumber = this.cardData.cardNo.toString().replace(/\d(?=\d{4})/g, "x");
        this.cardNumber = this.cardData.cardNo.slice(12, 16);
      }
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  // Form control
  getCategoriesControls() {
    return (this.categoryForm.get('categories') as FormArray).controls;
  }

  // Add new category
  addCategories(): void {
    this.categories = this.categoryForm.get('categories') as FormArray;
    this.categoriesNew = []
    this.categories.push(this.createCategory());
    this.totalAmount = this.totalAmount + 1
    this.categories.controls.forEach(element => {
      if (element.value.catStatus == 1) {
        this.categoriesNew.push(element)
        //console.log(this.categoriesNew.length)
        if (this.categoriesNew.length >= 5) {
          this.categorieBtnAdd = true
        }
      }
    })
  }

  /**
   * for remove the departments on click delete row.
   * @method removeItem
   * @returns { Object } Object
   */
  removeItem(index: any, item: any) {
    this.confirmationService.confirm({
      message: 'Do yo really wish to delete?',
      accept: () => {
        this.selectedRow = index;
        //this.cat.controls[index].get('subcategories')?.value
        //this.categories.removeAt(this.selectedRow);
        if (item.value.id != null) {
          this.userService.deleteUserCategory(item.value.id).subscribe((data: any) => {
            this.getData()
          })
        } else {
          this.categories.controls[index].get('catStatus')?.setValue(0)
          this.categories.removeAt(index);
        }
        this.totalAmount = this.totalAmount - 1
        //this.totalAmount = this.totalRemainingAmount - 1
        this.categorieBtnAdd = false
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record deleted Successfully' });
      }, reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No changes made' });
      }
    });
  }

  get cat() {
    return (<FormArray>this.categoryForm.get('categories'));
  }

  // Create form object
  createCategory(): FormGroup {
    this.submitted = false;
    return this.formBuilder.group({
      mediaPlatform: new FormControl('', Validators.required),
      categoryId: new FormControl('', Validators.required),
      subcategories: new FormControl('', Validators.required),
      catStatus: new FormControl(1),
    });
  }
  // on click agree to confirm.
  agree(ev: any) {
    this.isAgree = ev.originalEvent.detail
  }

  cancel() {
    this.cardDiv = false
    this.categorieBtn = false
    this.categorieBtnAdd = false
    this.categoryForm.enable()
  }
  /**
   * on click proceedToPay function it will navigate to payments processing.
   * @method proceedToPay
   * @returns { Object } Object
   */
  proceedToPay() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields ' });
      return;
    }
    let usercategories: any[] = []
    this.categoryForm.value.categories.map((item: any, index: any) => {
      if (item.catStatus === 1) {
        const tempRows: any = {};
        tempRows['id'] = item.id != null ? item.id : 0
        tempRows['mediaPlatform'] = item.mediaPlatform;
        tempRows['categoryId'] = item.categoryId;
        tempRows['subcategories'] = item.subcategories;
        usercategories.push(tempRows);
      }
    })
    this.categoryObj['usercategories'] = usercategories
    // console.log(this.categoryObj)
    // return
    // if (this.countryCode == 'US' || this.countryCode == 'CA') {
    //   this.cardDiv = true
    //   this.categorieBtn = true
    //   this.categorieBtnAdd = true
    //   this.categoryForm.disable()
    // } else {
    this.userService.updateUserCategory(this.categoryObj).subscribe((x: any) => {
      if ((this.countryCode == 'US' || this.countryCode == 'CA') && this.userData.subscribed == 0) {
        this.cardDiv = true
      } else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record updated Successfully' });
        this.getData()
      }
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    }
    );
    // }
  }
  // change card for payments
  changeCard() {
    this.changeCardDiv = true
    this.oldCadDiv = false
  }
  // old card
  oldCard() {
    this.changeCardDiv = false
    this.oldCadDiv = true
  }
  /**
   * on click paynow function it will navigate to payments.
   * @method payNow
   * @returns { Object } Object
   */
  payNow() {
    let obj: any = {}
    obj['amount'] = this.totalAmount
    if (this.changeCardDiv == true) {
      this.submitted2 = true;
      if (this.cardForm.invalid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill up all the required fields '
        });
        return;
      }
      let cardInfo = {
        cardHolderName: this.cardForm.value.cardHolderName,
        cardNo: this.cardForm.value.cardNumber.replace(/-/g, "").toString(),
        expMonth: this.cardForm.value.expiryDate.slice(0, 2),
        expYear: this.cardForm.value.expiryDate.slice(3, 5),
        cvv: this.cardForm.value.cvv
      }
      obj['cardDetails'] = cardInfo
      this.pageLoader = true
      this.userService.renewCard(obj).subscribe((x: any) => {
        this.pageLoader = false
        this.cardDiv = false
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Subscription has been successfully renewed' });
        this.getData()
        this.getCardDetails()
      }, (error: any) => {
        this.pageLoader = false
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
      });
    } else {
      this.pageLoader = true
      this.userService.renewCard(obj).subscribe((x: any) => {
        this.pageLoader = false
        this.cardDiv = false
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Subscription has been successfully renewed' });
        this.getData()
        this.getCardDetails()
      }, (error: any) => {
        this.pageLoader = false
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
      });
    }
  }

  editAddress() {
    this.paymentPay = false
    this.addressDiv = true
    this.planDiv = false
    this.cardDiv = false
    this.addressFrom.controls['address'].setValue(this.fullAddress)
  }

  getAddress() {
    this.submitted3 = true;
    if (this.addressFrom.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields ' });
      return;
    }
    if (this.fullAddress == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up proper address ' });
      return;
    }
    this.address = {
      fullAddress: this.fullAddress,
      country: this.countryNames,
      city: this.cityName,
      state: this.stateName,
      longitude: this.longituded,
      latitude: this.latituded,
      pincode: this.zip,
    }
    //this.categoryObj['address'] = this.address
    this.addressDiv = false
    this.planDiv = true
    this.cardDiv = true
    if (this.countryCode == 'US' || this.countryCode == 'CA') {
      this.paymentPay = true
      //this.processBtn = "Proceed to Pay"
    }
  }
  //Set google address
  public handleAddressChange(address: any) {
    let addDetails = this.extract(address);
    this.fullAddress = addDetails.full_address;
    this.countryNames = addDetails.detail.country.name;
    this.countryCode = addDetails.detail.country.short_name;
    this.stateName = addDetails.detail.state;
    this.cityName = addDetails.detail.city;
    this.latituded = addDetails.latlng.lat;
    this.longituded = addDetails.latlng.lng;
    this.street = addDetails.detail.street;
    this.zip = addDetails.detail.zip;
    this.locationName = addDetails.detail.location;
  }

  //Google address set small part
  public extract(receivedAddresses: any) {
    let address_components = receivedAddresses.address_components;
    let address = {
      full_address: receivedAddresses.formatted_address,
      latlng: {
        lat: receivedAddresses.geometry.location.lat(),
        lng: receivedAddresses.geometry.location.lng(),
      },
      detail: {
        location: '',
        street: '',
        city: '',
        state: '',
        department: '',
        country: {
          name: '',
          short_name: '',
        },
        zip: '',
      },
    };
    address_components.forEach((component: any) => {
      if (component.types.indexOf("street_number") > -1) {
        // set numero
        address.detail['location'] = component.long_name;
      }
      if (component.types.indexOf("route") > -1) {
        // set voie
        address.detail['street'] = component.long_name;
      }
      if (component.types.indexOf("locality") > -1) {
        // set ville
        address.detail['city'] = component.long_name;
      }
      if (component.types.indexOf("administrative_area_level_2") > -1) {
        // set departement
        address.detail['department'] = component.long_name;
      }
      if (component.types.indexOf("administrative_area_level_1") > -1) {
        // set region
        address.detail['state'] = component.long_name;
      }
      if (component.types.indexOf("country") > -1) {
        // set pays

        address.detail['country'] = {
          name: component.long_name,
          short_name: component.short_name,
        }
      }
      if (component.types.indexOf("postal_code") > -1) {
        // set code postal
        address.detail['zip'] = component.long_name;
      }
    });
    return address;
  }

  getNextSubscription() {
    this.pageLoader = true
    this.userService.getNextSubscription().subscribe((res: any) => {
      // this.nextSubscriptionData = res
      this.pageLoader = false
      if (Object.keys(res).length > 0) {
        this.nextSubscriptionData = res
        console.log(this.nextSubscriptionData);
      }else {
        console.log('No subscriptions found.');
        return
      }
    }, (err) => {
      this.pageLoader = false
    }
    );
  }


  pauseSubscription(): void {
    this.pageLoader = true
    this.userService.pauseSubscription().subscribe((res: any) => {
      this.pageLoader = false
      this.getNextSubscription();
    },
      (err) => {
        this.pageLoader = false
      }
    )
  }
  resumeSubscription(): void {
    this.pageLoader = true
    this.userService.resumeSubscription().subscribe((res: any) => {
      this.pageLoader = false
      this.getNextSubscription()
    },
      (err) => {
        this.pageLoader = false
      }
    )
  }
}
