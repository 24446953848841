import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { LoggedInGuard } from 'src/app/core/guards/logged-in.guard';
import { RegistrationGuard } from 'src/app/core/guards/registration.guard';
import { SubscriptionComponent } from './subscription.component';

const routes: Routes = [

  {
    path: 'subscription',
    data: { title: 'CrewResumes' },
    component: SubscriptionComponent,
    canActivate: [AuthGuard,RegistrationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionRoutingModule { }
