import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment'
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  profileImageUpdate$ = new Subject<string>();
  registrationStatus$ = new BehaviorSubject<any>(0);

  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http:HttpClient,
  ) { }
  //for forgot password.
  forgotPassword(data:any){
    return this.http.post(environment.apiUrl+'users/forgotpassword/initiate',data);
  }
  //for verify otp.
  verifyOtp(data:any){
    return this.http.post(environment.apiUrl+'users/otp/verify',data);
  }
  //complete forgot password.
  completePassword(data:any){
    return this.http.post(environment.apiUrl+`users/forgotpassword/complete`,JSON.stringify(data));
  }
  //send msg used in footer.
  sendNewsLetter(userData : any){
    return this.http.post(environment.apiUrl+'newsletters',userData);
  }
}
