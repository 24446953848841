import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path :'dashboard',
    data: { title: "CrewResumes" },
    component:DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path :'dashboard/:mediaType',
    data: { title: "CrewResumes" },
    component:DashboardComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
