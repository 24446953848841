import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router';

@Component({
  selector: 'app-non-login-header',
  templateUrl: './non-login-header.component.html',
  styleUrls: ['./non-login-header.component.scss']
})
export class NonLoginHeaderComponent implements OnInit {
  keyWord: any = ''
  searchKeyValue: any = ''
  cross: boolean = false
  rootUrl: any

  /**
  * @constructor
  * @param {Router} router - For getting router details.
  */
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.rootUrl = environment.rootUrl
  }

  // For navigation the path
  home() {
    window.location.href =
      environment.rootUrl
    //this.router.navigate(['/crew-profile'])
  }

  // For search 
  onSearch(ev: any) {
    this.searchKeyValue = ev.target.value
    if (this.searchKeyValue != '') {
      this.cross = true
      window.location.href = environment.rootUrl + `crew-list/?searchStr=`+ this.searchKeyValue;
    }
  }

  // For clear input field
  inputClear() {
    this.searchKeyValue = ''
    this.keyWord = ''
    this.cross = false
    // this.router.navigate(['crew-list']);
    window.location.href = environment.rootUrl + `crew-list/`
  }

  // For navigation the path
  goList() {
    window.location.href = environment.rootUrl + `crew-list/`
  }
}
