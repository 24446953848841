import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { MediaPlatform } from '../../shared/shared';
import { SubscriptionService } from './subscription.service'
import { ConfirmationService } from 'primeng/api';
import { CommonService } from '../common/common.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  categories: FormArray;
  categoryForm: FormGroup;
  selectedId: any;
  selectedRow: any;
  isAgree = false;
  cities: any = [];
  mediaPlatform: any = []
  departmentList: any = []
  subCategoryList: any = []
  signupObj: any = {}
  address: any = {}
  cardInfo: any = {}
  val: any;
  selectedCity: any;
  animal: number = 1;
  selectedValues: any
  tier1: number = 1;
  tier2: number = 1;
  hotel: number = 1;
  subscription: number = 1;
  payment: number = 1
  item: any;
  cardForm: FormGroup;
  addressFrom: FormGroup;
  submitted: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  countryNames: string = '';
  stateName: string = '';
  cityName: string = '';
  latituded: string = '';
  longituded: string = '';
  street: string = '';
  zip: string = '';
  locationName: string = '';
  countryCode: string = '';
  fullAddress: any;
  addressDiv = true
  planDiv = false
  cardDiv = false
  totalAmount: any 
  categorieBtn = false
  categorieBtnAdd = false
  paymentPay = false
  processBtn: string = 'Finish Signup'
  pageLoader = false
  categoriesNew:any = []
  monthlyAmount: any
  deleteBtn: any = true

  @ViewChild("placesRef") placesRef!: GooglePlaceDirective;

  /**
  * @constructor
  * @param {SubscriptionService} subservice - The subservice service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {Router} router - For getting router details.
  * @param {FormBuilder} formBuilder - FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.
  * @param {ConfirmationService} confirmationService - Confirmation services is required to display it by calling confirm method.
  */
  constructor(
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private subservice: SubscriptionService,
    private confirmationService: ConfirmationService,
    private common: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    // Get data for all fields
    this.subservice.getFiels().subscribe((data: any) => {
      this.mediaPlatform = data['mediaPlatform']
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })

    // Get all categories
    this.subservice.getCategory().subscribe((x: any) => {
      this.departmentList = x
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    });

    // Get all subscription plan
    this.subservice.getSubscriptionPlan().subscribe((x: any) => {
      this.monthlyAmount = x.amount
      this.totalAmount = x.amount
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    });

    // Form array declaration
    this.categoryForm = new FormGroup({
      categories: this.formBuilder.array([]),
      plan: new FormControl(''),

    })
    this.categories = this.categoryForm.get('categories') as FormArray;

    // Form declaration
    this.addressFrom = this.formBuilder.group({
      address: new FormControl('', Validators.required),
    });

     // Form declaration
    this.cardForm = this.formBuilder.group({
      cardNumber: new FormControl('', Validators.required),
      expiryDate: new FormControl('', Validators.required),
      cvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
      cardHolderName: new FormControl('', Validators.required),
    });

    this.addCategories()
  }

  // For form controls
  get f() { return this.categoryForm.controls; }
  get g() { return this.cardForm.controls; }
  get h() { return this.addressFrom.controls; }


  ngOnInit(): void {
  }

  // Function on click at agree 
  agree(ev: any) {
    this.isAgree = ev.checked.length > 0 ? true : false
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
    //this.placesRef.options.componentRestrictions = { country: 'USA' }
    this.placesRef.options.fields = ["formatted_address", "geometry", "place_id", "address_components"]
  }
 
   /**
   * Select subcategory from dropdown list
   * @method departmentSelect
   * @returns { Object } Object
   */
  departmentSelect(ev: any, i: any) {
    this.pageLoader = true
    //this.cat.controls[i].get('subcategories')?.reset()
    this.subservice.getSubCategoryList(ev.value).subscribe((data: any) => {
      this.pageLoader = false
      this.subCategoryList[i] = data
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  get cat() {
    return (<FormArray>this.categoryForm.get('categories'));
  }

  // Form control with array
  getCategoriesControls() {
    return (this.categoryForm.get('categories') as FormArray).controls;
  }

  // Add new category
  addCategories(): void {
    this.categories = this.categoryForm.get('categories') as FormArray;
    this.categoriesNew = []
    this.categories.push(this.createCategory());
    this.totalAmount = this.totalAmount + 1
    this.deleteBtn = true
    this.categories.controls.forEach(element => {
      if(element.value.catStatus == 1){
        this.categoriesNew.push(element)
        if(this.categoriesNew.length >= 5){
            this.categorieBtnAdd = true
          }
      }
    })
  }

  // Create form object
  createCategory(): FormGroup {
    this.submitted = false;
    return this.formBuilder.group({
      mediaPlatform: new FormControl('', Validators.required),
      categoryId: new FormControl('', Validators.required),
      subcategories: new FormControl('', Validators.required),
      catStatus: new FormControl(1),
    });
  }
  
  //For remove the departments on click delete row
  removeItem(index: any, item: any) {
    let categoriesNew: any = []
    this.confirmationService.confirm({
      message: 'Do yo really wish to delete?',
      accept: () => {
        this.selectedRow = index;
        //this.cat.controls[index].get('subcategories')?.value
        //this.categories.removeAt(this.selectedRow);
        this.categories.controls[index].get('catStatus')?.setValue(0)
        this.categories.controls[index].get('mediaPlatform')?.clearValidators();
        this.categories.controls[index].get('categoryId')?.clearValidators();
        this.categories.controls[index].get('subcategories')?.clearValidators();
        this.categories.controls[index].get('mediaPlatform')?.updateValueAndValidity();
        this.categories.controls[index].get('categoryId')?.updateValueAndValidity();
        this.categories.controls[index].get('subcategories')?.updateValueAndValidity();        
        this.totalAmount = this.totalAmount - 1
        this.categorieBtnAdd = false
        this.categories.controls.filter(element => {
          if(element.value.catStatus == 1){
            categoriesNew.push(element)
          }
        })
        if(categoriesNew.length == 1){
          this.deleteBtn = false
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record deleted Successfully' });
      }, reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No changes made' });
      }
    });
  }

  // Cancel for category div
  cancel() {
    this.categoriesNew = []
    this.cardDiv = false
    this.categorieBtn = false
    this.categorieBtnAdd = false
    this.isAgree = false
    this.categoryForm.enable()
    this.categories.controls.forEach(element => {
      if(element.value.catStatus == 1){
        this.categoriesNew.push(element)
        if(this.categoriesNew.length >= 5){
            this.categorieBtnAdd = true
          }
      }
    })
  }

  // For edit the address
  editAddress() {
    this.paymentPay = false
    this.addressDiv = true
    this.planDiv = false
    this.cardDiv = false
    this.addressFrom.controls['address'].setValue(this.fullAddress)
  }
 
  /**
   * For proceed to pay by click on function
   * @method proceedToPay
   * @returns { Object } Object
   */
  proceedToPay() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields ' });
      return;
    }
    let usercategories: any[] = []
    this.categoryForm.value.categories.map((item: any, index: any) => {
      if (item.catStatus === 1) {
        const tempRows: any = {};
        tempRows['mediaPlatform'] = item.mediaPlatform;
        tempRows['categoryId'] = item.categoryId;
        tempRows['subcategories'] = item.subcategories;
        usercategories.push(tempRows);
      }
    })
    this.signupObj['usercategories'] = usercategories
    // console.log(this.signupObj)
    // return
    if (this.countryCode == 'US' || this.countryCode == 'CA') {
      this.cardDiv = true
      this.categorieBtn = true
      this.categorieBtnAdd = true
      this.categoryForm.disable()
    } else {
      this.pageLoader = true
      this.subservice.signUpStep2(this.signupObj).subscribe((x: any) => {
        this.pageLoader = false
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Signup successful' });
        this.common.registrationStatus$.next(1)
        sessionStorage.setItem("registrationStatus", JSON.stringify(1))
        this.router.navigateByUrl('/home')
      }, (error: any) => {
        this.pageLoader = false
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
      }
      );
    }
  }

  /**
   * By click on pay now move to payments for details.
   * @method payNow
   * @returns { Object } Object
   */
  payNow() {
    this.submitted2 = true;
    if (this.cardForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields ' });
      return;
    }
    let cardNo = this.cardForm.value.cardNumber.replace(/-/g, "").toString();
    this.cardInfo = {
      cardHolderName: this.cardForm.value.cardHolderName,
      cardNo: cardNo,
      expMonth: this.cardForm.value.expiryDate.substring(0, 2),
      expYear: this.cardForm.value.expiryDate.substring(3, 5),
      cvv: this.cardForm.value.cvv
    }
    this.signupObj['cardDetails'] = this.cardInfo
    this.signupObj['amount'] = this.totalAmount
    // console.log(this.signupObj)
    // return
    this.isAgree = false
    this.pageLoader = true
    this.subservice.signUpStep2(this.signupObj).subscribe((x: any) => {
      this.isAgree = true
      this.pageLoader = false
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Signup successful' });
      this.common.registrationStatus$.next(1)
      sessionStorage.setItem("registrationStatus", JSON.stringify(1))
      this.router.navigateByUrl('/home')
    }, (error: any) => {
      this.isAgree = true
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    }
    );
  }

  // For getting the address 
  getAddress() {
    this.submitted3 = true;
    if (this.addressFrom.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields.' });
      return;
    }
    if (this.fullAddress == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the address from the address list before proceeding.' });
      return;
    }
    this.address = {
      fullAddress: this.fullAddress,
      country: this.countryNames,
      city: this.cityName,
      state: this.stateName,
      longitude: this.longituded,
      latitude: this.latituded,
      pincode: this.zip,
      countryCode: this.countryCode
    }
    console.log("Address Object >>>>>", this.address)
    this.signupObj['address'] = this.address
    this.addressDiv = false
    this.planDiv = true
    if (this.countryCode == 'US' || this.countryCode == 'CA') {
      this.paymentPay = true
      this.processBtn = "Proceed to Pay"
    }else{
      this.processBtn = "Finish Signup"
    }
  }
  
  //Set google address
  public handleAddressChange(address: any) {
    let addDetails = this.extract(address);
    this.fullAddress = addDetails.full_address;
    this.countryNames = addDetails.detail.country.name;
    this.countryCode = addDetails.detail.country.short_name;
    this.stateName = addDetails.detail.state;
    this.cityName = addDetails.detail.city;
    this.latituded = addDetails.latlng.lat;
    this.longituded = addDetails.latlng.lng;
    this.street = addDetails.detail.street;
    this.zip = addDetails.detail.zip;
    this.locationName = addDetails.detail.location;
    console.log("Google api address >>>>>", addDetails)
  }

  //Google address set small part
  public extract(receivedAddresses: any) {
    let address_components = receivedAddresses.address_components;
    let address = {
      full_address: receivedAddresses.formatted_address,
      latlng: {
        lat: receivedAddresses.geometry.location.lat(),
        lng: receivedAddresses.geometry.location.lng(),
      },
      detail: {
        location: '',
        street: '',
        city: '',
        state: '',
        department: '',
        country: {
          name: '',
          short_name: '',
        },
        zip: '',
      },
    };
    address_components.forEach((component: any) => {
      if (component.types.indexOf("street_number") > -1) {
        // set numero
        address.detail['location'] = component.long_name;
      }
      if (component.types.indexOf("route") > -1) {
        // set voie
        address.detail['street'] = component.long_name;
      }
      if (component.types.indexOf("locality") > -1) {
        // set ville
        address.detail['city'] = component.long_name;
      }
      if (component.types.indexOf("administrative_area_level_2") > -1) {
        // set departement
        address.detail['department'] = component.long_name;
      }
      if (component.types.indexOf("administrative_area_level_1") > -1) {
        // set region
        address.detail['state'] = component.long_name;
      }
      if (component.types.indexOf("country") > -1) {
        // set pays

        address.detail['country'] = {
          name: component.long_name,
          short_name: component.short_name,
        }
      }
      if (component.types.indexOf("postal_code") > -1) {
        // set code postal
        address.detail['zip'] = component.long_name;
      }
    });
    return address;
  }
}
