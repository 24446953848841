<div ly-dialog-content [lyPx]="0">

  <div *ngIf="ready">
    <div [className]="classes.flex">
      <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
      <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
      <button (click)="cropper.center()" ly-button appearance="icon"><ly-icon>filter_center_focus</ly-icon></button>
      <button (click)="cropper.rotate(-90)" ly-button
        appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
      <!-- </div>
      <div> -->
      <button (click)="cropper.fit()" ly-button>Fit</button>
      <!-- <button (click)="cropper.fitToScreen()" ly-button>Fit to screen</button> -->
      <!-- <button (click)="cropper.setScale(1)" ly-button>1:1</button> -->
      <button (click)="cropper.clean()" ly-button>Clean</button>
    </div>
  </div>


  <ly-img-cropper [config]="myConfig" [(scale)]="scale" (ready)="ready = true" (cleaned)="ready = false"
    (minScale)="minScale = $event" (cropped)="onCropped($event)" (loaded)="onLoaded($event)" (error)="onError($event)">
    <!-- <span>Drag and drop image</span> -->
    <div class="custom-design" *ngIf="!ready" style="cursor: pointer;">
      <button ly-button color="primary" (click)="_fileInput.click()" style="cursor: pointer;">
        <ly-icon>image</ly-icon>
        <span class="margin-left-text">Select File</span>

      </button>
      <!-- <span>Drag and drop image</span>  -->
      <!-- <span [lyFlex]="1"></span> -->
      <!-- Hidden input -->
      <input #_fileInput type="file" (change)="cropper.selectInputEvent($event)" accept="image/*" hidden>

   
          <span class="custom-text">or</span>
        <div class="text-style">
          Drag and drop image
        </div>
     
    </div>
  </ly-img-cropper>

  <div [className]="classes.sliderContainer" *ngIf="ready">
    <div [class]="classes.slider">
      <ly-slider [thumbVisible]="false" [min]="minScale" [max]="1" [(ngModel)]="scale" (input)="onSliderInput($event)"
        [step]="0.000001" cdkFocusInitial></ly-slider>
    </div>
  </div>

</div>

<div container [lyJustifyContent]="'flex-end'" ly-dialog-actions *ngIf="!ready">
  <div>
    <button ly-button raised bg="primary" [lyMf]="1" (click)="dialogRef.close()">CLOSE</button>
  </div>
</div>
<div container [lyJustifyContent]="'flex-end'" ly-dialog-actions *ngIf="ready">
  <div>
    <button ly-button raised class="gray-button" [lyMf]="1" (click)="dialogRef.close()">CANCEL</button>
    <!-- <button ly-button color="primary" (click)="dialogRef.close(cropper.crop())">Crop</button> -->
  </div>
  <div>
    <button ly-button raised bg="primary" (click)="dialogRef.close(cropper.crop())" ly-button>
      <!-- <ly-icon>crop</ly-icon>crop -->
    CROP & UPLOAD
    </button>
  </div>
</div>

