import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment'
import { AuthGuard } from 'src/app/core/guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http:HttpClient
  ) { }
  //For category data
  getCategory(){
    return this.http.get(environment.apiUrl+'categories/active');
  }
  //For SubCategoryList
  getSubCategoryList(id:any){
    return this.http.get(environment.apiUrl+`categories/active?parentId=${id}`);
  }
  //For signUpStep2
  signUpStep2(userData:any){
    return this.http.post(environment.apiUrl+'users/registration/step/2',userData);
  }
  //For user info
  userMe(){
    return this.http.get(environment.apiUrl+'crews/me');
  }
  //For input fields data value.
  getFiels(){
    return this.http.get(environment.apiUrl+'fields');
  }
  //For Subscription Plan 
  getSubscriptionPlan(){
    return this.http.get(environment.apiUrl+`plans/${1}`);
  }
}
