import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './container/default-layout/default-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';

import { commonModule } from './modules/common/common.module';
import { CoreModule } from './core/core.module';
import { MultiSelectModule } from 'primeng/multiselect';

import { SigninModule } from './modules/signin/signin.module';
import { SharedModule } from './shared/shared.module';
import { SignupModule } from './modules/signup/signup.module';
import { SignupRoutingModule } from './modules/signup/signup-routing.module';
import { SigninRoutingModule } from './modules/signin/signin-routing.module';
import { CommonRoutingModule } from './modules/common/common-routing.module';
import { SubscriptionModule } from './modules/subscription/subscription.module';
import { SubscriptionRoutingModule } from './modules/subscription/subscription-routing.module';
import { UserModule } from './modules/user/user.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CrewResumesInterceptor } from './core/services/crew-resumes.interceptor';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DashboardModule } from './modules/dashboard/dashboard.module';

import { YouTubePlayerModule } from "@angular/youtube-player";

import { LY_THEME, LY_THEME_NAME, LyCommonModule, LyHammerGestureConfig, LyTheme2, LyThemeModule, StyleRenderer } from '@alyle/ui';
import { MinimaLight, MinimaDark, MinimaDeepDark } from '@alyle/ui/themes/minima';
@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent
  ],
  imports: [
    YouTubePlayerModule,
    DashboardModule,
    UserModule,
    SubscriptionRoutingModule,
    CommonRoutingModule,
    SigninRoutingModule,
    SignupRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    SignupModule,
    SubscriptionModule,
    SharedModule,
    MultiSelectModule,
    HttpClientModule,
    CoreModule,
    StyleClassModule,
    InputTextModule,
    commonModule,
    SigninModule,

    LyThemeModule.setTheme('minima-light'),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrewResumesInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    [ LyTheme2 ],
    [ StyleRenderer ],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: LY_THEME, useClass: MinimaDeepDark, multi: true },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
