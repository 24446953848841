import { Component } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'crewResumes';
  isUserLoggedIn:any = false;

  constructor(private auth:AuthGuard) {
    this.auth.isUsersLoggedIn.subscribe(x=>{
      this.isUserLoggedIn = x;
    })
  }

}
