import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment'
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {Observable} from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  renewState$ = new Subject<any>();

  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http:HttpClient
  ) { }
  //for get fields input value
  getFiels(){
    return this.http.get(environment.apiUrl+'fields');
  }
  //for addPersonalInfo
  addPersonalInfo(userData:any){
    return this.http.post(environment.apiUrl+'crews/personalinfo',userData);
  }
  //for addContactInfo
  addContactInfo(userData:any){
    return this.http.post(environment.apiUrl+'crews/contactinfo',userData);
  } 
  //for addProfessionalInfo
  addProfessionalInfo(userData:any){
    return this.http.post(environment.apiUrl+'crews/professionalinfo',userData);
  }
  //for addSkillInfo
  addSkillInfo(userData:any){
    return this.http.post(environment.apiUrl+'crews/skillandtrainings',userData);
  }
  //for addAttributesInfo
  addAttributesInfo(userData:any){
    return this.http.post(environment.apiUrl+'crews/attributes',userData);
  }
  //for addEquipmentInfo
  addEquipmentInfo(userData:any){
    return this.http.post(environment.apiUrl+'crews/equipments',userData);
  }
  //for getSkilledCheckValue
  getSkilledCheckValue(userData:any){
    return this.http.get(environment.apiUrl+`crews/meta/${userData}`);
  }
  //for saveSkilledCheckValue
  saveSkilledCheckValue(userData:any){
    return this.http.post(environment.apiUrl+`crews/meta`,userData);
  }
  //for get User Data
  getUserData(){
    return this.http.get(environment.apiUrl+`crews/me`);
  }
  //for delete Resume
  deleteResume(id:any){
    return this.http.delete(environment.apiUrl+`users/resume/${id}`);
  }
  // change password details
  changePassword(obj:any){
    return this.http.patch(environment.apiUrl+'users/change-password',JSON.stringify(obj));
  }
  //media photo details
  addMediaPhotoInfo(userData:any){
    return this.http.post(environment.apiUrl+'usermedias',userData);
  }
  //media video details
  addMediaVideoInfo(userData:any){
    return this.http.post(environment.apiUrl+'usermedias',userData);
  }
   //media video details
   addMediaVoiceInfo(userData:any){
    return this.http.post(environment.apiUrl+'usermedias',userData);
  }
  // get media type
  getMediaData(){
    return this.http.get(environment.apiUrl+`usermedias`);
  }
  //get media type for id 
  getMediaId(id:any){
    return this.http.get(environment.apiUrl+`usermedias/${id}`);
  }
  //update media
  updateMediaData(id:any,obj:any){
    return this.http.patch(environment.apiUrl+`usermedias/${id}`,JSON.stringify(obj));
  }
  //delete media photo video audio
  deleteMediaData(id:any){
    return this.http.delete(environment.apiUrl+`usermedias/${id}`);
  }
  // get profile data
  getProfileDetails(){
    return this.http.get(environment.apiUrl+`crews`);
  }
  // get profile data by id
  getProfileDetailsId(){
    return this.http.get(environment.apiUrl+`crews/profile`);
  }
  //for get CardDetails
  getCardDetails(){
    return this.http.get(environment.apiUrl+`paymentmethods`);
  }
  //for update CardDetails
  updateCardDetails(obj:any){
    return this.http.post(environment.apiUrl+`paymentmethods`,JSON.stringify(obj));
  }
  //for  get Category
  getCategory(){
    return this.http.get(environment.apiUrl+'categories/active');
  }
  //for SubCategory List
  getSubCategoryList(id:any){
    return this.http.get(environment.apiUrl+`categories/active?parentId=${id}`);
  }
  //for deleteUser Category
  deleteUserCategory(id:any){
    return this.http.delete(environment.apiUrl+`crews/categories/${id}`);
  }
  //for updateUser Category
  updateUserCategory(data: any){
    return this.http.patch(environment.apiUrl+`crews/categories`,data);
  }
  //for SubCategory List With Promice
  async getSubCategoryListWithPromice(id:any): Promise<Observable<any>> {
    return this.http.get(environment.apiUrl+`categories/active?parentId=${id}`);
  }

  //featured image / video / audio
  featuerdMediaData(id:any,mediaType: any){
    return this.http.patch(environment.apiUrl+`usermedias/${id}/${mediaType}/featured`,{});
  }
  checkUserName(data : any){
    return this.http.post(environment.apiUrl+'users/check-username-availability',JSON.stringify(data));
  }
  // for transactions history
  transactionHistory(){
    return this.http.get(environment.apiUrl+`transactions`);
  }
  //for Subscription Plan
  getSubscriptionPlan(){
    return this.http.get(environment.apiUrl+`plans/${1}`);
  }
  //for renewCard
  renewCard(data: any){
    return this.http.post(environment.apiUrl+'subscriptions/renew',data);
  }
  getNextSubscription(){
    return this.http.get(environment.apiUrl+'subscriptions/next');
  }
  pauseSubscription(){
    return this.http.patch(environment.apiUrl+'subscriptions/pause',{});
  }
  resumeSubscription(){
    return this.http.patch(environment.apiUrl+'subscriptions/resume',{});
  }
}
