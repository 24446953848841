import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Route, ParamMap } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard'
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { DashboardService } from './dashboard.service';
import { UserService } from '../user/user.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  items: any = [];
  selectedItem: any = "edit"
  edit: boolean = true
  mediaType: any;
  profileId: any;
  userData: any = {}
  paymentSubscription: any
  show: boolean = false;

  /**
  * @constructor
  * @param {DashboardService} dService - The dashboardService service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {ConfirmationService} confirmationService - Confirmation services is required to display it by calling confirm method.
  * @param {ActivatedRoute} route - For getting router details.
  * @param {AuthGuard} auth - AuthGuard is used to protect the routes from unauthorized access in angular.
  * @param {UserService} userService - The userService service for call API.
  */
  constructor(
    private auth: AuthGuard,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dService: DashboardService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // For side bar menue in dashboard 
    this.items = [
      { label: 'Edit Profile', page: 'edit', disable: 1 },
      { label: 'Media', page: 'media', disable: 1 },
      { label: 'Edit Subscription', page: 'subscription', disable: 1 },
      { label: 'Transactions History', page: 'crewTransactionHistory', disable: 1 },
      { label: 'Payment Method', page: 'PaymentMethod', disable: 1 },
      { label: 'Change Password', page: 'changePassword', disable: 1 },
    ]
    this.getInfo()
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.mediaType = paramMap.get("mediaType");
      if (this.mediaType == 0 || this.mediaType == 1 || this.mediaType == 2) {
        this.selectedItem = this.items[1]
      } else if (this.mediaType == 3) {
        this.selectedItem = this.items[2]
      } else {
        if (sessionStorage['selectedItem']) {
          let item = JSON.parse(sessionStorage['selectedItem']);
          this.selectedItem = item
        } else {
          this.selectedItem = this.items[0]
        }
      }
    });

  }
  /**
 * Get information of logged in user
 * @method getInfo
 * @returns { Object } Object
 */
  getInfo() {
    this.dService.getUserData().subscribe((x: any) => {
      this.userData = x
      if (x.subscribed == 0 && (x.address.countryCode == 'US' || x.address.countryCode == 'CA')) {
        this.userService.renewState$.subscribe((data: any) => this.items[0].disable = data);
        this.userService.renewState$.subscribe((data: any) => this.items[1].disable = data);
        this.userService.renewState$.subscribe((data: any) => this.paymentSubscription = data);
      }
    })
  }


  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  // For select the sidebar menue 
  listClick(event: any, newValue: any) {
    this.selectedItem = newValue
    sessionStorage.setItem('selectedItem', JSON.stringify(this.selectedItem))
  }

  // For logout the application 
  logout() {
    this.auth.signOut()
    setTimeout(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Logout successfully'
      });
    }, 1000)
  }

  /**
 * Delete data 
 * @method delete
 * @returns { Object } Object
 */
  delete() {
    this.confirmationService.confirm({
      message: 'Do you really wish to delete?',
      accept: async () => {
        this.dService.deleteProfile().subscribe(
          async (r: any) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Record deleted successfully'
            });
            this.auth.signOut()
          }, (error: any) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error.error.message
            });
          })
      }, reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: 'No changes made'
        });
      }
    })
  }
}
