<!-- pageLoader -->
<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>

<div class="grid" *ngIf="cardAddDiv">
    <div class="col-12 lg:col-6">
        <div class="surface-section p-4 surface-card shadow-2 border-round-sm" >
            <div class="formgrid p-fluid">
                <div class="field mb-4">
                    <label for="CardEndingIn" class="">Add Your Card (optional)</label><br>
                </div>
            </div>
            <div class="">
                <button pButton pRipple label="{{btnName}}" (click)="editCard()"
                    class="p-button-primary ml-auto"></button>
            </div>
        </div>
    </div>
</div>

<div class="grid" *ngIf="cardEditDiv">
    <div class="col-12 lg:col-6">
        <div class="surface-section p-4 surface-card shadow-2 border-round-sm" >
            <div class="formgrid p-fluid">
                <div class="field mb-4">
                    <label for="cardHolderName" class="">Card Holder Name</label><br>
                    <label>{{cardData.cardHolderName}}</label>
                </div>
            </div>
            <div class="formgrid p-fluid">
                <div class="field mb-4">
                    <label for="CardEndingIn" class="">Card Ending In</label><br>
                    <label>XXXX-XXXX-XXXX-{{cardNumber}}</label>
                </div>
            </div>
            <div class="">
                <button pButton pRipple label="{{btnName}}" (click)="editCard()"
                    class="p-button-primary ml-auto"></button>
            </div>
        </div>
    </div>
</div>
<div class="grid" *ngIf="cardDiv">
    <div class="col-12 lg:col-6">
        <div class="surface-section p-4 surface-card shadow-2 border-round-sm">
            <!-- [formGroup]="cardForm" used -->
            <form [formGroup]="cardForm">
                <div class="formgrid p-fluid">
                    <div class="field mb-4">
                        <label for="Address" class="">Card Number</label>
                        <p-inputMask mask="9999-9999-9999-9999" placeholder="1234-1234-1234-1234"
                            formControlName="cardNumber" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedCardForm && g['cardNumber'].errors }"></p-inputMask>
                        <div *ngIf="submittedCardForm && g['cardNumber'].errors" class="invalid-feedback">
                            <small *ngIf="g['cardNumber'].errors['required']" class="p-error block mb-2">Card Number
                                is required</small>
                        </div>
                    </div>
                </div>
                <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-6">
                        <label for="expiryDate" class="">Expiration Date</label>
                        <p-inputMask mask="99/99" placeholder="MM/YY" formControlName="expiryDate" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedCardForm && g['expiryDate'].errors }"></p-inputMask>
                        <div *ngIf="submittedCardForm && g['expiryDate'].errors" class="invalid-feedback">
                            <small *ngIf="g['expiryDate'].errors['required']" class="p-error block mb-2">Expiry Date
                                is required</small>
                        </div>
                    </div>
                    <div class="field mb-4 col-6">
                        <label for="cvv" class="">CVV</label>

                        <input id="cvv" placeholder="Enter CVV" pInputText formControlName="cvv" type="password"
                            pattern="\d*" maxlength="4" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedCardForm && g['cvv'].errors }">
                        <div *ngIf="submittedCardForm && g['cvv'].errors" class="invalid-feedback">
                            <small *ngIf="g['cvv'].errors['required']" class="p-error block mb-2">CVV is
                                required</small>
                        </div>
                    </div>
                </div>
                <div class="formgrid p-fluid">
                    <div class="field mb-4">
                        <label for="cardHolderName" class="">Cardholder Name</label>
                        <input id="cardHolderName" type="text" placeholder="Enter Cardholder Name"
                            formControlName="cardHolderName" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedCardForm && g['cardHolderName'].errors }" pInputText>
                        <div *ngIf="submittedCardForm && g['cardHolderName'].errors" class="invalid-feedback">
                            <small *ngIf="g['cardHolderName'].errors['required']" class="p-error block mb-2">Cardholder
                                Name is required</small>
                        </div>
                    </div>
                </div>
                <!-- save and cancel on click -->
                <div class="flex">
                    <button pButton pRipple label="Cancel" (click)="cancel()" class="p-button-secondary p-button-outlined"></button>
                    <button pButton pRipple label="Update" (click)="updateCard()"
                        class="p-button-primary ml-auto"></button>
                </div>
            </form>
        </div>
    </div>
</div>