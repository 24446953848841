import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CommonService } from '../common/common.service';
import { UserService } from '../user/user.service';
import { SigninService } from './signin.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  features: any[] = [];
  logForm: FormGroup;
  submitted1: boolean = false;
  isdisabled: boolean = false;
  display5: boolean = false;
  showPassword = false;
  password: string = 'password';
  /**
  * @constructor
  * @param {SigninService} signinservice - The signinservice service for call API.
  * @param {userService} userService - The userService service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {Router} router - For getting router details.
  * @param {AuthGuard} auth - AuthGuard is used to protect the routes from unauthorized access in angular.
  */
  constructor(
    private messageService: MessageService,
    private router: Router,
    private signinservice: SigninService,
    private auth: AuthGuard,
    private userService: UserService,
    private common: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // Form declaration
    this.logForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    })
  }

  // For form controls
  get f() { return this.logForm.controls; }
  ngOnInit() {
    // this.features = [
    //     { title: 'Please provide important text', image: 'live-collaboration.svg', text: 'Please provide content' },
    //     { title: 'Please provide important text', image: 'security.svg', text: 'Please provide content' },
    //     { title: 'Please provide important text', image: 'subscribe.svg', text: 'Please provide content' }
    // ];
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  /**
   * For sign in the page
   * @method signIn
   * @returns { Object } Object
   */
  signIn() {
    this.submitted1 = true;
    if (this.logForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return;
    }
    this.isdisabled = true
    this.signinservice.userLogin(this.logForm.value).subscribe((x: any) => {
      if (x.token != null) {
        this.isdisabled = false
        this.auth.saveUser(x)
        this.auth.setLoggedIn()
        sessionStorage.setItem("registrationStatus", JSON.stringify(1))
        this.common.registrationStatus$.next(1)
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Login successful'
        });
        this.getInfo();
      }
    },
      (error: any) => {
        console.log(error)
        this.isdisabled = false
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error?.error?.error?.message
        });
      }
    );
  }

  /**
   * Get information of logged in user
   * @method getInfo
   * @returns { Object } Object
   */
  getInfo() {
    this.signinservice.userMe().subscribe((x: any) => {
      if (x) {
        sessionStorage.setItem('currentUser', JSON.stringify(x));
        if (x.subscribed == 0 && (x.address.countryCode == 'US' || x.address.countryCode == 'CA')) {
          this.userService.renewState$.next(x.subscribed);
          this.router.navigate(['/dashboard', 3])
        } else {
          this.router.navigateByUrl('/dashboard')
        }
      }
    })
  }

  //For dialog box
  showDialog4() {
    this.display5 = true;
  }

  /**
   * Toggles the visibility of the password input field.
   * @param {number} id - The id of the input field to toggle.
   */
  onClick(id: number) {
    if (id == 1) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
  }
}
