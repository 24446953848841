import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { CrewProfileComponent } from './crew/crew-profile/crew-profile.component';
import { CrewEditProfileComponent } from './crew/crew-edit-profile/crew-edit-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CrewChangePasswordComponent } from './crew/crew-change-password/crew-change-password.component';
import { CrewMediaComponent } from './crew/crew-media/crew-media.component';
import { CrewEditSubscriptionComponent } from './crew/crew-edit-subscription/crew-edit-subscription.component';
import { CrewPaymentMethodComponent } from './crew/crew-payment-method/crew-payment-method.component';
import { CrewTransactionHistoryComponent } from './crew/crew-transaction-history/crew-transaction-history.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CrewProfileImageCropperModalComponent } from './crew/crew-profile-image-cropper-modal/crew-profile-image-cropper-modal.component';

@NgModule({
  declarations: [
    CrewProfileComponent,
    CrewEditProfileComponent,
    CrewChangePasswordComponent,
    CrewMediaComponent,
    CrewEditSubscriptionComponent,
    CrewPaymentMethodComponent,
    CrewTransactionHistoryComponent,
    CrewProfileImageCropperModalComponent
  ],
  imports: [
   YouTubePlayerModule,
    CommonModule,
    UserRoutingModule,
    SharedModule
  ],
  exports:[
    CrewEditProfileComponent,
    CrewChangePasswordComponent,
    CrewMediaComponent,
    CrewPaymentMethodComponent,
    CrewEditSubscriptionComponent,
    CrewTransactionHistoryComponent
  ]
})
export class UserModule { }
