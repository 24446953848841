<div class="surface-ground px-4 py-7 md:px-6 lg:px-8">
    <div class="flex flex-wrap shadow-2 container">
        <div class="w-full md:w-6 bg-blue-50">
            <!-- <img src="assets/images/bastion-700.svg" alt="Image" height="35" class="mb-6"> -->
            <!-- <p-carousel [value]="features">
                <ng-template let-feature pTemplate="item">
                    <div class="text-center mb-8">
                        <img src="assets/logo/{{feature.logo}}" alt="Image" class="mb-6 w-6">
                        <div class="mx-auto  text-xl mb-4 text-blue-900">{{feature.title}}</div>
                       
                    </div>
                </ng-template>
            </p-carousel> -->
            <div class="text-center relative h-full">
                <img src="assets/images/login.jpg" alt="Login your Crew Resume account" class="signin-banner">
                <div class="mx-auto banner-text p-3">
                    <div class="text-2-line">Crew Resumes cast and crew profile page is developed by professionals with years of experience hiring talent. 
                    The webpage is designed to showcase your work on all media platforms, departments and job titles.
                    Please answer all questions that apply.
                    This will make your webpage complete for the producer to view your work history but most importantly contact you right now. 
                    Add your WhatsApp phone number for international productions. Then share your fresh updated webpage! 
                    Don't get overlooked due to lack of information available! Create your best profile page now!</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog4()"></button>
                    </div>
                </div>
                    
            </div>
        </div>
        <div class="w-full md:w-6 p-4 lg:p-7 surface-card">
            <div class="flex align-items-center justify-content-between mb-7">
                <span class="text-2xl text-900">Log In</span>
                <a tabindex="0" class="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" style="text-decoration: none;"  routerLink="/signup">Sign Up</a>
            </div>
            <!-- logForm formGroup used -->
            <form [formGroup]="logForm">
                <div class="mb-2">
                    <label for="email" class="block text-900 mb-2">Email</label>
                    <input id="email" type="text" pInputText class="w-full" placeholder="Enter Email"
                    formControlName="email" autocomplete="off"
                    [ngClass]="{ 'ng-dirty': submitted1 && f['email'].errors }" pInputText>
                    <div *ngIf="submitted1 && f['email'].errors" class="invalid-feedback">
                        <small *ngIf="f['email'].errors['required']" class="p-error block">Email is required</small>
                    
                    </div>
                </div>
                <div class="mb-2">
                    <label for="password4" class="block text-900 mb-2">Password</label>
                    <input id="password4" [type]="password" pInputText class="w-full" 
                    formControlName="password" placeholder="Enter Password" autocomplete="off" 
                    [ngClass]="{ 'ng-dirty': submitted1 && f['password'].errors } " pInputText>
                    <span class="eye-tpoggle" (click)="onClick(1)">
                        <i class="pi pi-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                        <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
                    </span>
                    <div *ngIf="submitted1 && f['password'].errors" class="invalid-feedback">
                        <small *ngIf="f['password'].errors['required']" class="p-error block">Password is required</small>
                    
                    </div>
                </div>
                
                <div class="flex align-items-center justify-content-between mb-4">
                    <!-- <div class="flex align-items-center">
                        <p-checkbox id="rememberme4" [binary]="true" styleClass="mr-2"></p-checkbox>
                        <label for="rememberme4">Remember me</label>
                    </div> -->
                    <a class="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" style="text-decoration: none;" routerLink="/forgot-password">Forgot password?</a>
                </div>
                <!-- On clik signIn to login  -->
                <button pButton pRipple label="Sign In" [disabled]="isdisabled" (click)="signIn()" class="w-full mb-0 md:mb-5"></button>
                <br class="hidden md:block">
                <br class="hidden md:block">
                <br class="hidden md:block">
                <br class="hidden md:block">
            </form>
        </div>
    </div>
</div>
<!-- read more dialog box -->
<p-dialog header="Welcome to Crew Resumes" [(visible)]="display5" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
   
    <div>
       <p>Crew Resumes cast and crew profile page is developed by professionals with years of experience hiring talent. 
        The webpage is designed to showcase your work on all media platforms, departments and job titles.
        Please answer all questions that apply.
        This will make your webpage complete for the producer to view your work history but most importantly contact you right now. 
        Add your WhatsApp phone number for international productions. Then share your fresh updated webpage! 
        Don't get overlooked due to lack of information available! Create your best profile page now!</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display5=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- toast msg alert -->
<p-toast position="top-right"></p-toast>