<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="surface-card p-4 shadow-2 border-round-sm">
    <!-- [formGroup]="changePasswordForm" used -->
    <form [formGroup]="changePasswordForm">
        <div class="grid formgrid p-fluid">
            <div class="col-12 lg:col-6">  
        <div class="form-group mb-4">
            <label for="currentPassword" class="block text-900  mb-2">Old Password</label>
            <div class="col-sm ">
              <input [type]="oldPassword" formControlName="currentPassword" class="form-control" 
              placeholder="Enter Old Password" autocomplete="off"
              [ngClass]="{ 'ng-dirty': submitted1 && f['currentPassword'].errors }" pInputText/>
              <span class="eye-tpoggle" (click)="onClick(1)">
                <i class="pi pi-eye" aria-hidden="true" *ngIf="!showOldPassword"></i>
                <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showOldPassword"></i>
            </span>
              <div *ngIf="submitted1 && f['currentPassword'].errors" class="invalid-feedback">
                  <small *ngIf="f['currentPassword'].errors['required']" class="p-error block">Old Password is required</small>
                  <small *ngIf="f['currentPassword'].errors['minlength']" class="p-error block">Old Password must be at least 8 characters</small>
              </div>
            </div>
        </div> 
        <div class="form-group mb-4">
            <label for="password" class="block text-900  mb-2">New Password</label>
            <div class="col-sm">
              <input [type]="password" formControlName="password" placeholder="Enter New Password" 
              class="form-control" autocomplete="off"
               [ngClass]="{ 'ng-dirty': submitted1 && f['password'].errors }" pInputText/>
               <span class="eye-tpoggle" (click)="onClick(2)">
                <i class="pi pi-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
            </span>
              <div *ngIf="submitted1 && f['password'].errors" class="invalid-feedback">
                  <small *ngIf="f['password'].errors['required']" class="p-error block">New Password is required</small>
                  <!-- <div *ngIf="f['password'].errors['minlength']" class="p-error block">New Password must be at least 8 characters</div> -->
              </div>
              <div *ngIf="f['password'].errors" class="invalid-feedback">
                <small *ngIf="f['password'].errors['pattern']" class="p-error block">Password must contain more than eight characters, at least one uppercase letter, at least one lowercase letter, at least one number, at least one special character($@$!%*?&)
                </small>
            </div>
              </div>
        </div> 
        <div class="form-group mb-4">
            <label for="confirmPassword" class="block text-900  mb-2">Confirm New Password</label>
            <div class="col-sm">
              <input [type]="connPassword" formControlName="confirmPassword" placeholder="Enter Confirm New Password" 
              class="form-control" autocomplete="off"
               [ngClass]="{ 'ng-dirty': submitted1 && f['confirmPassword'].errors }" pInputText/>
               <span class="eye-tpoggle" (click)="onClick(3)">
                <i class="pi pi-eye" aria-hidden="true" *ngIf="!showConnPassword"></i>
                <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showConnPassword"></i>
            </span>
              <div *ngIf="submitted1 && f['confirmPassword'].errors" class="invalid-feedback">
                  <small *ngIf="f['confirmPassword'].errors['required']" class="p-error block">Confirm New Password is required</small>
                </div>
                <div  *ngIf="submitted1 && changePasswordForm.hasError('notSame')" class="invalid-feedback mb-2">
                    <small class="p-error block">Confirm New Password do not match</small> 
                 </div> 
              
            </div>
        </div> 
        <!-- Save and submit on click changePassword -->
        <div class="form-group">
            <button pButton type="submit" (click)="changePassword()" pRipple label="Update Password" class="w-auto min-w-186"></button>
        </div>
        </div>
        </div>
    </form>
</div>
<!-- toast msg alert -->
<p-toast position="top-right"></p-toast>