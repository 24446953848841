import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CrewEditProfileComponent } from './crew/crew-edit-profile/crew-edit-profile.component';
import { CrewMediaComponent } from './crew/crew-media/crew-media.component';
import { CrewProfileComponent } from './crew/crew-profile/crew-profile.component';

const routes: Routes = [
  {
    path: 'crew-profile',
    data: { title: 'CrewResumes' },
    component : CrewProfileComponent ,
    canActivate : [AuthGuard]
  },
  {
    path:'crew-profile/:id',
    data: { title: 'CrewResumes' },
    component : CrewProfileComponent ,
    canActivate : [AuthGuard]
  },
  
  {
    path:'crew-media',
    data: { title: 'CrewResumes' },
    component : CrewMediaComponent,
    canActivate : [AuthGuard]
  },
  // {
  //   path:'crew-media/:mediaType',
  //   data: { title: 'CrewResumes' },
  //   component : CrewMediaComponent,
  //   canActivate : [AuthGuard]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
