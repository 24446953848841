import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment'
import { AuthGuard } from 'src/app/core/guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  
  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http:HttpClient
  ) { }
  //For login
  userLogin(userData:any){
    return this.http.post(environment.apiUrl+'users/login',userData);
  } 
  //For user info
  userMe(){
    return this.http.get(environment.apiUrl+'crews/me');
  }

  
}
