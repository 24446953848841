<div class="progress-spinner gallery-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>

<div class="pb-4">
    <!-- for media menu photo, video, audio -->
    <ul
        class="surface-card shadow-2 p-0 m-0 list-none flex overflow-x-auto select-none editprofile-tab-style border-round-sm">
        <li class="flex-1" *ngFor="let item of items">
            <a pRipple
                class="h-full cursor-pointer p-3 flex border-bottom-2 hover:border-500 transition-colors transition-duration-150 justify-content-center"
                [ngClass]="{'border-blue-500 text-blue-500 hover:border-blue-500': activeTab === item.number, 'text-900 border-transparent': activeTab !== item.number}"
                (click)="listClick($event, item)">
                <div>
                    <span class="">{{item.label}}</span>
                </div>
            </a>
        </li>
    </ul>
</div>
<!-- for photo  -->
<div *ngIf="activeTab == 0">
    <div class="surface-card shadow-2 border-round-sm">
        <div class="pt-4 px-4 surface-border" *ngIf="!photoDiv">
            <!-- <div *ngIf="imageDataList">
                <img *ngIf="imageDataList == 0" src="assets/images/profilePlaceholder.png" height="200" width="170" class="border-round-sm" style="object-fit: cover;" /> -->
            <p-galleria [value]="imageDataList" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
                [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [circular]="true"
                [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
                <ng-template pTemplate="item" let-item>
                    <img src="{{item.source}}" class="gallery-image-fullscreen" />
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                        <img src="{{item.source}}" style="display: block;"/>
                    </div>
                </ng-template>
                <ng-template pTemplate="caption" let-item>
                    <h4 style="margin-bottom: .5rem; color: #ffffff;">{{item.title}}</h4>
                    <p>{{item.description}}</p>
                </ng-template>
            </p-galleria>
            <div class="grid">
                <div class="col-6 md:col-6 lg:col-3 mb-3 pos" *ngFor="let item of imageDataList; let index = index;">
                    <ng-container *ngIf="item.mediaType == '1'">
                        <div class="edit-btn">
                            <button pButton pRipple icon="pi pi-ellipsis-v"
                                class="p-button-text p-button-plain p-button-rounded round-btn"
                                (click)="selectedItem = item;cag.toggle($event)"></button>
                            <p-menu #cag [popup]="true" [model]="imageFeature" appendTo="body"></p-menu>
                        </div>
                        <div class="" key="index">
                            <img src="{{item.thumnail}}" class="gallery-image" (click)="imageClick(index)" />
                        </div>


                        <!-- <p-image class="img_gallery"  [src]="item.source" [preview]="true"></p-image> -->
                        <!-- <img  [src]="item.source" class="media-image-edit border-round-sm"
                        style="object-fit: cover;"/> -->

                        <div class="text-medium mt-2">{{item.title}}</div>
                        <div class="text-700 mt-2">{{item.description}}</div>
                    </ng-container>
                </div>
                <div class="col-6 md:col-6 lg:col-3 mb-3 pos" *ngIf="imgLoader">
                    <p-progressSpinner class="custom-spinner"></p-progressSpinner>
                </div>
                <div class="col-6 md:col-6 lg:col-3 mb-3 pos" *ngIf="imageData">
                    <img src="{{imageData}}" class="gallery-image" />
                </div>
                <!-- </div> -->
            </div>
        </div>
        <div class="border-bottom-1 surface-border" *ngIf="imageDataList.length > 0"></div>
        <div class="col-6 md:col-6 lg:col-3 mb-3 pt-4 px-4" *ngIf="photoDiv">
            <button pButton class="p-button-outlined mr-2 mb-4" (click)="backClicked()" label="Back"></button>
            <!-- <img *ngIf="loading" src="assets\logo\loader.png" alt="loading" class="media-image-edit border-round-sm"
            style="object-fit: cover;"/> -->
            <img *ngIf="imageData" src="{{imageData}}" class="media-image-edit border-round-sm"
                style="object-fit: cover;" />
            <div class="media-image-edit border-round-sm" *ngIf="imgLoader">
                <p-progressSpinner class="custom-spinner"></p-progressSpinner>
            </div>
        </div>
        <div class="pt-4 px-4 border-bottom-1 surface-border" *ngIf="photoDiv"></div>
        <!-- [formGroup]="photosForm" used -->
        <form [formGroup]="photosForm" *ngIf="photoFormDiv">
            <div class="p-4">
                <div class="grid formgrid p-fluid">
                    <div class="col-12 lg:col-6">
                        <div class="field mb-4">
                            <label for="title" class="">Title</label>
                            <input id="title" autocomplete="off" type="text" placeholder="Enter Title"
                                formControlName="title" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedPhotosForm && f['title'].errors }" pInputText>
                            <div *ngIf="submittedPhotosForm && f['title'].errors" class="invalid-feedback">
                                <small *ngIf="f['title'].errors['required']" class="p-error block">Title is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4">
                            <label for="description" class="">Description</label>
                            <textarea id="description" autocomplete="off" type="text" pInputTextarea rows="5"
                                [autoResize]="true" formControlName="description"
                                [ngClass]="{ 'ng-dirty': submittedPhotosForm && f['description'].errors }"
                                autocomplete="off" placeholder="Enter Description"></textarea>
                            <div *ngIf="submittedPhotosForm && f['description'].errors" class="invalid-feedback">
                                <small *ngIf="f['description'].errors['required']" class="p-error block">Description is
                                    required</small>
                            </div>
                        </div>
                        <div class="mb-4 error-message-fix">
                            <p-fileUpload #fileUpload class="upload-image-input" id="file-input" mode="basic"
                                name="avatar" [maxFileSize]="10000000" [customUpload]="true"
                                (onSelect)="onUpload($event)" accept="image/*" [multiple]="false"
                                styleClass="p-button-outlined p-button-plain min-w-186" chooseLabel="Upload Photo">
                            </p-fileUpload>
                            <div class="mt-1"><small class="text-600"><i>For best result please upload min 650PX by
                                        650PX image, file size should be below 10MB</i></small></div>
                        </div>
                        <!-- on click addMediaPhotoData save photo -->
                        <div>
                            <button pButton pRipple label="{{btnTitle}}" [disabled]="photoDisable"
                                (click)="addMediaPhotoData()" class="min-w-186 w-auto"></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="p-4 border-top-1 surface-border" *ngIf="addImageBtn">
            <button pButton pRipple label="Add Photo" class="min-w-186" (click)="openForm(0)"></button>
        </div>
    </div>
</div>
<!-- for video -->
<div *ngIf="activeTab == 1">
    <div class="surface-card shadow-2 border-round-sm">
        <div class="pt-4 px-4 surface-border" *ngIf="!videoDiv">
            <div class="grid">
                <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngFor="let item of videoDataList">
                    <ng-container *ngIf="item.mediaType == 2 || item.mediaType == 4">
                        <div class="edit-btn">
                            <button pButton pRipple icon="pi pi-ellipsis-v"
                                class="p-button-text p-button-plain p-button-rounded round-btn"
                                (click)="selectedItem = item;cag.toggle($event)"></button>
                            <p-menu #cag [popup]="true" [model]="feature" appendTo="body"></p-menu>
                        </div>
                        <p-badge value="Featured" *ngIf="item.featured == 1" severity="success" styleClass="mr-2"
                            class="featuredbadge"></p-badge>
                        <video *ngIf="item.mediaType == 2" controls class="normal-video">
                            <source [src]="item.source" class="media-image-edit border-round-sm" type="video/mp4">
                        </video>

                        <div *ngIf="item.mediaType == 4">
                            <div class="linkDiv surface-border border-1" *ngIf="item.linkType == 3">
                                <a style="word-break: break-all;height: 100%;" class="mt-4 pr-6 block" target="_blank"
                                    href="{{item.mediaName}}">{{item.mediaName}}</a>
                            </div>

                            <iframe [src]="parseVimeo(item.mediaName)" *ngIf="item.linkType == 2"
                                class="responsive-video-small iframe" class="iframe-player"
                                allow="autoplay; fullscreen" allowfullscreen></iframe>

                            <youtube-player class="responsive-video-small iframe"
                                *ngIf="item.linkType == 1 && item.youTubeLinkType != 1"
                                videoId="{{this.getId(item.mediaName)}}" suggestedQuality="highres">
                            </youtube-player>

                            <iframe class="responsive-video-small iframe"
                                *ngIf="item.linkType == 1 && item.youTubeLinkType == 1"
                                class="iframe-player" [src]="safePlayListUrl(item.mediaName)"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>

                        </div>
                        <div class="text-medium mt-2">{{item.title}}</div>
                        <div class="text-700 mt-2">{{item.description}}</div>
                        <!-- <button pButton label="button" (click)="deleteMedia(item.id)"></button> -->
                    </ng-container>
                </div>
                <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngIf="videoLoader">
                    <p-progressSpinner class="custom-spinner"></p-progressSpinner>
                </div>
                <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngIf="videoData">
                    <video controls style="object-fit: cover; height: 160px;">
                        <source [src]="videoData" class="media-image-edit border-round-sm" type="video/mp4">
                    </video>
                </div>
                <!-- <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngIf="g['linkType'].value == 1">
                        <youtube-player class="responsive-video-small iframe"
                        videoId="{{this.getId(g['link'].value)}}" 
                        suggestedQuality="highres" >
                      </youtube-player>
                    </div> -->

                <!-- <iframe class="responsive-video-small iframe" *ngIf="g['linkType'].value == 1" style="height: 160px" [src]="safePlayListUrl(g['link'].value)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                <!-- <iframe [src]="parseVimeo(g['link'].value)" *ngIf="g['linkType'].value == 2"
                    class="responsive-video-small iframe" class="iframe-player"
                    allow="autoplay; fullscreen" allowfullscreen></iframe> -->

                <!-- </div> -->
            </div>
        </div>
        <div class="border-bottom-1 surface-border" *ngIf="videoDataList.length > 0"></div>
        <div class="col-12 md:col-6 lg:col-4 mb-4 pt-4 px-4" *ngIf="videoDiv">
            <button pButton class="p-button-outlined mr-2 mb-4" (click)="backClicked()" label="Back"></button>
            <video *ngIf="mediaType == 2" controls class="normal-video">
                <source [src]="videoData" class="media-image-edit border-round-sm" type="video/mp4">
            </video>

            <div *ngIf="mediaType == 4">
                <youtube-player class="responsive-video-small iframe"
                    *ngIf="mediaDetails.linkType == 1 && youTubePlayListLink != 1"
                    videoId="{{this.getId(mediaDetails.mediaName)}}" suggestedQuality="highres">
                </youtube-player>

                <iframe [src]="parseVimeo(mediaDetails.mediaName)" *ngIf="mediaDetails.linkType == 2"
                    class="responsive-video-small iframe" class="iframe-player"
                    allow="autoplay; fullscreen" allowfullscreen></iframe>

                <iframe class="responsive-video-small iframe"
                    *ngIf="mediaDetails.linkType == 1 && youTubePlayListLink == 1"
                    class="iframe-player" [src]="safePlayListUrl(youTubePlayListLinkType)"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>

                <div class="linkDiv surface-border border-1" *ngIf="mediaDetails.linkType == 3">
                    <a style="word-break: break-all; height: 100%;" class="pr-6 block" target="_blank"
                        href="{{mediaDetails.mediaName}}">{{mediaDetails.mediaName}}</a>
                </div>
            </div>
            <div class="media-image-edit border-round-sm" *ngIf="videoLoader">
                <p-progressSpinner class="custom-spinner"></p-progressSpinner>
            </div>
        </div>
        <div class="pt-4 px-4 border-bottom-1 surface-border" *ngIf="videoDiv"></div>
        <!-- [formGroup]="videosForm" used -->
        <form [formGroup]="videosForm" *ngIf="videoFormDiv">
            <div class="p-4">
                <div class="grid formgrid p-fluid">
                    <div class="col-12 lg:col-6">
                        <div class="field mb-4">
                            <label for="title" class="">Title</label>
                            <input id="title" type="text" autocomplete="off" placeholder="Enter Title"
                                formControlName="title" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedVideosForm && g['title'].errors }" pInputText>
                            <div *ngIf="submittedVideosForm && g['title'].errors" class="invalid-feedback">
                                <small *ngIf="g['title'].errors['required']" class="p-error block">Title is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4">
                            <label for="description" class="">Description</label>
                            <textarea id="description" autocomplete="off" type="text" pInputTextarea rows="5"
                                [autoResize]="true" formControlName="description"
                                [ngClass]="{ 'ng-dirty': submittedVideosForm && g['description'].errors }"
                                autocomplete="off" placeholder="Enter Description"></textarea>
                            <div *ngIf="submittedVideosForm && g['description'].errors" class="invalid-feedback">
                                <small *ngIf="g['description'].errors['required']" class="p-error block">Description is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4">
                            <p-radioButton value="1" formControlName="uploadVideo" inputId="1" class="mr-3"
                                label="Upload Video"></p-radioButton>
                            <p-radioButton value="0" formControlName="uploadVideo" inputId="0" class="mr-3"
                                label="Add Link"></p-radioButton>
                        </div>
                        <div class="field mb-4 error-message-fix"
                            [ngStyle]="{'display': g['uploadVideo'].value == 1 ? '' : 'none' }">
                            <p-fileUpload #fileUpload class="upload-image-input" id="avatar" mode="basic" name="avatar"
                                [maxFileSize]="50000000" [customUpload]="true" [multiple]="false"
                                (onSelect)="onUploadVideo($event)" accept="video/*"
                                styleClass="p-button-outlined p-button-plain" chooseLabel="Upload Videos">
                            </p-fileUpload>
                            <div class="mt-1"><small class="text-600"><i>File size should be below 50MB</i></small>
                            </div>
                        </div>
                        <div class="field" *ngIf="g['uploadVideo'].value == 0">
                            <div class="field mb-4">
                                <label class="">Link Type</label>
                                <p-dropdown optionLabel="name" [options]="linkType" formControlName="linkType"
                                    optionValue="value" placeholder="Link Type" class="ml-2"></p-dropdown>
                            </div>

                            <div class="field mb-4">
                                <label class="">Link</label>
                                <input id="link" type="text" autocomplete="off" placeholder="Link" (input)="verifyLink($event)"
                                    formControlName="link" pInputText>
                                <div>
                                    <small class="text-600">Please provide a full link with http:// or https://</small>
                                </div>
                                <div *ngIf="g['link'].errors" class="invalid-feedback">
                                    <small *ngIf="g['link'].errors['pattern']" class="p-error block">Invalid URL. Hint:
                                        http://
                                        or https:// is missing.
                                    </small>
                                </div>
                                <div *ngIf="g['linkType'].value == 1 || g['linkType'].value == 2" class="invalid-feedback">
                                    <small *ngIf="validLinkError"  class="p-error block">
                                        Please provide a valid link
                                    </small>
                                </div>
                            </div>

                        </div>
                        <!-- on click addMediaVideoData to save -->
                        <div class="field mb-0">
                            <button pButton pRipple label="{{btnTitle}}" [disabled]="videoDisable"
                                (click)="addMediaVideoData()" class="w-auto min-w-186"></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="p-4 border-top-1 surface-border" *ngIf="addVideoBtn">
            <button pButton pRipple label="Add Video" class="min-w-186" (click)="openForm(1)"></button>
        </div>
    </div>
</div>
<!-- for voice -->
<div *ngIf="activeTab == 2">
    <div class="surface-card shadow-2 border-round-sm">
        <div class="pt-4 px-4 surface-border" *ngIf="!voiceDiv">
            <div class="grid">
                <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngFor="let item of audioDataList">
                    <ng-container *ngIf="item.mediaType == 3 || item.mediaType == 5">
                        <div class="edit-btn">
                            <button pButton pRipple icon="pi pi-ellipsis-v"
                                class="p-button-text p-button-plain p-button-rounded round-btn"
                                (click)="selectedItem = item;cag.toggle($event)"></button>
                            <p-menu #cag [popup]="true" [model]="feature" appendTo="body"></p-menu>
                        </div>
                        <p-badge value="Featured" *ngIf="item.featured == 1" severity="success" styleClass="mr-2"
                            class="featuredbadge-voice"></p-badge>
                        <audio controls *ngIf="item.mediaType == 3">
                            <source [src]="item.source" class="media-image-edit border-round-sm"
                                style="object-fit: cover;" type="audio/mpeg">
                        </audio>
                        <div class="linkDivAudio surface-border border-1"
                            *ngIf="item.linkType == 3 && item.mediaType == 5">
                            <a style="word-break: break-all;height: 100%;" class="mt-2 pr-6 block" target="_blank"
                                href="{{item.mediaName}}">{{item.mediaName}}</a>
                        </div>
                        <div class="text-medium mt-2">{{item.title}}</div>
                        <div class="text-700 mt-2">{{item.description}}</div>
                    </ng-container>
                </div>
                <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngIf="audioLoader">
                    <p-progressSpinner class="custom-spinner"></p-progressSpinner>
                </div>
                <div class="col-12 md:col-6 lg:col-4 mb-4 pos" *ngIf="voiceData">
                    <audio controls>
                        <source [src]="voiceData" class="media-image-edit border-round-sm" style="object-fit: cover;"
                            type="audio/mpeg">
                    </audio>
                </div>
            </div>
        </div>
        <div class="border-bottom-1 surface-border" *ngIf="audioDataList.length > 0"></div>
        <div class="col-12 md:col-6 lg:col-4 mb-4 pt-4 px-4 pos" *ngIf="voiceDiv">
            <button pButton class="p-button-outlined mr-2 mb-4" (click)="backClicked()" label="Back"></button>
            <audio controls *ngIf="voiceData">
                <source [src]="voiceData" class="media-image-edit border-round-sm" style="object-fit: cover;"
                    type="audio/mpeg">
            </audio>
            <div class="linkDivAudio surface-border border-1" *ngIf="audioLink">
                <a style="word-break: break-all; height: 100%;" class="pr-6 block" target="_blank"
                    href="{{audioLink}}">{{audioLink}}</a>
            </div>
            <div class="media-image-edit border-round-sm" *ngIf="audioLoader">
                <p-progressSpinner class="custom-spinner"></p-progressSpinner>
            </div>
        </div>
        <div class="pt-4 px-4 border-bottom-1 surface-border" *ngIf="voiceDiv"></div>
        <!-- [formGroup]="voiceOverForm" used -->
        <form [formGroup]="voiceOverForm" *ngIf="audioFormDiv">
            <div class="p-4">
                <div class="grid formgrid p-fluid">
                    <div class="col-12 lg:col-6">
                        <div class="field mb-4">
                            <label for="title" class="">Title</label>
                            <input id="title" type="text" autocomplete="off" placeholder="Enter Title"
                                formControlName="title"
                                [ngClass]="{ 'ng-dirty': submittedVoiceOverForm && h['title'].errors }" pInputText>
                            <div *ngIf="submittedVoiceOverForm && h['title'].errors" class="invalid-feedback">
                                <small *ngIf="h['title'].errors['required']" class="p-error block">Title is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4">
                            <label for="description" class="">Description</label>
                            <textarea id="description" type="text" autocomplete="off" pInputTextarea rows="5"
                                [autoResize]="true" formControlName="description"
                                [ngClass]="{ 'ng-dirty': submittedVoiceOverForm && h['description'].errors }"
                                placeholder="Enter Description"></textarea>
                            <div *ngIf="submittedVoiceOverForm && h['description'].errors" class="invalid-feedback">
                                <small *ngIf="h['description'].errors['required']" class="p-error block">Description is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4">
                            <p-radioButton value="1" formControlName="uploadAudio" inputId="1" class="mr-3"
                                label="Upload Audio"></p-radioButton>
                            <p-radioButton value="0" formControlName="uploadAudio" inputId="0" class="mr-3"
                                label="Add Link"></p-radioButton>
                        </div>
                        <div class="field mb-4 error-message-fix"
                            [ngStyle]="{'display':h['uploadAudio'].value == 1 ? '' : 'none' }">
                            <p-fileUpload #fileUpload class="upload-image-input" id="avatar" mode="basic" name="avatar"
                                [maxFileSize]="50000000" [customUpload]="true" [multiple]="false"
                                (onSelect)="onUploadVoice($event)" accept="audio/*"
                                styleClass="p-button-outlined p-button-plain" chooseLabel="Upload Voice Over">
                            </p-fileUpload>
                            <div class="mt-1"><small class="text-600"><i>File size should be below 50MB</i></small>
                            </div>
                        </div>
                        <div class="field" *ngIf="h['uploadAudio'].value == 0">
                            <div class="field mb-4">
                                <label class="">Link</label>
                                <input id="link" type="text" autocomplete="off" placeholder="Link"
                                    formControlName="link" pInputText>
                                <div>
                                    <small class="text-600">Please provide a full link with http:// or https://</small>
                                </div>
                                <div *ngIf="h['link'].errors" class="invalid-feedback">
                                    <small *ngIf="h['link'].errors['pattern']" class="p-error block">Invalid URL. Hint:
                                        http://
                                        or https:// is missing.
                                    </small>
                                </div>
                            </div>

                        </div>
                        <!-- save on click addMediaVoiceData -->
                        <div class="field mb-0">
                            <button pButton pRipple label="{{btnTitle}}" [disabled]="voiceDisable"
                                (click)="addMediaVoiceData()" class="w-auto min-w-186"></button>
                        </div>
                    </div>

                </div>
            </div>
        </form>
        <div class="p-4 border-top-1 surface-border" *ngIf="addAudioBtn">
            <button pButton pRipple label="Add Voice Over" class="min-w-186" (click)="openForm(2)"></button>
        </div>
    </div>
</div>
<!-- confirmDialog box -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
    <ng-template pTemplate="footer">
        <button type="button" pButton class="p-button-danger" icon="pi pi-times" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton class="p-button-success" icon="pi pi-check" label="Yes"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>
<!-- for toast msg alert -->
<p-toast position="top-right"></p-toast>