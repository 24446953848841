
<div class="grid emp-box">
    <div class="col-12">
        <div class="card box-height">
            <!-- data table -->
            <p-table 
            #dt
            [value]="products"
            [totalRecords]='totalRecords'
            expandableRows='true' 
            dataKey=''
            [rows]='10' 
            [paginator]='true' 
            [rowsPerPageOptions]='[10,20,30, { showAll: "All" }]'
            [lazy]="false" 
            [loading]="loading" 
            [scrollable]="true"
            responsiveLayout="stack"
            >
              <!-- column heading -->
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width:25%">Transaction ID</th>
                        <th style="min-width:10%">Amount</th>
                        <th pSortableColumn="status" style="min-width:12%">Payment Status <p-sortIcon field="status"></p-sortIcon></th>
                        <th style="min-width:15%">Description</th>
                        <th style="min-width:15%">Failure Message</th>
                        <th pSortableColumn="date" style="min-width:13%">Date <p-sortIcon field="date"></p-sortIcon></th>
                    </tr>
                </ng-template>
                   <!-- column list of table -->
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td style="min-width:25%; word-break: break-all;">{{item.txnId}}</td>
                        <td style="min-width:10%">$ {{item.amount}}</td>
                        <td style="min-width:12%">{{item.status}}</td>
                        <td style="min-width:15%">{{item.description}}</td>
                        <td style="min-width:15%">{{item.failureMessage}}</td>
                        <td style="min-width:13%">{{item.date}}</td>
                    </tr>
                </ng-template>
                   <!-- if there is no data in table -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                      <td>
                        There are no data to display
                      </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
