<!-- pageLoader -->
<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="pb-4">
    <!-- For top bar in edit profile -->
    <ul
        class="surface-card shadow-2 p-0 m-0 list-none flex overflow-x-auto select-none editprofile-tab-style border-round-sm">
        <li class="flex-1" *ngFor="let item of items">
            <a pRipple
                class="h-full cursor-pointer p-3 flex border-bottom-2 hover:border-500 transition-colors transition-duration-150  justify-content-center"
                [ngClass]="{'border-blue-500 text-blue-500 hover:border-blue-500': activeTab === item.number, 'text-900 border-transparent': activeTab !== item.number}"
                (click)="listClick($event, item)">
                <div class="flex align-items-center">
                    <span class="">{{item.label}}</span>
                    <!-- <small class="hidden lg:block">Photo, Bio, etc.</small> -->
                </div>
                <!-- <div>
                    <span class="">Personal</span><br>
                    <small>Photo, bio, nationality, etc</small>
                </div> -->
            </a>
        </li>
    </ul>
</div>
<div class="" *ngIf="activeTab == 0">
    <div class="surface-card p-4 shadow-2 border-round-sm">
        <!-- For personalForm formGroup -->
        <form [formGroup]="personalForm">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="name" class="">Name</label>
                    <input id="name" type="text" placeholder="Enter Name" formControlName="name" autocomplete="off"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['name'].errors }" pInputText>
                    <div *ngIf="submittedPersonalForm && f['name'].errors" class="invalid-feedback">
                        <small *ngIf="f['name'].errors['required']" class="p-error block">Name is required</small>
                    </div>
                </div>
                <!-- <div class="field mb-4 col-12 lg:col-6">
                    <label for="dob" class="">Date of Birth</label>
                    <p-calendar  placeholder="Enter Date of Birth" styleClass="w-full" [disabled]="true"
                    formControlName="dob" (onSelect)="dobPick($event)"  [maxDate]="maxDate"
                    [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['dob'].errors }">
                    </p-calendar>
                    <div *ngIf="submittedPersonalForm && f['dob'].errors" class="invalid-feedback">
                        <small *ngIf="f['dob'].errors['required']" class="p-error block">Date of Birth is
                        required</small>
                    </div>
                </div>     -->
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="tagLine" class="">Tag Line</label>
                    <input id="tagLine" type="tagLine" placeholder="Add Your Tag Line " formControlName="tagLine"
                        autocomplete="off" pInputText>
                    <!-- <div *ngIf="submittedPersonalForm && f['tagLine'].errors" class="invalid-feedback">
                        <small *ngIf="f['tagLine'].errors['tagLine']" class="p-error block">Tag Line is required</small>
                    </div> -->
                </div>
                <div class="surface-100 col-12"></div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="bio" class="">Bio</label>
                    <textarea id="bio" type="text" autocomplete="off" pInputTextarea rows="5" [autoResize]="false"
                        formControlName="bio" [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['bio'].errors }"
                        placeholder="Enter Bio"></textarea>
                    <div *ngIf="submittedPersonalForm && f['bio'].errors" class="invalid-feedback">
                        <small *ngIf="f['bio'].errors['required']" class="p-error block">Bio is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="avatar" class="">Profile Avatar <small class="text-600"><i>For best result please
                                upload min 350PX by 350PX image, file size should be below 10MB</i></small></label>
                    <div class="flex align-items-center">
                        <img *ngIf="!profileImage" src="assets/images/profilePlaceholder.png" class="mr-4" height="75"
                            width="75" style="border-radius:50% ;object-fit: cover;" />
                        <img *ngIf="profileImage" src="{{profileImage}}" class="mr-4" height="75" width="75"
                            style="border-radius:50% ; object-fit: cover;" />

                        <p-fileUpload class="upload-image-input" id="avatar" mode="basic" name="avatar" [auto]="true"
                            [maxFileSize]="10000000" [customUpload]="true" [multiple]="false"
                            (onSelect)="onUpload($event)" accept="image/*" #fileUpload
                            [ngStyle]="{'display':clearImage === 1 ? 'none' : '' }"
                            styleClass="p-button-outlined p-button-plain" chooseLabel="Upload Image"></p-fileUpload>

                        <div>
                            <button pButton pRipple label="Clear" *ngIf="clearImage == 1" (click)="clearUploadImage()"
                                class="w-auto p-button-outlined"></button>
                        </div>
                        <!-- <input type="file" name="file" pInputText 
                            (change)="onChange($event)"> -->
                    </div>
                </div>

                <!-- social Media Info Start -->

                <div class="surface-100 col-12"></div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="socialMediaDescription" class="">Social Media Info</label>
                    <textarea id="socialMediaDescription" type="text" autocomplete="off" pInputTextarea rows="5"
                        [autoResize]="false" formControlName="socialMediaDescription"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['socialMediaDescription'].errors }"
                        placeholder="Enter Social Media Info"></textarea>
                    <div *ngIf="submittedPersonalForm && f['socialMediaDescription'].errors" class="invalid-feedback">
                        <small *ngIf="f['socialMediaDescription'].errors['required']" class="p-error block">Social Media
                            Info is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="avatar" class="">Social Media Photo <small class="text-600"><i>For best result please
                                upload min 1200PX X 630PX image, file size should be below 10MB</i></small></label>
                    <div class="flex align-items-center">
                        <img *ngIf="!socialMediaImage" src="assets/images/profilePlaceholder.png" class="mr-4"
                            height="75" width="75" style="border-radius:50% ;object-fit: cover;" />
                        <img *ngIf="socialMediaImage" [src]="socialMediaImageFileData" class="mr-4" height="75"
                            width="75" style="border-radius:50% ;object-fit: cover;" />
                        <button ly-button outlined (click)="openCropperDialog($event)"
                            class="broder-color button-hover">
                            <i class="pi pi-plus text-base"></i>
                            <span class="ml-3">Upload Image</span>
                        </button>
                    </div>
                </div>

                <!-- social Media Info End -->

                <div class="surface-100 col-12"></div>
                <!-- <div class="field mb-4 col-12 lg:col-6">
                    <label for="jobTitle" class="">Job Title</label>
                        <p-multiSelect
                        formControlName="jobTitle"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['jobTitle'].errors }"
                        placeholder="Select Job Title"></p-multiSelect>
                    <div *ngIf="submittedPersonalForm && f['jobTitle'].errors" class="invalid-feedback">
                        <small *ngIf="f['jobTitle'].errors['required']" class="p-error block">Job 
                            is required</small>
                    </div>
                </div> -->
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="gender" class="">Gender Identity</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['gender']" [filter]="true"
                        filterBy="label" optionValue="value" formControlName="gender"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['gender'].errors }"
                        placeholder="Select a Gender Identity"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['gender'].errors" class="invalid-feedback">
                        <small *ngIf="f['gender'].errors['required']" class="p-error block">Gender Identity is
                            required</small>
                    </div>
                </div>

                <!-- <div class="field mb-4 col-12 lg:col-6">
                    <label for="country" class="">Country of Origin</label>
                    <input id="country" type="text" formControlName="countryofOrigin" autocomplete="off" readonly
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['countryofOrigin'].errors }" pInputText>
                    <div *ngIf="submittedPersonalForm && f['countryofOrigin'].errors" class="invalid-feedback">
                        <small *ngIf="f['countryofOrigin'].errors['required']" class="p-error block">Country of Origin
                            is required</small>
                    </div>
                </div> -->
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="nationality" class="">Nationality</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['nationality']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="nationality"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['nationality'].errors }"
                        placeholder="Select a Nationality"></p-multiSelect>
                    <!-- <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['nationality']"
                        optionValue="value" [filter]="true" filterBy="label" formControlName="nationality"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['nationality'].errors }"
                        placeholder="Select a Nationality"></p-dropdown> -->
                    <div *ngIf="submittedPersonalForm && f['nationality'].errors" class="invalid-feedback">
                        <small *ngIf="f['nationality'].errors['required']" class="p-error block">Nationality is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="multipleBirths" class="">Multiple Births</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['multipleBirths']"
                        [filter]="true" filterBy="label" optionValue="value" formControlName="multipleBirths"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['multipleBirths'].errors }"
                        placeholder="Select a Multiple Births"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['multipleBirths'].errors" class="invalid-feedback">
                        <small *ngIf="f['multipleBirths'].errors['required']" class="p-error block">Multiple Births is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="Covid-19Vaccination" class="">Covid-19 Vaccination</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['covidVaccination']"
                        optionValue="value" formControlName="covidVaccination"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['covidVaccination'].errors }"
                        placeholder="Select Covid-19 Vaccination"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['covidVaccination'].errors" class="invalid-feedback">
                        <small *ngIf="f['covidVaccination'].errors['required']" class="p-error block">Covid-19
                            Vaccination is required</small>
                    </div>
                </div>
                <!-- <div class="field mb-4 col-12 lg:col-6">
                    <label for="Covid-19Passport" class="">Covid-19 Passport</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['covidPassport']" optionValue="value"
                        formControlName="covidPassport"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['covidPassport'].errors }"
                        placeholder="Select Covid-19 Passport"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['covidPassport'].errors" class="invalid-feedback">
                        <small *ngIf="f['covidPassport'].errors['required']" class="p-error block">Covid-19 Passport is
                            required</small>
                    </div>
                </div> -->

                <!-- <div class="field mb-4 col-12 lg:col-6">
                    <label for="covid19ComplianceDepartment" class="">Covid 19 Compliance Department</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['covid19ComplianceDepartment']"
                        [filter]="true" filterBy="label" optionValue="value"
                        formControlName="covid19ComplianceDepartment"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['covid19ComplianceDepartment'].errors }"
                        placeholder="Select Covid 19 Compliance Department"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['covid19ComplianceDepartment'].errors"
                        class="invalid-feedback">
                        <small *ngIf="f['covid19ComplianceDepartment'].errors['required']" class="p-error block">CCovid
                            19 Compliance Department is
                            required</small>
                    </div>
                </div> -->

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="ethnicity" class="">Ethnicity</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['ethnicity']"
                        optionValue="value" formControlName="ethnicity" [filter]="true" filterBy="label"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['ethnicity'].errors }"
                        placeholder="Select a Ethnicity"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['ethnicity'].errors" class="invalid-feedback">
                        <small *ngIf="f['ethnicity'].errors['required']" class="p-error block">Ethnicity is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="lifeChallenges" class="">Life's Challenges</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['lifeChallenges']" optionValue="value"
                        formControlName="lifeChallenges"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['lifeChallenges'].errors }"
                        placeholder="Select Life's Challenges"></p-multiSelect>
                    <div *ngIf="submittedPersonalForm && f['lifeChallenges'].errors" class="invalid-feedback">
                        <small *ngIf="f['lifeChallenges'].errors['required']" class="p-error block">Life's Challenges is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="dualCitizenship" class="">Dual Citizenship</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['dualCitizenship']"
                        optionValue="value" formControlName="dualCitizenship"
                        [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['dualCitizenship'].errors }"
                        placeholder="Select a Dual Citizenship"></p-dropdown>
                    <div *ngIf="submittedPersonalForm && f['dualCitizenship'].errors" class="invalid-feedback">
                        <small *ngIf="f['dualCitizenship'].errors['required']" class="p-error block">Dual Citizenship is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">

                    <label for="userName" class=""><span
                            style="text-transform: lowercase;">https://www.crewresumes.com/{{f['userName'].value}}</span></label>
                    <span class="p-input-icon-right">
                        <input id="userName" (paste)="onPaste($event)" type="text" autocomplete="off"
                            pTooltip='You can use only alphabets, numbers and following special characters ".", "-", "_"'
                            (input)="checkUserName($event)" tooltipPosition="bottom" pInputText class="w-full"
                            formControlName="userName" placeholder="Username" style="text-transform: lowercase;"
                            [ngClass]="{ 'ng-dirty': submittedPersonalForm && f['userName'].errors }">
                        <!-- <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> -->
                        <i class="pi pi-check" *ngIf="isCorrectUserName"></i>
                        <i class="pi pi-spin pi-spinner" *ngIf="isValidUserNameLoader"></i>
                    </span>
                    <div *ngIf="submittedPersonalForm && f['userName'].errors" class="invalid-feedback">
                        <small *ngIf="f['userName'].errors['required']" class="p-error block">Username is
                            required</small>
                        <small *ngIf="f['userName'].errors['minlength']" class="p-error block">Username at least 4
                            characters
                        </small>
                    </div>
                    <div *ngIf="userNameError" class="invalid-feedback">
                        <small class="p-error block">Username is invalid. You can use only alphabets, numbers and
                            following special characters ".", "-", "_"</small>
                    </div>
                </div>
                <div class="col-12"></div>
                <div class="field mb-0 col-12 lg:col-6">
                    <label for="resumes" class="">Resumes <small class="text-600"><i>Only .pdf file type
                                allowed</i></small></label>
                    <!-- <div class="flex border-bottom-1 surface-border py-3 mb-3 align-items-center">
                        <div class="w-4">Resume Title</div>
                        <div class="w-4"><a href="">filename.pdf</a></div>
                        <div class="w-4 text-right">
                            <button  pButton pRipple icon="pi pi-trash" class="w-100 bg-gray-900 border-gray-900 p-button-sm p-1"></button>
                        </div>
                    </div> -->
                    <ng-container formArrayName="resumes"
                        *ngFor="let item of getResumesControls();let i = index;first as first, last as last">
                        <div class="grid formgrid p-fluid" [ngClass]="{'resumeUploaded' :  item.value.myClass }"
                            [formGroupName]="i">
                            <div class="field mb-4 col-12 resume-title">
                                <input id="name" type="text" autocomplete="off" placeholder="Enter Name"
                                    formControlName="title"
                                    [ngClass]="{ 'ng-dirty': submittedPersonalForm && resumes.controls[i].get('title')?.hasError('required') }"
                                    pInputText>

                                <div *ngIf="submittedPersonalForm && resumes.controls[i].get('title')?.hasError"
                                    class="invalid-feedback">
                                    <small *ngIf="resumes.controls[i].get('title')?.hasError('required')"
                                        class="p-error block mb-2">Resume
                                        Name is required</small>

                                </div>
                            </div>
                            <div class="field mb-4 col-12 resume-buttons">

                                <div class="flex align-items-center">
                                    <div *ngIf="item.value.resume">
                                        <!-- <small class="text-yellow-600" *ngIf="uploadMsg">Please click the button to upload the selected file</small> -->
                                        <span class="text-700" *ngIf="item.value.id > 0"><a
                                                href="{{item.value.resumeSrc}}" target="_blank"
                                                class="text-blue-500">View
                                                Resume</a>
                                        </span>
                                    </div>
                                    <p-fileUpload id="avatar" mode="basic" class="mr-3 upload-resume-input"
                                        styleClass="p-button-outlined p-button-plain" chooseLabel="Upload Resume"
                                        [maxFileSize]="10000000" [customUpload]="true" accept=".pdf"
                                        (onSelect)="onUploadResume($event,i)">
                                    </p-fileUpload>

                                    <button *ngIf="(userData.personalInfo?.resumes.length > 0) && last" pButton pRipple
                                        icon="pi pi-trash" class="w-100 bg-gray-900 border-gray-900 ml-2"
                                        (click)="removeItem(i,item,item.value.id)"></button>
                                    <button
                                        *ngIf="(userData.personalInfo?.resumes.length == 0 && i >= 0 && !first || !last) "
                                        pButton pRipple icon="pi pi-trash"
                                        class="w-100 bg-gray-900 border-gray-900 ml-2"
                                        (click)="removeItem(i,item,item.value.id)"></button>
                                    <button *ngIf="last" pButton pRipple icon="pi pi-plus"
                                        class="w-100 bg-gray-900 border-gray-900 add-resume-button ml-2"
                                        (click)="addResumes()"></button>
                                </div>
                                <div *ngIf="submittedPersonalForm && resumes.controls[i].get('resume')?.hasError"
                                    class="invalid-feedback">
                                    <small *ngIf="resumes.controls[i].get('resume')?.hasError('required')"
                                        class="p-error block mb-2">Resume
                                        is required</small>

                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- Save personalForm details on click savePersonalDetails -->
                <div class="col-12">
                    <button pButton pRipple label="Save & Next" [disabled]="isValidUserName"
                        (click)="savePersonalDetails()" class="w-auto"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="" *ngIf="activeTab == 1">
    <div class="surface-card p-4 shadow-2 border-round-sm">
        <!-- For contactForm formGroup -->
        <form [formGroup]="contactForm">
            <div class="grid formgrid p-fluid">
                <div class="field mb-2 col-12 lg:col-12" *ngIf="age <= 18">
                    <label for="guardian" class=""><b>Guardian's information in mandatory for all users under
                            the age of 18.</b></label>
                </div>
                <div class="col-12"></div>
                <div class="field mb-4 col-12 lg:col-6" *ngIf="age <= 18">
                    <label for="guardian" class="">Guardian</label>
                    <input id="guardian" autocomplete="off" type="text" placeholder="Enter Guardian Name"
                        formControlName="guardian"
                        [ngClass]="{ 'ng-dirty': submittedContactForm && g['guardian'].errors }" pInputText>
                    <div *ngIf="submittedContactForm && g['guardian'].errors" class="invalid-feedback">
                        <small *ngIf="g['guardian'].errors['required']" class="p-error block">Guardian name is
                            required</small>
                    </div>
                </div>
                <div class="col-12"></div>
                <div class="field mb-4 col-12 lg:col-6" *ngIf="age <= 18">
                    <label for="guardianPhone" class="">Guardian's Phone</label>
                    <!-- For phone number pin code  -->
                    <div class="inputtype-tel">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                            [phoneValidation]="true" name="phone" [separateDialCode]="separateDialCode"
                            formControlName="guardianPhone" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedContactForm && g['guardianPhone'].errors }">
                        </ngx-intl-tel-input>
                        <div *ngIf="submittedContactForm && g['guardianPhone'].errors" class="invalid-feedback">
                            <small *ngIf="g['guardianPhone'].errors['required']" class="p-error block">Guardian's Phone
                                is required</small>
                            <small *ngIf="g['guardianPhone'].errors" class="p-error block">Invalid Phone Number</small>
                        </div>
                    </div>
                    <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter Guardian's Phone" autocomplete="off"
                        formControlName="guardianPhone"
                        [ngClass]="{ 'ng-dirty': submittedContactForm && g['guardianPhone'].errors }">
                    </p-inputMask>
                    <div *ngIf="submittedContactForm && g['guardianPhone'].errors" class="invalid-feedback">
                        <small *ngIf="g['guardianPhone'].errors['required']" class="p-error block">Guardian's Phone
                            is required</small>
                    </div> -->
                </div>
                <div class="field mb-4 col-12 lg:col-6" *ngIf="age <= 18">
                    <label for="guardianEmail" class="">Guardian's Email</label>
                    <input id="guardianEmail" type="email" placeholder="Enter Guardian's Email" autocomplete="off"
                        formControlName="guardianEmail"
                        [ngClass]="{ 'ng-dirty': submittedContactForm && g['guardianEmail'].errors }" pInputText>
                    <div *ngIf="submittedContactForm && g['guardianEmail'].errors" class="invalid-feedback">
                        <small *ngIf="g['guardianEmail'].errors['required']" class="p-error block">Guardian's Email
                            is required</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="flex">
                        <strong class="">Point Agent</strong>
                        <div class="field-checkbox ml-2">
                            <p-checkbox formControlName="hasAgent" [binary]="true"></p-checkbox>
                            <label for="binary">Not at this time</label>
                        </div>
                    </div>
                    <div class=" formgrid p-fluid mt-3" *ngIf="g['hasAgent'].value == 0 ">

                        <ng-container formArrayName="agents">
                            <div *ngFor="let item of getAgentsControls();let i = index;first as first, last as last">
                                <div class="">
                                    <div [formGroupName]="i">
                                        <div class="grid">
                                            <div class="field mb-4 col-12 lg:col-6">
                                                <label for="agentTypes" class="">Point Agent Type</label>
                                                <p-dropdown optionLabel="name" [showClear]="true"
                                                    [options]="fieldsList['agentTypes']" [filter]="true" filterBy="name"
                                                    optionValue="id" formControlName="agentTypeId"
                                                    placeholder="Select Agent Types"
                                                    (onChange)="onAgentTypeChange($event.value,i)"></p-dropdown>
                                            </div>

                                            <div class="field mb-4 col-12 lg:col-6"
                                                *ngIf="agents.controls[i].get('agentTypeId')?.value === 17">
                                                <label for="agentType" class="">Point Agent Type (Other)</label>
                                                <input type="text" pInputText formControlName="agentType">
                                            </div>


                                            <div class="field mb-4 col-12 lg:col-6">
                                                <label for="agentName" class="flex justify-content-start align-items-center">Point Agent Name<span class="pi pi-question-circle mr-5 ml-2 cursor-pointer"
                                                    (mouseenter)="showHints[i] = 'agentName'"
                                                    (mouseleave)="showHints[i] = ''"></span></label>
                                            <span class="custom-hint" *ngIf="showHints[i] === 'agentName'">
                                                You may type in this box to fit your needs, such as 'currently seeking', or 'taking
                                                    meetings', etc.
                                            </span>
                                                <input id="agentName" type="text" placeholder="Enter Agent Name"
                                                    formControlName="agentName" autocomplete="off" pInputText>
                                                <!-- <div *ngIf="submittedContactForm && agents.controls[i].get('agentName')?.hasError"
                                                    class="invalid-feedback">
                                                    <small
                                                        *ngIf="agents.controls[i].get('agentName')?.hasError('required')"
                                                        class="p-error block mb-2">Agent
                                                        name is
                                                        required</small>

                                                </div> -->

                                                <!-- <div *ngIf="submittedContactForm && contactForm.get('agents.' + r + '.agentName')?.hasError('required')"
                                                    class="invalid-feedback">
                                                    <small class="p-error block mb-2">Agent Name is
                                                        required</small>

                                                </div> -->
                                            </div>

                                            <div class="field mb-4 col-12 lg:col-6">
                                                <label for="agentPhone" class="">Point Agent's Phone</label>

                                                <div class="inputtype-tel">
                                                    <ngx-intl-tel-input [cssClass]="'custom'"
                                                        [preferredCountries]="preferredCountries"
                                                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                                                        [phoneValidation]="true" name="phone"
                                                        [separateDialCode]="separateDialCode"
                                                        formControlName="agentPhone" autocomplete="off">
                                                    </ngx-intl-tel-input>
                                                    <!-- <div *ngIf="submittedContactForm && agents.controls[i].get('agentPhone')?.hasError"
                                                        class="invalid-feedback">
                                                        <small
                                                            *ngIf="agents.controls[i].get('agentPhone')?.hasError('required')"
                                                            class="p-error block">Agent's Phone
                                                            is required</small>
                                                        <small
                                                            *ngIf="agents.controls[i].get('agentPhone')?.hasError('required')"
                                                            class="p-error block">Invalid
                                                            Phone Number</small>
                                                    </div> -->
                                                    <!-- <div *ngIf="submittedContactForm && contactForm.get('agents.' + r + '.agentPhone')?.hasError('required')"
                                                        class="invalid-feedback">
                                                        <small class="p-error block mb-2">Agent phone is
                                                            required</small> -->
                                                    <!-- <small   class="p-error block">Invalid Phone Number</small> -->

                                                </div>
                                                <!-- <div *ngIf="contactForm.get('agents.' + r + '.agentPhone')?.hasError('pattern')"
                                                        class="invalid-feedback">
                                                        <small class="p-error block mb-2">Invalid Phone Number</small>
                                                    </div> -->
                                            </div>

                                            <!-- </div> -->

                                            <div class="field mb-4 col-12 lg:col-6">

                                                <label for="agentEmail" class="">Point Agent's Email</label>
                                                <input id="agentEmail" type="email" placeholder="Enter Agent's Email"
                                                    formControlName="agentEmail" autocomplete="off" pInputText>
                                                <!-- <div *ngIf="submittedContactForm && agents.controls[i].get('agentEmail')?.hasError"
                                                    class="invalid-feedback">
                                                    <small
                                                        *ngIf="agents.controls[i].get('agentEmail')?.hasError('required')"
                                                        class="p-error block">Agent's Email
                                                        is required</small>
                                                </div> -->
                                                <!-- <div *ngIf="submittedContactForm && contactForm.get('agents.' + r + '.agentEmail')?.hasError('required')"
                                                    class="invalid-feedback">
                                                    <small class="p-error block mb-2">Agent Email is
                                                        required</small>

                                                </div>

                                                <div *ngIf="submittedContactForm && contactForm.get('agents.' + r + '.agentEmail')?.hasError('pattern')"
                                                    class="invalid-feedback">
                                                    <small class="p-error block mb-2">Invalid agent email</small>

                                                </div> -->
                                            </div>
                                            <div class="field mb-4 col-12 lg:col-6">

                                                <label for="location" class="">Point Agent's Location</label>
                                                <input id="location" type="text" #placesRef="ngx-places"
                                                    ngx-google-places-autocomplete [options]="options"
                                                    (onAddressChange)="handleAddressChange($event,i)"
                                                    placeholder="Enter Agent's Location" formControlName="location"
                                                    pInputText>
                                            </div>

                                            <div class="col-12 pb-3">
                                                <div class="flex align-items-center">
                                                    <hr class="w-full h-full">
                                                    <button *ngIf="getAgentsControls().length > 1" pButton pRipple
                                                        icon="pi pi-trash"
                                                        class="w-100 bg-gray-900 border-gray-900 ml-2 cursor-pointer"
                                                        (click)="removeAgent(i)"></button>
                                                    <button *ngIf="last" pButton pRipple icon="pi pi-plus"
                                                        class="w-100 bg-gray-900 border-gray-900 add-resume-button ml-2"
                                                        (click)="addAgents()"></button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- <div class="field mb-4 col-12 lg:col-6">
                            <label for="agent" class="">Agent Name</label>
                            <input id="agent" type="text" placeholder="Enter Agent Name" formControlName="agent" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['agent'].errors }" pInputText>
                            <div *ngIf="submittedContactForm && g['agent'].errors" class="invalid-feedback">
                                <small *ngIf="g['agent'].errors['required']" class="p-error block">Agent name is
                                    required</small>
                            </div>
                        </div>
                        <div class="col-12"></div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="agentPhone" class="">Agent's Phone</label>
                           
                            <div class="inputtype-tel">
                                <ngx-intl-tel-input
                                    [cssClass]="'custom'"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15"
                                    [phoneValidation]="true"
                                    name="phone"
                                    [separateDialCode]="separateDialCode"
                                    formControlName="agentPhone"
                                    autocomplete="off"
                                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['agentPhone'].errors }"
                                    >
                                </ngx-intl-tel-input>
                                <div *ngIf="submittedContactForm && g['agentPhone'].errors" class="invalid-feedback">
                                    <small *ngIf="g['agentPhone'].errors['required']" class="p-error block">Agent's Phone
                                        is required</small>
                                    <small *ngIf="g['agentPhone'].errors" class="p-error block">Invalid Phone Number</small>
                                </div> 
                            </div>
                         
                        </div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="agentEmail" class="">Agent's Email</label>
                            <input id="agentEmail" type="email" placeholder="Enter Agent's Email"
                                formControlName="agentEmail" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['agentEmail'].errors }"
                                pInputText>
                            <div *ngIf="submittedContactForm && g['agentEmail'].errors" class="invalid-feedback">
                                <small *ngIf="g['agentEmail'].errors['required']" class="p-error block">Agent's Email
                                    is required</small>
                            </div>
                        </div> -->
                    </div>
                    <hr class="mb-4 mt-2" *ngIf="g['hasAgent'].value == 1">
                </div>
                <div class="col-12">
                    <div class="flex">
                        <strong class="">Manager</strong>
                        <div class="field-checkbox ml-2">
                            <p-checkbox formControlName="hasManager" [binary]="true"></p-checkbox>
                            <label for="binary">Not at this time</label>
                        </div>
                    </div>
                    <div class="col-12"></div>
                    <div class="grid formgrid p-fluid" *ngIf="g['hasManager'].value == 0">
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="manager" class="flex justify-content-start align-items-center">Manager Name<span class="pi pi-question-circle mr-5 ml-2 cursor-pointer"
                                (mouseenter)="showHint = 'manager'"
                                (mouseleave)="showHint = ''"></span></label>
                        <span class="custom-hint" *ngIf="showHint === 'manager'">
                            You may type in this box to fit your needs, such as 'currently seeking', or 'taking
                                meetings', etc.
                        </span>
                            <input id="manager" type="text" placeholder="Enter Manager Name" formControlName="manager"
                                autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['manager'].errors }" pInputText>
                            <div *ngIf="submittedContactForm && g['manager'].errors" class="invalid-feedback">
                                <small *ngIf="g['manager'].errors['required']" class="p-error block">Manager name is
                                    required</small>
                            </div>
                        </div>
                        <div class="col-12"></div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="managerPhone" class="">Manager's Phone</label>
                            <!-- For phone number pin code  -->
                            <div class="inputtype-tel">
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15" [phoneValidation]="true" name="phone"
                                    [separateDialCode]="separateDialCode" formControlName="managerPhone"
                                    autocomplete="off"
                                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['managerPhone'].errors }">
                                </ngx-intl-tel-input>
                                <div *ngIf="submittedContactForm && g['managerPhone'].errors" class="invalid-feedback">
                                    <small *ngIf="g['managerPhone'].errors['required']" class="p-error block">Manager's
                                        Phone is required</small>
                                    <small *ngIf="g['managerPhone'].errors" class="p-error block">Invalid Phone
                                        Number</small>
                                </div>
                            </div>
                            <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter Manager's Phone" autocomplete="off"
                                formControlName="managerPhone"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['managerPhone'].errors }">
                            </p-inputMask>
                            <div *ngIf="submittedContactForm && g['managerPhone'].errors" class="invalid-feedback">
                                <small *ngIf="g['managerPhone'].errors['required']" class="p-error block">Manager's
                                    Phone is required</small>
                            </div> -->
                        </div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="managerEmail" class="">Manager's Email</label>
                            <input id="managerEmail" type="email" placeholder="Enter Manager's Email"
                                formControlName="managerEmail" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['managerEmail'].errors }" pInputText>
                            <div *ngIf="submittedContactForm && g['managerEmail'].errors" class="invalid-feedback">
                                <small *ngIf="g['managerEmail'].errors['required']" class="p-error block">Manager's
                                    Email is required</small>
                            </div>
                        </div>
                    </div>
                    <hr class="mb-4 mt-2">
                </div>
                <div class="col-12">
                    <div class="flex">
                        <strong class="">Attorney</strong>
                        <div class="field-checkbox ml-2">
                            <p-checkbox [binary]="true" formControlName="hasAttorny"></p-checkbox>
                            <label for="binary">Not at this time</label>
                        </div>
                    </div>
                    <div class="grid formgrid p-fluid" *ngIf="g['hasAttorny'].value == 0">
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="attorney" class="flex justify-content-start align-items-center">Attorney Name<span class="pi pi-question-circle mr-5 ml-2 cursor-pointer"
                                    (mouseenter)="showHint = 'attorney'"
                                    (mouseleave)="showHint = ''"></span></label>
                            <span class="custom-hint" *ngIf="showHint === 'attorney'">
                                You may type in this box to fit your needs, such as 'currently seeking', or 'taking
                                    meetings', etc.
                            </span>
                            <input id="attorney" type="text" placeholder="Enter Attorney" formControlName="attorney"
                                autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['attorney'].errors }" pInputText>
                            <div *ngIf="submittedContactForm && g['attorney'].errors" class="invalid-feedback">
                                <small *ngIf="g['attorney'].errors['required']" class="p-error block">Attorney is
                                    required</small>
                            </div>
                        </div>
                        <div class="col-12"></div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="attorneyPhone" class="">Attorney's Phone</label>
                            <!-- For phone number pin code  -->
                            <div class="inputtype-tel">
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15" [phoneValidation]="true" name="phone"
                                    [separateDialCode]="separateDialCode" formControlName="attorneyPhone"
                                    autocomplete="off"
                                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['attorneyPhone'].errors }">
                                </ngx-intl-tel-input>
                                <div *ngIf="submittedContactForm && g['attorneyPhone'].errors" class="invalid-feedback">
                                    <small *ngIf="g['attorneyPhone'].errors['required']"
                                        class="p-error block">Attorney's
                                        Phone is required</small>
                                    <small *ngIf="g['attorneyPhone'].errors" class="p-error block">Invalid Phone
                                        Number</small>
                                </div>
                            </div>
                            <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter Attorney's Phone"
                            formControlName="attorneyPhone" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['attorneyPhone'].errors }">
                            </p-inputMask>
                            <div *ngIf="submittedContactForm && g['attorneyPhone'].errors"
                                class="invalid-feedback">
                                <small *ngIf="g['attorneyPhone'].errors['required']" class="p-error block">Attorney's
                                    Phone is
                                    required</small>
                            </div> -->
                        </div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="attorneyEmail" class="">Attorney's Email</label>
                            <input id="attorneyEmail" type="email" placeholder="Enter Attorney's Email"
                                formControlName="attorneyEmail" autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['attorneyEmail'].errors }"
                                pInputText>
                            <div *ngIf="submittedContactForm && g['attorneyEmail'].errors" class="invalid-feedback">
                                <small *ngIf="g['attorneyEmail'].errors['required']" class="p-error block">Attorney is
                                    required</small>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-2 mb-4">
                </div>
                <div class="col-12">
                    <div class="flex">
                        <strong class="">Publicist</strong>
                        <div class="field-checkbox ml-2">
                            <p-checkbox [binary]="true" formControlName="hasPublicist"></p-checkbox>
                            <label for="binary">Not at this time</label>
                        </div>
                    </div>
                    <div class="col-12"></div>
                    <div class="grid formgrid p-fluid" *ngIf="g['hasPublicist'].value == 0">
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="publicist" class="flex justify-content-start align-items-center">Publicist
                                Name<span class="pi pi-question-circle mr-5 ml-2 cursor-pointer"
                                    (mouseenter)="showHint = 'publicistName'"
                                    (mouseleave)="showHint = ''"></span></label>
                            <span class="custom-hint" *ngIf="showHint === 'publicistName'">
                                You may type in this box to fit your needs, such as 'currently seeking', or 'taking
                                    meetings', etc.
                            </span>
                            <input id="publicist" type="text" placeholder="Enter Publicist" formControlName="publicist"
                                autocomplete="off"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['publicist'].errors }" pInputText>
                            <div *ngIf="submittedContactForm && g['publicist'].errors" class="invalid-feedback">
                                <small *ngIf="g['publicist'].errors['required']" class="p-error block">Publicist is
                                    required</small>
                            </div>
                        </div>
                        <div class="col-12"></div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="publicistPhone" class="">Publicist's Phone</label>
                            <!-- For phone number pin code  -->
                            <div class="inputtype-tel">
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15" [phoneValidation]="true" name="phone"
                                    [separateDialCode]="separateDialCode" formControlName="publicistPhone"
                                    autocomplete="off"
                                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['publicistPhone'].errors }">
                                </ngx-intl-tel-input>
                                <div *ngIf="submittedContactForm && g['publicistPhone'].errors"
                                    class="invalid-feedback">
                                    <small *ngIf="g['publicistPhone'].errors['required']"
                                        class="p-error block">Publicist's
                                        Phone is required</small>
                                    <small *ngIf="g['publicistPhone'].errors" class="p-error block">Invalid Phone
                                        Number</small>
                                </div>
                            </div>
                            <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter Publicist's Phone" autocomplete="off"
                                formControlName="publicistPhone"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['publicistPhone'].errors }">
                            </p-inputMask>
                            <div *ngIf="submittedContactForm && g['publicistPhone'].errors" class="invalid-feedback">
                                <small *ngIf="g['publicistPhone'].errors['required']" class="p-error block">Publicist's
                                    Phone is required</small>
                            </div> -->
                        </div>
                        <div class="field mb-4 col-12 lg:col-6">
                            <label for="publicistEmail" class="">Publicist's Email</label>
                            <input id="publicistEmail" type="email" placeholder="Enter Publicist's Email"
                                autocomplete="off" formControlName="publicistEmail"
                                [ngClass]="{ 'ng-dirty': submittedContactForm && g['publicistEmail'].errors }"
                                pInputText>
                            <div *ngIf="submittedContactForm && g['publicistEmail'].errors" class="invalid-feedback">
                                <small *ngIf="g['publicistEmail'].errors['required']" class="p-error block">Publicist is
                                    required</small>
                            </div>
                        </div>
                    </div>
                    <hr class="mb-4">
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="cellPhone" class="">Your Cell Phone</label>
                    <!-- For phone number pin code  -->
                    <div class="inputtype-tel">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                            [enableAutoCountrySelect]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                            [separateDialCode]="separateDialCode" formControlName="cellPhone" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedContactForm && g['cellPhone'].errors }">
                        </ngx-intl-tel-input>
                        <div *ngIf="submittedContactForm && g['cellPhone'].errors" class="invalid-feedback">
                            <small *ngIf="g['cellPhone'].errors['required']" class="p-error block">Your Cell Phone is
                                required</small>
                            <small *ngIf="g['cellPhone'].errors" class="p-error block">Invalid Phone Number</small>
                        </div>
                    </div>

                    <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter Your Cell Phone Number" 
                    autocomplete="off" formControlName="cellPhone"
                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['cellPhone'].errors }"></p-inputMask>
                    <div *ngIf="submittedContactForm && g['cellPhone'].errors" class="invalid-feedback">
                        <small *ngIf="g['cellPhone'].errors['required']" class="p-error block">Your Cell Phone is
                            required</small>
                    </div> -->
                    <!-- <input id="cellPhone" type="text" placeholder="Enter Cell Phone" pInputText> -->
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="otherPhone" class="">Other Phone</label>
                    <!-- For phone number pin code  -->
                    <div class="inputtype-tel">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                            [phoneValidation]="true" name="phone" [separateDialCode]="separateDialCode"
                            formControlName="otherPhone" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedContactForm && g['otherPhone'].errors }">
                        </ngx-intl-tel-input>
                        <div *ngIf="submittedContactForm && g['otherPhone'].errors" class="invalid-feedback">
                            <small *ngIf="g['otherPhone'].errors['required']" class="p-error block">Other Phone Number
                                is
                                required</small>
                            <small *ngIf="g['otherPhone'].errors" class="p-error block">Invalid Phone Number</small>
                        </div>
                    </div>
                    <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter Other Phone Number"
                    autocomplete="off" formControlName="otherPhone"
                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['otherPhone'].errors }"></p-inputMask>
                    <div *ngIf="submittedContactForm && g['otherPhone'].errors" class="invalid-feedback">
                        <small *ngIf="g['otherPhone'].errors['required']" class="p-error block">Other Phone Number is
                            required</small>
                    </div> -->
                    <!-- <input id="otherPhone" type="text" placeholder="Enter Other Phone" pInputText> -->
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="whatsAppNumber" class="">WhatsApp</label>
                    <!-- For phone number pin code  -->
                    <div class="inputtype-tel">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                            [phoneValidation]="true" name="phone" [separateDialCode]="separateDialCode"
                            formControlName="whatsAppNumber" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submittedContactForm && g['whatsAppNumber'].errors }">
                        </ngx-intl-tel-input>
                        <div *ngIf="submittedContactForm && g['whatsAppNumber'].errors" class="invalid-feedback">
                            <small *ngIf="g['whatsAppNumber'].errors['required']" class="p-error block">WhatsApp is
                                required</small>
                            <small *ngIf="g['whatsAppNumber'].errors" class="p-error block">Invalid Phone Number</small>
                        </div>
                    </div>
                    <!-- <p-inputMask mask="(999) 999-9999" placeholder="Enter WhatsApp"
                    formControlName="whatsAppNumber" autocomplete="off"
                    [ngClass]="{ 'ng-dirty': submittedContactForm && g['whatsAppNumber'].errors }"></p-inputMask>
                    <div *ngIf="submittedContactForm && g['whatsAppNumber'].errors" class="invalid-feedback">
                        <small *ngIf="g['whatsAppNumber'].errors['required']" class="p-error block">WhatsApp is
                            required</small>
                    </div> -->
                    <!-- <input id="whatsAppNumber" type="text" placeholder="Enter WhatsApp" pInputText> -->
                </div>
                <div class="field mb-4 col-12 lg:col-6 disable-input">
                    <label for="email" class="">Email</label>
                    <input id="email" autocomplete="off" type="text" pInputText formControlName="email">
                    <!-- <div *ngIf="submittedContactForm && g['email'].errors" class="invalid-feedback">
                    <small *ngIf="g['email'].errors['required']" class="p-error block">Email is required</small>
                </div> -->
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="website" class="">Website</label>
                    <input id="website" type="text" placeholder="Enter Website Link" formControlName="website"
                        autocomplete="off" [ngClass]="{ 'ng-dirty': submittedContactForm && g['website'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['website'].errors" class="invalid-feedback">
                        <small *ngIf="g['website'].errors['required']" class="p-error block">Website is required</small>

                    </div>
                    <div *ngIf="g['website'].errors" class="invalid-feedback">
                        <small *ngIf="g['website'].errors['pattern']" class="p-error block">Invalid URL. Hint: http://
                            or https:// is missing.
                        </small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="iMDBLink" class="">IMDb Link</label>
                    <input id="iMDBLink" type="text" placeholder="Enter IMDb Link" formControlName="imdbLink"
                        autocomplete="off" [ngClass]="{ 'ng-dirty':submittedContactForm && g['imdbLink'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['imdbLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['imdbLink'].errors['required']" class="p-error block">IMDb Link is
                            required</small>

                    </div>
                    <div *ngIf="g['imdbLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['imdbLink'].errors['pattern']" class="p-error block">Invalid URL. Hint: http://
                            or https:// is missing.
                        </small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="iMDBLink" class="">IMDbPro Link</label>
                    <input id="iMDBLink" type="text" placeholder="Enter IMDbPro Link" formControlName="imdbProLink"
                        autocomplete="off" [ngClass]="{ 'ng-dirty':submittedContactForm && g['imdbProLink'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['imdbProLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['imdbProLink'].errors['required']" class="p-error block">IMDbPro Link is
                            required</small>
                    </div>
                    <div *ngIf="g['imdbProLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['imdbProLink'].errors['pattern']" class="p-error block">Invalid URL. Hint:
                            http:// or https:// is missing.
                        </small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="youtubeLink" class="">YouTube Link</label>
                    <input id="youtubeLink" type="text" placeholder="Enter YouTube Link" formControlName="youtubeLink"
                        autocomplete="off" [ngClass]="{ 'ng-dirty':submittedContactForm && g['youtubeLink'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['youtubeLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['youtubeLink'].errors['required']" class="p-error block">YouTube Link is
                            required</small>
                    </div>
                    <div *ngIf="g['youtubeLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['youtubeLink'].errors['pattern']" class="p-error block">Invalid URL. Hint:
                            http:// or https:// is missing.
                        </small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="tiktokLink" class="">TikTok Link</label>
                    <input id="tiktokLink" type="text" placeholder="Enter TikTok Link" formControlName="tiktokLink"
                        autocomplete="off" [ngClass]="{ 'ng-dirty':submittedContactForm && g['tiktokLink'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['tiktokLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['tiktokLink'].errors['required']" class="p-error block">TikTok Link is
                            required</small>
                    </div>
                    <div *ngIf="g['tiktokLink'].errors" class="invalid-feedback">
                        <small *ngIf="g['tiktokLink'].errors['pattern']" class="p-error block">Invalid URL. Hint:
                            http:// or https:// is missing.
                        </small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="facebook" class="">Facebook</label>
                    <input id="facebook" autocomplete="off" type="text" placeholder="Enter Facebook Link"
                        formControlName="facebook"
                        [ngClass]="{ 'ng-dirty': submittedContactForm && g['facebook'].errors }" pInputText>
                    <div *ngIf="submittedContactForm && g['facebook'].errors" class="invalid-feedback">
                        <small *ngIf="g['facebook'].errors['required']" class="p-error block">Facebook is
                            required</small>

                    </div>
                    <div *ngIf="g['facebook'].errors" class="invalid-feedback">
                        <small *ngIf="g['facebook'].errors['pattern']" class="p-error block">Invalid URL. Hint: http://
                            or https:// is missing.
                        </small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="twitter" class="">X</label>
                    <input id="twitter" type="text" placeholder="Enter X Link" formControlName="twitter"
                        autocomplete="off" [ngClass]="{ 'ng-dirty': submittedContactForm && g['twitter'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['twitter'].errors" class="invalid-feedback">
                        <small *ngIf="g['twitter'].errors['required']" class="p-error block">X is required</small>

                    </div>
                    <div *ngIf="g['twitter'].errors" class="invalid-feedback">
                        <small *ngIf="g['twitter'].errors['pattern']" class="p-error block">Invalid URL. Hint: http://
                            or https:// is missing.
                        </small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="instagram" class="">Instagram</label>
                    <input id="instagram" type="text" placeholder="Enter Instagram Link" formControlName="instagram"
                        autocomplete="off" [ngClass]="{ 'ng-dirty': submittedContactForm && g['instagram'].errors }"
                        pInputText>
                    <div *ngIf="submittedContactForm && g['instagram'].errors" class="invalid-feedback">
                        <small *ngIf="g['instagram'].errors['required']" class="p-error block">Instagram is
                            required</small>

                    </div>
                    <div *ngIf="g['instagram'].errors" class="invalid-feedback">
                        <small *ngIf="g['instagram'].errors['pattern']" class="p-error block">Invalid URL. Hint: http://
                            or https:// is missing.
                        </small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="linkedin" class="">Linkedin</label>
                    <input id="linkedin" type="text" placeholder="Enter Linkedin Link" autocomplete="off"
                        formControlName="linkedin"
                        [ngClass]="{ 'ng-dirty': submittedContactForm && g['linkedin'].errors }" pInputText>
                    <div *ngIf="submittedContactForm && g['linkedin'].errors" class="invalid-feedback">
                        <small *ngIf="g['linkedin'].errors['required']" class="p-error block">Linkedin is
                            required</small>

                    </div>
                    <div *ngIf="g['linkedin'].errors" class="invalid-feedback">
                        <small *ngIf="g['linkedin'].errors['pattern']" class="p-error block">Invalid URL. Hint: http://
                            or https:// is missing.
                        </small>
                    </div>
                </div>

                <!-- Add social media external field  -->

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="actorsAccess" class="">Actors Access</label>
                    <input id="actorsAccess" type="text" placeholder="Enter Actors Access Link" autocomplete="off"
                        formControlName="actorsAccess" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="castingNetworks" class="">Casting Networks</label>
                    <input id="castingNetworks" type="text" placeholder="Enter Casting Networks Link" autocomplete="off"
                        formControlName="castingNetworks" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="spotlight" class="">Spotlight</label>
                    <input id="spotlight" type="text" placeholder="Enter Spotlight Link" autocomplete="off"
                        formControlName="spotlight" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="castingFrontier" class="">Casting Frontier</label>
                    <input id="castingFrontier" type="text" placeholder="Enter Casting Frontier Link" autocomplete="off"
                        formControlName="castingFrontier" pInputText>
                </div>
                <!-- <div class="field mb-4 col-12 lg:col-6">
                    <label for="nyCastings" class="">NYCastings</label>
                    <input id="nyCastings" type="text" placeholder="Enter NYCastings Link" autocomplete="off"
                        formControlName="nyCastings" pInputText>
                </div> -->
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="applePodcasts" class="">Apple Podcasts</label>
                    <input id="applePodcasts" type="text" placeholder="Enter Apple Podcasts Link" autocomplete="off"
                        formControlName="applePodcasts" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="spotify" class="">Spotify</label>
                    <input id="spotify" type="text" placeholder="Enter Spotify Link" autocomplete="off"
                        formControlName="spotify" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="threads" class="">Threads</label>
                    <input id="threads" type="text" placeholder="Enter Threads Link" autocomplete="off"
                        formControlName="threads" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="showcast" class="">Showcast</label>
                    <input id="showcast" type="text" placeholder="Enter Showcast Link" autocomplete="off"
                        formControlName="showcast" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="workbook" class="">Workbook</label>
                    <input id="workbook" type="text" placeholder="Enter Workbook Link" autocomplete="off"
                        formControlName="workbook" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="enCast" class="">enCast</label>
                    <input id="enCast" type="text" placeholder="Enter enCast Link" autocomplete="off"
                        formControlName="enCast" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="youTubePodcasts" class="">YouTube Podcasts</label>
                    <input id="youTubePodcasts" type="text" placeholder="Enter YouTube Podcasts Link" autocomplete="off"
                        formControlName="youTubePodcasts" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="amazonMusic" class="">Amazon Music</label>
                    <input id="amazonMusic" type="text" placeholder="Enter Amazon Music Link" autocomplete="off"
                        formControlName="amazonMusic" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="amazonPodcasts" class="">Amazon Podcasts</label>
                    <input id="amazonPodcasts" type="text" placeholder="Enter Amazon Podcast Link" autocomplete="off"
                        formControlName="amazonPodcasts" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="castBox" class="">CastBox</label>
                    <input id="castBox" type="text" placeholder="Enter CastBox Link" autocomplete="off"
                        formControlName="castBox" pInputText>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="spotifyPodcast" class="">Spotify Podcasts</label>
                    <input id="spotifyPodcast" type="text" placeholder="Enter Spotify Podcast Link" autocomplete="off"
                        formControlName="spotifyPodcast" pInputText>
                </div>


                <!-- Save details of form on click saveContactDetails -->
                <div class="col-12">
                    <button pButton pRipple label="Save & Next" (click)="saveContactDetails()" class="w-auto"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="" *ngIf="activeTab == 2">
    <div class="surface-card p-4 shadow-2 border-round-sm">
        <!-- professionalForm formGroup used -->
        <form [formGroup]="professionalForm">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="loanOutCompany" class="">Loan Out Company</label>
                    <input id="loanOutCompany" type="text" placeholder="Enter Loan Out Company" autocomplete="off"
                        formControlName="loanOutCompany"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['loanOutCompany'].errors }" pInputText>
                    <div *ngIf="submittedProfessionalForm && h['loanOutCompany'].errors" class="invalid-feedback">
                        <small *ngIf="h['loanOutCompany'].errors['required']" class="p-error block">Loan Out Company is
                            required</small>
                    </div>
                </div>
                <div class="col-12"></div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="union" class="">Unions & Guilds <small class="text-500"><i>Check all that
                                apply</i></small></label><br>
                    <p-radioButton value="1" formControlName="unionsAndGuilds" inputId="1" class="mr-3" label="Union">
                    </p-radioButton>
                    <p-radioButton value="0" formControlName="unionsAndGuilds" inputId="0" class="mr-3"
                        label="Non-Union">
                    </p-radioButton>

                </div>
                <div class="col-12"></div>
                <!-- <div class="field mb-4 col-12 lg:col-6" *ngIf="h['unionsAndGuilds'].value == 0">
                    <label for="dayRate" class="">Non-Union Day Rate</label>
                    <p-dropdown formControlName="dayRate" [showClear]="true" optionLabel="label" [options]="fieldsList['dayRate']"
                        optionValue="value" [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['dayRate'].errors }"
                        placeholder="Select a Non Union Day Rate"></p-dropdown>
                    <div *ngIf="submittedProfessionalForm && h['dayRate'].errors" class="invalid-feedback">
                        <small *ngIf="h['dayRate'].errors['required']" class="p-error block">Non Union Day Rate is
                            required</small>
                    </div>
                </div> -->

                <div class="field mb-4 col-12 lg:col-6" *ngIf="h['unionsAndGuilds'].value == 1">
                    <label for="union" class="">Union</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['union']" optionValue="value"
                        formControlName="union"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['union'].errors }"
                        placeholder="Select Union"></p-multiSelect>
                    <div *ngIf="submittedProfessionalForm && h['union'].errors" class="invalid-feedback">
                        <small *ngIf="h['union'].errors['required']" class="p-error block">Union is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6" *ngIf="h['unionsAndGuilds'].value == 1">
                    <label for="otherCrewUnion" class="flex justify-content-start align-items-center">Other Crew
                        Unions<span class="pi pi-question-circle mr-5 ml-2 cursor-pointer"
                            (mouseenter)="showHint = 'otherCrewUnion'" (mouseleave)="showHint = ''"></span></label>
                    <span class="custom-hint" *ngIf="showHint === 'otherCrewUnion'">
                        Please type your response in text box.
                    </span>
                    <input pInputText type="text" formControlName="otherCrewUnion" placeholder="Enter Other Crew Union">
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="willTravel" class="">Will Travel</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['willTravel']"
                        optionValue="value" formControlName="willTravel" [filter]="true" filterBy="label"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['willTravel'].errors }"
                        placeholder="Select a Will Travel"></p-dropdown>
                    <div *ngIf="submittedProfessionalForm && h['willTravel'].errors" class="invalid-feedback">
                        <small *ngIf="h['willTravel'].errors['required']" class="p-error block">Will Travel is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="visa" class="">Visa & Work Permit</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['visa']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="visa"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['visa'].errors }"
                        placeholder="Select a Visa & Work Permit"></p-multiSelect>
                    <!-- <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['visa']"
                        optionValue="value" formControlName="visa"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['visa'].errors }" [filter]="true"
                        filterBy="label" placeholder="Select a Visa & Work Permit"></p-dropdown> -->
                    <div *ngIf="submittedProfessionalForm && h['visa'].errors" class="invalid-feedback">
                        <small *ngIf="h['visa'].errors['required']" class="p-error block">Visa & Work Permit is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="passport" class="">Passport</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['passport']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="passport"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['passport'].errors }"
                        placeholder="Select passport"></p-multiSelect>
                    <div *ngIf="submittedProfessionalForm && h['passport'].errors" class="invalid-feedback">
                        <small *ngIf="h['passport'].errors['required']" class="p-error block">passport is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="kit" class="">Has Kit/Gear</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['hasKitOrGear']"
                        optionValue="value" formControlName="hasKitOrGear"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['hasKitOrGear'].errors }"
                        placeholder="Select Kit/Gear"></p-dropdown>
                    <div *ngIf="submittedProfessionalForm && h['hasKitOrGear'].errors" class="invalid-feedback">
                        <small *ngIf="h['hasKitOrGear'].errors['required']" class="p-error block">Kit/Gear is
                            required</small>
                    </div>
                </div>

                <div class="col-12"></div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="filmBudget" class="">Budget of My Projects</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['filmBudget']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="filmBudget"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['filmBudget'].errors }"
                        placeholder="Select Budget of My Projects"></p-multiSelect>
                    <!-- <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['filmBudget']"
                        optionValue="value" [filter]="true" filterBy="label" formControlName="filmBudget"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['filmBudget'].errors }"
                        placeholder="Select Budget of My Projects"></p-dropdown> -->
                    <div *ngIf="submittedProfessionalForm && h['filmBudget'].errors" class="invalid-feedback">
                        <small *ngIf="h['filmBudget'].errors['required']" class="p-error block">Budget of My Projects is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="workingLocations" class="">Work Locations</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['workingLocations']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="workingLocations"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['workingLocations'].errors }"
                        placeholder="Select Work Locations"></p-multiSelect>
                    <!-- <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['workingLocations']"
                        optionValue="value" [filter]="true" filterBy="label" formControlName="workingLocations"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['workingLocations'].errors }"
                        placeholder="Select Work Locations"></p-dropdown> -->
                    <div *ngIf="submittedProfessionalForm && h['workingLocations'].errors" class="invalid-feedback">
                        <small *ngIf="h['workingLocations'].errors['required']" class="p-error block">Work Locations is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="specialTalents" class="">Special Talents</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['specialTalents']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="specialTalents"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['specialTalents'].errors }"
                        placeholder="Select Special Talents"></p-multiSelect>
                    <div *ngIf="submittedProfessionalForm && h['specialTalents'].errors" class="invalid-feedback">
                        <small *ngIf="h['specialTalents'].errors['required']" class="p-error block">Special Talents is
                            required</small>
                    </div>
                </div>

                <div class="col-12"></div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="additionalInformation" class="">Additional Information</label>
                    <textarea pInputTextarea rows="5" cols="30" autocomplete="off"
                        formControlName="additionalInformation"
                        [ngClass]="{ 'ng-dirty': submittedProfessionalForm && h['additionalInformation'].errors }"
                        pInputTextarea></textarea>
                    <div *ngIf="submittedProfessionalForm && h['additionalInformation'].errors"
                        class="invalid-feedback">
                        <small *ngIf="h['additionalInformation'].errors['required']" class="p-error block">Additional
                            Information is required</small>
                    </div>
                </div>
                <!-- Save professional Form details on click saveProfessionalDetails -->
                <div class="col-12">
                    <button pButton pRipple label="Save & Next" (click)="saveProfessionalDetails()"
                        class="w-auto"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="" *ngIf="activeTab == 3">
    <div class="surface-card p-4 shadow-2 border-round-sm">
        <!-- skillAndTrainings Form formGroup used from here-->
        <form [formGroup]="skillAndTrainingsForm">
            <div class="grid formgrid p-fluid">

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="actorTraining" class="">Actor Training</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['actorTraining']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="actorTraining"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['actorTraining'].errors }"
                        placeholder="Select Actor Training"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['actorTraining'].errors" class="invalid-feedback">
                        <small *ngIf="i['actorTraining'].errors['required']" class="p-error block">Actor Training is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="vocalRange" class="">Vocal Range</label>
                    <!-- <input formControlName="vocalRange"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['vocalRange'].errors }" pInputText
                        placeholder="Select Vocal Range" (click)="showTable('Vocal Range','vocalRange')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['vocalRange']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="vocalRange"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['vocalRange'].errors }"
                        placeholder="Select Vocal Range"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['vocalRange'].errors" class="invalid-feedback">
                        <small *ngIf="i['vocalRange'].errors['required']" class="p-error block">Vocal Range is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="dance" class="">Dance</label>
                    <!-- <input formControlName="dance"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['dance'].errors }" pInputText
                        placeholder="Select Dance" (click)="showTable('Dance','dance')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['dance']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="dance"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['dance'].errors }"
                        placeholder="Select Dance"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['dance'].errors" class="invalid-feedback">
                        <small *ngIf="i['dance'].errors['required']" class="p-error block">Dance is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="Bands" class="">Bands & Instruments</label>
                    <!-- <input formControlName="bands"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['bands'].errors }" pInputText
                        placeholder="Select Bands & Instruments" (click)="showTable('Bands','bands')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['bands']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="bands"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['bands'].errors }"
                        placeholder="Select Bands & Instruments"></p-multiSelect>


                    <div *ngIf="submittedSkillAndTrainingsForm && i['bands'].errors" class="invalid-feedback">
                        <small *ngIf="i['bands'].errors['required']" class="p-error block">Bands & Instruments is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="cycling" class="">Cycling</label>
                    <!-- <input formControlName="cycling"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['cycling'].errors }" pInputText
                        placeholder="Select Cycling" (click)="showTable('Cycling','cycling')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['cycling']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="cycling"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['cycling'].errors }"
                        placeholder="Select Cycling"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['cycling'].errors" class="invalid-feedback">
                        <small *ngIf="i['cycling'].errors['required']" class="p-error block">Cycling is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="militaryWeaponsAndFirearmsTraining" class="">Military, Weapons & Firearms
                        Training</label>
                    <!-- <input formControlName="militaryWeaponsAndFirearmsTraining"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['militaryWeaponsAndFirearmsTraining'].errors }"
                        pInputText placeholder="Select Military, Weapons & Firearms"
                        (click)="showTable('Weapons Trainings','militaryWeaponsAndFirearmsTraining')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['militaryWeaponsAndFirearmsTraining']"
                        optionValue="value" [filter]="true" filterBy="label"
                        formControlName="militaryWeaponsAndFirearmsTraining"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['militaryWeaponsAndFirearmsTraining'].errors }"
                        placeholder="Select Military, Weapons & Firearms"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['militaryWeaponsAndFirearmsTraining'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['militaryWeaponsAndFirearmsTraining'].errors['required']"
                            class="p-error block">Military, Weapons & Firearms
                            is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 lg:col-6">
                    <label for="gymnastic" class="">Gymnastics</label>
                    <!-- <input formControlName="gymnastic"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['gymnastic'].errors }" pInputText
                        placeholder="Select Gymnastics" (click)="showTable('Gymnastics','gymnastic')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['gymnastic']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="gymnastic"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['gymnastic'].errors }"
                        placeholder="Select Gymnastics"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['gymnastic'].errors" class="invalid-feedback">
                        <small *ngIf="i['gymnastic'].errors['required']" class="p-error block">Gymnastics is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="languages" class="">Languages</label>
                    <!-- <input formControlName="languages"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['languages'].errors }" pInputText
                        placeholder="Select Languages" (click)="showTable('Languages','languages')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['languages']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="languages"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['languages'].errors }"
                        placeholder="Select Languages"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['languages'].errors" class="invalid-feedback">
                        <small *ngIf="i['languages'].errors['required']" class="p-error block">Languages is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="hobbies" class="">Hobbies</label>
                    <!-- <input formControlName="hobbies"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['hobbies'].errors }" pInputText
                        placeholder="Select Hobbies" (click)="showTable('Hobbies','hobbies')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['hobbies']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="hobbies"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['hobbies'].errors }"
                        placeholder="Select Hobbies"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['hobbies'].errors" class="invalid-feedback">
                        <small *ngIf="i['hobbies'].errors['required']" class="p-error block">Hobbies is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="outdoorSports" class="">Outdoor & Recreational Sports</label>
                    <!-- <input formControlName="outdoorSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['outdoorSports'].errors }"
                        pInputText placeholder="Select Outdoor & Recreational Sports"
                        (click)="showTable('Outdoor & Recreational Sports','outdoorSports')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['outdoorSports']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="outdoorSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['outdoorSports'].errors }"
                        placeholder="Select Outdoor & Recreational Sports"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['outdoorSports'].errors" class="invalid-feedback">
                        <small *ngIf="i['outdoorSports'].errors['required']" class="p-error block">Outdoor &
                            Recreational Sports is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="rodeo" class="">Rodeo</label>
                    <!-- <input formControlName="rodeo"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['rodeo'].errors }" pInputText
                        placeholder="Select Rodeo" (click)="showTable('Rodeo','rodeo')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['rodeo']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="rodeo"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['rodeo'].errors }"
                        placeholder="Select Rodeo"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['rodeo'].errors" class="invalid-feedback">
                        <small *ngIf="i['rodeo'].errors['required']" class="p-error block">Rodeo is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="racquetAndPaddleSports " class="">Racquet & Paddle Sports </label>
                    <!-- <input formControlName="racquetAndPaddleSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['racquetAndPaddleSports'].errors }"
                        pInputText placeholder="Select Racquet & Paddle Sports"
                        (click)="showTable('Racquet & Paddle Sports','racquetAndPaddleSports')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['racquetAndPaddleSports']"
                        optionValue="value" [filter]="true" filterBy="label" formControlName="racquetAndPaddleSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['racquetAndPaddleSports'].errors }"
                        placeholder="Select Racquet & Paddle Sports"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['racquetAndPaddleSports'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['racquetAndPaddleSports'].errors['required']" class="p-error block">Racquet &
                            Paddle Sports is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="teamSports" class="">Team Sports</label>
                    <!-- <input formControlName="teamSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['teamSports'].errors }" pInputText
                        placeholder="Select Team Sports" (click)="showTable('Team Sports','teamSports')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['teamSports']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="teamSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['teamSports'].errors }"
                        placeholder="Select Team Sports"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['teamSports'].errors" class="invalid-feedback">
                        <small *ngIf="i['teamSports'].errors['required']" class="p-error block">Team Sports is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="singingStyles" class="">Singing Styles</label>
                    <!-- <input formControlName="singingStyles"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['singingStyles'].errors }"
                        pInputText placeholder="Select Singing Styles"
                        (click)="showTable('Singing Styles','singingStyles')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['singingStyles']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="singingStyles"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['singingStyles'].errors }"
                        placeholder="Select Singing Styles"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['singingStyles'].errors" class="invalid-feedback">
                        <small *ngIf="i['singingStyles'].errors['required']" class="p-error block">Singing Styles is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="circusMagicClowning" class="">Circus, Magic, Clowning</label>
                    <!-- <input formControlName="circusMagicClowning"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['circusMagicClowning'].errors }"
                        pInputText placeholder="Select Circus, Magic, Clowning"
                        (click)="showTable('Circus, Magic, Clowning','circusMagicClowning')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['circusMagicClowning']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="circusMagicClowning"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['circusMagicClowning'].errors }"
                        placeholder="Select Circus, Magic, Clowning"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['circusMagicClowning'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['circusMagicClowning'].errors['required']" class="p-error block">Circus, Magic,
                            Clowning is required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="extremeWinterSportsAndWaterSports" class="">Extreme Winter Sports & Water
                        Sports</label>
                    <!-- <input formControlName="extremeWinterSportsAndWaterSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['extremeWinterSportsAndWaterSports'].errors }"
                        pInputText placeholder="Select Extreme Winter Sports & Water Sports"
                        (click)="showTable('Extreme Winter Sports & Water Sports','extremeWinterSportsAndWaterSports')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['extremeWinterSportsAndWaterSports']"
                        optionValue="value" [filter]="true" filterBy="label"
                        formControlName="extremeWinterSportsAndWaterSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['extremeWinterSportsAndWaterSports'].errors }"
                        placeholder="Select Extreme Winter Sports & Water Sports"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['extremeWinterSportsAndWaterSports'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['extremeWinterSportsAndWaterSports'].errors['required']"
                            class="p-error block">Extreme Winter Sports & Water Sports is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="equestrianSports" class="">Equestrian</label>
                    <!-- <input formControlName="equestrianSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['equestrianSports'].errors }"
                        pInputText placeholder="Select Equestrian" (click)="showTable('Equestrian','equestrianSports')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['equestrianSports']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="equestrianSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['equestrianSports'].errors }"
                        placeholder="Select Equestrian"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['equestrianSports'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['equestrianSports'].errors['required']" class="p-error block">Equestrian is
                            required</small>
                    </div>
                </div>


                <div class="field mb-4 col-12 lg:col-6">
                    <label for="dialects" class="">Dialects</label>
                    <!-- <input formControlName="dialects"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['dialects'].errors }" pInputText
                        placeholder="Select Dialects" (click)="showTable('Dialects','dialects')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['dialects']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="dialects"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['dialects'].errors }"
                        placeholder="Select Dialects"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['dialects'].errors" class="invalid-feedback">
                        <small *ngIf="i['dialects'].errors['required']" class="p-error block">Dialects is
                            required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="driversPilotsCerificatesTransportation" class="">Drivers, Pilots,
                        Cerificates, Transportation</label>
                    <!-- <input formControlName="driversPilotsCerificatesTransportation"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['driversPilotsCerificatesTransportation'].errors }"
                        pInputText placeholder="Select Drivers, Pilots, Cerificates, Transportation"
                        (click)="showTable('Drivers, Pilots, Cerificates, Transportation','driversPilotsCerificatesTransportation')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['driversPilotsCerificatesTransportation']"
                        optionValue="value" [filter]="true" filterBy="label"
                        formControlName="driversPilotsCerificatesTransportation"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['driversPilotsCerificatesTransportation'].errors }"
                        placeholder="Select Drivers, Pilots, Cerificates, Transportation"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['driversPilotsCerificatesTransportation'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['driversPilotsCerificatesTransportation'].errors['required']"
                            class="p-error block">Drivers, Pilots, Cerificates, Transportation is required</small>
                    </div>
                </div>


                <div class="field mb-4 col-12 lg:col-6">
                    <label for="indoorAndOutdoorSports" class="">Indoor & Outdoor Sports</label>
                    <!-- <input formControlName="indoorAndOutdoorSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['indoorAndOutdoorSports'].errors }"
                        pInputText placeholder="Select Indoor & Outdoor Sports"
                        (click)="showTable('Indoor & Outdoor Sports','indoorAndOutdoorSports')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['indoorAndOutdoorSports']"
                        optionValue="value" [filter]="true" filterBy="label" formControlName="indoorAndOutdoorSports"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['indoorAndOutdoorSports'].errors }"
                        placeholder="Select Indoor & Outdoor Sports"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['indoorAndOutdoorSports'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['indoorAndOutdoorSports'].errors['required']" class="p-error block">Indoor &
                            Outdoor Sports is required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 lg:col-6">
                    <label for="stuntsMovesCombatMartialArts" class="">Stunts, Moves, Combat, Martial
                        Arts</label>
                    <!-- <input formControlName="stuntsMovesCombatMartialArts"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['stuntsMovesCombatMartialArts'].errors }"
                        pInputText placeholder="Select Stunts, Moves, Combat, Martial Arts"
                        (click)="showTable('Stunts, Moves, Combat, Martial Arts','stuntsMovesCombatMartialArts')"> -->

                    <p-multiSelect optionLabel="label" [options]="fieldsList['stuntsMovesCombatMartialArts']"
                        optionValue="value" [filter]="true" filterBy="label"
                        formControlName="stuntsMovesCombatMartialArts"
                        [ngClass]="{ 'ng-dirty': submittedSkillAndTrainingsForm && i['stuntsMovesCombatMartialArts'].errors }"
                        placeholder="Select Stunts, Moves, Combat, Martial Arts"></p-multiSelect>

                    <div *ngIf="submittedSkillAndTrainingsForm && i['stuntsMovesCombatMartialArts'].errors"
                        class="invalid-feedback">
                        <small *ngIf="i['stuntsMovesCombatMartialArts'].errors['required']"
                            class="p-error block">Stunts, Moves, Combat, Martial Arts is required</small>
                    </div>
                </div>
                <!-- Save details of form on click saveSkillAndTrainingDetails -->
                <div class="col-12">
                    <button pButton pRipple label="Save & Next" (click)="saveSkillAndTrainingDetails()"
                        class="w-auto"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="" *ngIf="activeTab == 4">
    <div class="surface-card p-4 shadow-2 border-round-sm">
        <!-- attributes Form formGroup used -->
        <form [formGroup]="attributesForm">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 md:col-6">
                    <label for="height" class="">Height (ft inch)</label>
                    <p-dropdown formControlName="height" [showClear]="true" optionLabel="label" [options]="heights"
                        optionValue="label" [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['height'].errors }"
                        placeholder="Select Height"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['height'].errors" class="invalid-feedback">
                        <small *ngIf="j['height'].errors['required']" class="p-error block">Height is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="weight" class="">Weight (lbs)</label>
                    <p-dropdown formControlName="weight" [showClear]="true" optionLabel="label"
                        [options]="fieldsList['weight']" optionValue="value"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['weight'].errors }"
                        placeholder="Select Weight"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['weight'].errors" class="invalid-feedback">
                        <small *ngIf="j['weight'].errors['required']" class="p-error block">Weight is required</small>
                    </div>
                </div>

                <div class="field mb-4 col-12 md:col-6">
                    <label for="eyeColor" class="">Eye Color</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['eyeColor']"
                        optionValue="value" formControlName="eyeColor"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['eyeColor'].errors }"
                        placeholder="Select a Eye Color"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['eyeColor'].errors" class="invalid-feedback">
                        <small *ngIf="j['eyeColor'].errors['required']" class="p-error block">Eye Color is
                            required</small>
                    </div>

                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="tattoo" class="">Tattoo</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['tattoo']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="tattoo"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['tattoo'].errors }"
                        placeholder="Select a Tattoo"></p-multiSelect>
                    <!-- <p-dropdown optionLabel="label" [showClear]="true" [showClear]="true"
                        [options]="fieldsList['tattoo']" optionValue="value" formControlName="tattoo"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['tattoo'].errors }"
                        placeholder="Select a Tattoo"></p-dropdown> -->
                    <div *ngIf="submittedAttributeForm && j['tattoo'].errors" class="invalid-feedback">
                        <small *ngIf="j['tattoo'].errors['required']" class="p-error block">Tattoo is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="pregnant" class="">Pregnant</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['pregnant']"
                        optionValue="value" formControlName="pregnant"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['pregnant'].errors }"
                        placeholder="Select a Pregnant"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['pregnant'].errors" class="invalid-feedback">
                        <small *ngIf="j['pregnant'].errors['required']" class="p-error block">Pregnant is
                            required</small>
                    </div>
                </div>
                <div class="field mb-0 col-12 lg:col-6" *ngIf="j['pregnant'].value == 2">
                    <label for="dueDateFrom" class="">Due Date</label>
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12 md:col-6">
                            <p-calendar placeholder="From" (onSelect)="duePick($event)" formControlName="dueDateFrom"
                                [showIcon]="true"
                                [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['dueDateFrom'].errors }">
                            </p-calendar>
                            <div *ngIf="submittedAttributeForm && j['dueDateFrom'].errors" class="invalid-feedback">
                                <small *ngIf="j['dueDateFrom'].errors['required']" class="p-error block">From Date is
                                    required</small>
                            </div>
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                            <p-calendar placeholder="To" (onSelect)="toPick($event)" [showIcon]="true"
                                formControlName="dueDateTo"
                                [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['dueDateTo'].errors }">
                            </p-calendar>
                            <div *ngIf="submittedAttributeForm && j['dueDateTo'].errors" class="invalid-feedback">
                                <small *ngIf="j['dueDateTo'].errors['required']" class="p-error block">To Date is
                                    required</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="piercing" class="">Piercing</label>
                    <p-multiSelect optionLabel="label" [options]="fieldsList['piercing']" optionValue="value"
                        [filter]="true" filterBy="label" formControlName="piercing"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['piercing'].errors }"
                        placeholder="Select a Piercing"></p-multiSelect>
                    <!-- <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['piercing']"
                        optionValue="value" formControlName="piercing"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['piercing'].errors }"
                        placeholder="Select a Piercing"></p-dropdown> -->
                    <div *ngIf="submittedAttributeForm && j['piercing'].errors" class="invalid-feedback">
                        <small *ngIf="j['piercing'].errors['required']" class="p-error block">Piercing is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="smoking" class="">Smoking</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['smoking']"
                        optionValue="value" formControlName="smoking"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['smoking'].errors }"
                        placeholder="Select a Smoking"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['smoking'].errors" class="invalid-feedback">
                        <small *ngIf="j['smoking'].errors['required']" class="p-error block">Smoking is required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="hairColor" class="">Hair Color</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['hairColor']"
                        optionValue="value" formControlName="hairColor" [filter]="true" filterBy="label"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['hairColor'].errors }"
                        placeholder="Select a Hair Color"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['hairColor'].errors" class="invalid-feedback">
                        <small *ngIf="j['hairColor'].errors['required']" class="p-error block">Hair Color is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="hairLength" class="">Hair Length</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['hairLength']"
                        optionValue="value" formControlName="hairLength" [filter]="true" filterBy="label"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['hairLength'].errors }"
                        placeholder="Select a Hair Length"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['hairLength'].errors" class="invalid-feedback">
                        <small *ngIf="j['hairLength'].errors['required']" class="p-error block">Hair Length is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="facialHair" class="">Facial Hair</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['facialHair']"
                        optionValue="value" formControlName="facialHair" [filter]="true" filterBy="label"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['facialHair'].errors }"
                        placeholder="Select a Facial Hair"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['facialHair'].errors" class="invalid-feedback">
                        <small *ngIf="j['facialHair'].errors['required']" class="p-error block">Facial Hair is
                            required</small>
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="complexion" class="">Complexion</label>
                    <p-dropdown optionLabel="label" [showClear]="true" [options]="fieldsList['complexion']"
                        optionValue="value" formControlName="complexion" [filter]="true" filterBy="label"
                        [ngClass]="{ 'ng-dirty': submittedAttributeForm && j['complexion'].errors }"
                        placeholder="Select a Complexion"></p-dropdown>
                    <div *ngIf="submittedAttributeForm && j['complexion'].errors" class="invalid-feedback">
                        <small *ngIf="j['complexion'].errors['required']" class="p-error block">Complexion is
                            required</small>
                    </div>
                </div>
                <!-- Save attributes Form details on click saveattributeDetails -->
                <div class="col-12">
                    <button pButton pRipple label="Save & Next" (click)="saveattributeDetails()"
                        class="w-auto"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="" *ngIf="activeTab == 5">
    <div class="surface-card p-4 shadow-2 border-round-sm">
        <!-- equipment Form formGroup used  -->
        <form [formGroup]="equipmentForm">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 lg:col-12">
                    <label for="equipment" class="">Other Contributions</label>
                    <textarea id="equipment" type="text" pInputTextarea rows="5" [autoResize]="true" autocomplete="off"
                        formControlName="equipment"
                        [ngClass]="{ 'ng-dirty': submittedequipment && k['equipment'].errors }"></textarea>
                    <div *ngIf="submittedequipment && k['equipment'].errors" class="invalid-feedback">
                        <small *ngIf="k['equipment'].errors['required']" class="p-error block">Text is required</small>
                    </div>
                </div>
                <!-- Save details of equipment Form on click saveequipmentDetails -->
                <div class="col-12">
                    <button pButton pRipple label="Finish" (click)="saveequipmentDetails()" class="w-auto"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- for alert msg -->
<p-toast position="top-right"></p-toast>
<!-- Confirm dialog box  -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
    <ng-template pTemplate="footer">
        <button type="button" pButton class="p-button-danger" icon="pi pi-times" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton class="p-button-success" icon="pi pi-check" label="Yes"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>

<!-- General Sports -->
<!-- <p-dialog header="{{displayName}}" [(visible)]="display" [style]="{width: '50vw'}">
    <div class="border-top-2 surface-border"></div>
    <section class="container py-4">
        <div class="container-fluid">
            <p-table #dt [value]="displayValue" expandableRows='true' [responsive]='true' dataKey='' [lazy]="false"
                [scrollable]="true" responsiveLayout="stack" [globalFilterFields]="['label']"  [loading]="loading" >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control" (input)="dt.filterGlobal($any($event.target).value, 'contains')"  placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th>Sports Name</th>
                        <th>Expertise Level</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr>
                        <td>
                            <p-checkbox (onChange)="trainningCheck($event,rowIndex,item)" name="{{item.label}}"
                                [(ngModel)]="selectedValueCheck" value="{{item.value}}" label="{{item.label}}">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-radioButton name="{{item.label}}_{{item.value}}" value="0" [disabled]="selectedList.indexOf(item.value) == -1"
                                [(ngModel)]="selectedValueRadio[item.value]" class="mr-3" label="Beginner">
                            </p-radioButton>
                            <p-radioButton name="{{item.label}}_{{item.value}}" value="1" [disabled]="selectedList.indexOf(item.value) == -1"
                                [(ngModel)]="selectedValueRadio[item.value]" class="mr-3" label="Intermediate">
                            </p-radioButton>
                            <p-radioButton name="{{item.label}}_{{item.value}}" value="2" [disabled]="selectedList.indexOf(item.value) == -1"
                                [(ngModel)]="selectedValueRadio[item.value]" class="mr-3" label="Expert">
                            </p-radioButton>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </section>
    <div class="text-right">
        <p-button label="Confirm" class="p-button-secondery" (click)="closeDialog(displayName)"></p-button>
    </div>
</p-dialog> -->