import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../user.service';
import { log } from 'console';

@Component({
  selector: 'app-crew-change-password',
  templateUrl: './crew-change-password.component.html',
  styleUrls: ['./crew-change-password.component.scss']
})
export class CrewChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  errors: any;
  submitted1: boolean = false;
  currentPassword: any;
  confirmPassword: any
  pageLoader: boolean = false;
  showOldPassword: boolean = false;
  showPassword = false;
  showConnPassword = false;
  oldPassword: string = 'password';
  password: string = 'password';
  connPassword: string = 'password';
  /**
  * @constructor
  * @param {UserService} userservice - The userservice service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {FormBuilder} formBuilder -  FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.
  */
  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private userservice: UserService
  ) {
    // Form declaration
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$/)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    },
      { validator: this.checkPasswords })
  }

  ngOnInit(): void {

  }

  // For form controls
  get f() { return this.changePasswordForm.controls; }

  // For password check and match
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    return pass === confirmPass ? null : { notSame: true }
  }

  
/**
 * Function to handle password visibility toggle
 * @method onClick
 * @param {number} id - The id of the password field to toggle (1 for currentPassword, 2 for password, 3 for confirmPassword)
 * @returns {void}
 */
  onClick(id: number) {
    if (id == 1) {
      this.showOldPassword = !this.showOldPassword;
      this.oldPassword = this.showOldPassword ? 'text' : 'password';
    }
    if (id == 2) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
    if (id == 3) {
      this.showConnPassword = !this.showConnPassword;
      this.connPassword = this.showConnPassword ? 'text' : 'password';
    }
  }

  /**
   * Function for change password
   * @method changePassword
   * @returns { Object } Object
   */
  changePassword() {
    this.submitted1 = true;
    if (this.changePasswordForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      })
      return;
    }
    this.userservice.changePassword(this.changePasswordForm.value).subscribe((data) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Record updated successfully.'
      });
      this.changePasswordForm.reset(); // To reset input field.
      this.submitted1 = false;
    }, (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    }
    )

  }

}
