import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http:HttpClient
  ) 
  { }
  //Delete profile 
  deleteProfile(){
    return this.http.delete(environment.apiUrl+`users`)
  }
  //Get user data info
  getUserData(){
    return this.http.get(environment.apiUrl+`crews/me`);
  }
}
