import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';



const routes: Routes = [

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: "CrewResumes" }
  },
  {
    path: 'home', 
    component: HomeComponent,
    data: { title: "CrewResumes" },
    canActivate: [AuthGuard]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonRoutingModule { }
