import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupService } from './signup.service';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  features: any[] = [];
  signupForm: FormGroup;
  otpForm: FormGroup;
  submitted1: boolean = false;
  submitted3: boolean = false;
  signupDiv = true
  otpDiv = false
  mask: boolean = false
  dob: any
  ResendOTPDiv: boolean = false
  timerDiv: boolean = false
  timeLeft: number = 60;
  interval: any;
  isdisabled: any = false
  maxDate: any
  date1: Date | undefined;
  endDateFilter!: Date | null;
  display5: boolean = false;
  showPassword = false;
  showConnPassword = false;
  password: string = 'password';
  connPassword: string = 'password';
  newsletterSubscribed: boolean = true;
  termsAgreed: boolean = true
  /**
  * @constructor
  * @param {SignupService} signupService - The signupService service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {Router} router - For getting router details.
  * @param {FormBuilder} formBuilder - FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.
  * @param {AuthGuard} auth - AuthGuard is used to protect the routes from unauthorized access in angular.
  */
  constructor(
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private auth: AuthGuard,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    let today = new Date();
    let month = today.getMonth();
    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    // Form declaration
    this.signupForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$/)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      termsAgreed: new FormControl(true, Validators.required),
      newsletterSubscribed: new FormControl('')
    },
      { validator: this.checkPasswords }
    )

    // Form declaration
    this.otpForm = this.formBuilder.group({
      otp: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  // For password and confirm password check and match
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnInit(): void {
    //   this.features = [
    //     { title: 'Please provide important text', image: 'live-collaboration.svg', text: 'Please provide content' },
    //     { title: 'Please provide important text', image: 'security.svg', text: 'Please provide content' },
    //     { title: 'Please provide important text', image: 'subscribe.svg', text: 'Please provide content' }
    // ];
  }

  // For form controls
  get f() { return this.signupForm.controls; }
  get h() { return this.otpForm.controls; }

  // For date formating in dob input field
  dobPick(event: any) {
    this.dob = moment(event).format('YYYY-MM-DD')
    //this.signupForm.controls["dob"].setValue(dob);
  }

  onKeyDate(event: any) {
    //console.log( event.target.value)
    // event.target.value = event.target.value.pattern(/^((0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](19|20)[0-9]{2})*$/);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  /**
   * Toggles the visibility of the password input field.
   * @param {number} id - The id of the input field to toggle.
   */
  onClick(id: number) {
    if (id == 1) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
    if (id == 2) {
      this.showConnPassword = !this.showConnPassword;
      this.connPassword = this.showConnPassword ? 'text' : 'password';
    }
  }
  redirect(name: any) {
    window.location.href =
      environment.rootUrl + name;
  }

  /**
  * Function for sign up.
  * @method signUp
  * @returns { Object } Object
  */
  signUp() {
    this.submitted1 = true;
    if (this.signupForm.invalid ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return;
    }
    let dob = moment(this.signupForm.value.dob).format('YYYY-MM-DD')
    if (dob == "Invalid date") {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please put a valid date'
      });
      return;
    }
    this.isdisabled = true
    let obj: any = {}
    obj = this.signupForm.value
    // obj['dob'] = this.dob != null ? this.dob : moment(this.signupForm.value.dob).format('YYYY-MM-DD')
    obj['dob'] = dob
    obj['newsletterSubscribed'] = this.signupForm.value.newsletterSubscribed == true ? 1 : 0,
    obj['termsAgreed'] = this.signupForm.value.termsAgreed == true ? 1 : 0,
    //   console.log(obj)
    // return
    this.signupService.signUpStep1(this.signupForm.value).subscribe((x: any) => {
      if (x.token != null) {
        this.isdisabled = false
        this.auth.saveUser(x)
        this.auth.setLoggedIn();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'One time password sent successfully to your email'
        });
        this.otpDiv = true;
        this.signupDiv = false;
        this.timerDiv = true
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.timerDiv = false
            this.ResendOTPDiv = true;
            this.timeLeft = 60;
          }
        }, 1000)
      }
    }, (error: any) => {
      this.isdisabled = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })


  }

  /**
   * For verify otp for sign up
   * @method  otpVerify
   * @returns { Object } Object
   */
  otpVerify() {
    this.submitted3 = true;
    if (this.otpForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return;
    }
    this.isdisabled = true
    let obj: any = {}
    obj['email'] = this.signupForm.value.email
    let otp = this.otpForm.value.otp;
    obj['otp'] = parseInt(otp.replace(/\s/g, ""));
    this.signupService.otpverify(obj).subscribe((x: any) => {
      this.isdisabled = false
      this.router.navigate(['/subscription'])
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'One time password verified successfully'
      });
    }, (error: any) => {
      this.isdisabled = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }

  /**
   * For resend otp for sign up
   * @method  otpVerify
   * @returns { Object } Object
   */
  resendOTP() {
    let obj: any = {}
    obj['email'] = this.signupForm.value.email
    this.signupService.resentOTP(obj).subscribe((x: any) => {

      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'One time password sent successfully to your email'
      });
    }, (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }

  // Back to sign up page
  backToSignUp() {
    clearInterval(this.interval);
    this.timeLeft = 60;
    this.ResendOTPDiv = false;
    this.otpDiv = false
    this.signupDiv = true
    this.auth.isUserloggedIn.next(false);
    sessionStorage.clear()
  }

  // To navigate sign in page 
  goToLogin() {
    this.auth.isUserloggedIn.next(false);
    sessionStorage.clear()
    this.router.navigate(['/'])
  }

  // To navigate sign in page 
  backToLogin() {
    clearInterval(this.interval);
    this.timeLeft = 60;
    this.ResendOTPDiv = false;
    this.router.navigate(['/'])
    this.auth.isUserloggedIn.next(false);
    sessionStorage.clear()
  }

  // Show dialog box for read more 
  showDialog4() {
    this.display5 = true;
  }
}
