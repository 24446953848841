import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment'
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {Observable} from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { tap, map } from "rxjs/operators";
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private http:HttpClient,
    private router:Router,
    private auth:AuthGuard,
    private httpBack: HttpBackend,
    private messageService: MessageService,
  ) { }

  uploadFile(file:any,perpose:any):Observable<any>{
    this.http = new HttpClient(this.httpBack);
    let token = sessionStorage.getItem("tokenKey")
    const header = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    return this.http.post(environment.apiUrl + `files/${perpose}`, file,{ headers: header }).pipe(tap(event => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
       if(err.status == 401){
        setTimeout(() => {
        this.auth.signOut()
        },1000)
       }
      }
  })); 
  }

  uploadBase64File(file:any,perpose:any):Observable<any>{
    this.http = new HttpClient(this.httpBack);
    let token = sessionStorage.getItem("tokenKey")
    const header = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    return this.http.post(environment.apiUrl + `files/base64/${perpose}`, file,{ headers: header }).pipe(tap(event => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
       if(err.status == 401){
        setTimeout(() => {
        this.auth.signOut()
        },1000)
       }
      }
  })); 
  }
  
}
