import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../user.service'
import { ConfirmationService } from 'primeng/api';
import { HelperService } from 'src/app/core/services/helper.service';
import { CommonService } from 'src/app/modules/common/common.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import * as moment from 'moment';
import { StyleRenderer } from '@alyle/ui';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CrewProfileImageCropperModalComponent } from '../crew-profile-image-cropper-modal/crew-profile-image-cropper-modal.component';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Component({
  selector: 'app-crew-edit-profile',
  templateUrl: './crew-edit-profile.component.html',
  styleUrls: ['./crew-edit-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrewEditProfileComponent implements OnInit {
  @ViewChild("placesRef") placesRef!: GooglePlaceDirective;
  userData: any = {}
  fileData: any
  fileImage: any
  socialMediaImageFileData: any
  resumeData: any
  resumeImage: any
  fieldKey = ''
  selectedValueCheck: any = []
  selectedValueRadio: any = [];
  selectedList: any = [];
  disableIndex: any = []
  displayName: any
  activeTab: number = 0;
  rangeDates: any = [];
  dates: any = [];
  countries: any = [];
  isRadio: boolean = true
  visibleMember?: number;
  countryNames: string = '';
  stateName: string = '';
  cityName: string = '';
  latituded: string = '';;
  longituded: string = '';;
  street: string = '';;
  zip: string = '';;
  locationName: string = '';;
  fullAddress: any;
  personalForm: FormGroup;
  submittedPersonalForm: boolean = false;
  contactForm: FormGroup;
  submittedContactForm: boolean = false;
  professionalForm: FormGroup;
  submittedProfessionalForm: boolean = false;
  skillAndTrainingsForm: FormGroup;
  submittedSkillAndTrainingsForm: boolean = false;
  attributesForm: FormGroup;
  submittedAttributeForm: boolean = false;
  equipmentForm: FormGroup;
  submittedequipment: boolean = false;
  display: boolean = false;
  selectedValues: any = [];
  fieldsList: any = {}
  resumes: FormArray;
  selectedRow: any;
  vocalRange: any = []
  bands: any = []
  cycling: any = []
  displayValue: any = []
  selectedFields: any = []
  selectedValueUnion: any = "0"
  items: any = [];
  profileImage: any;
  socialMediaImage: any;
  pageLoader: boolean = false;
  isValidUserName: boolean = false;
  isCorrectUserName: boolean = false;
  isValidUserNameLoader: boolean = false;
  heights: any = []
  dueDateFrom: any
  dueDateTo: any
  age: any
  loading = false;
  userNameError = false
  maxDate: any
  dob: any
  notAtThisTimeAgent: any;
  selectAgent: any;
  selectManager: any;
  selectAtorney: any;
  selectPublisist: any;
  clearImage = 0
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  agents: FormArray;
  cropped?: string;
  isOtherUnionVisible: boolean = false
  isAgentTypeVisible: boolean = false
  agentLocation: any[] = []
  @ViewChild('fileUpload') fileUpload: any;
  options: Options = new Options({
    bounds: undefined, fields: ["address_component"], strictBounds: false,
    types: ['country', 'administrative_area_level_1'],
    // componentRestrictions: {country: ''}
  });

  showHint: string = '';
  showHints: any[] = [];


  /**
  * @constructor
  * @param {ConfirmationService} confirmationService - Confirmation services is required to display it by calling confirm method.
  * @param {HelperService} helper - The HelperService service for call API.
  * @param {CommonService} common - The common service for call API.
  * @param {userService} common - The userService service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {Router} router - For getting router details.
  * @param {FormBuilder} formBuilder - FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.  
  */
  constructor(
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private helper: HelperService,
    private common: CommonService,
    readonly sRenderer: StyleRenderer,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef,
  ) {
    this.heights = [
      { label: '0′ 12′′' },
      { label: ' 1′ 0′′' },
      { label: '1′ 1′′' },
      { label: '1′ 2′′' },
      { label: '1′ 3′′' },
      { label: '1′ 4′′' },
      { label: '1′ 5′′' },
      { label: '1′ 6′′' },
      { label: '1′ 7′′' },
      { label: '1′ 8′′' },
      { label: '1′ 9′′' },
      { label: '1′ 10′′' },
      { label: '1′ 11′′' },
      { label: '1′ 12′′' },
      { label: '2′ 0′′' },
      { label: '2′ 1′′' },
      { label: '2′ 2′′' },
      { label: '2′ 3′′' },
      { label: ' 2′ 4′′' },
      { label: '2′ 5′′' },
      { label: '2′ 5′′' },
      { label: '2′ 6′′' },
      { label: '2′ 7′′' },
      { label: '2′ 8′′' },
      { label: '2′ 9′′' },
      { label: '2′ 10′′' },
      { label: '2′ 11′′' },
      { label: '2′ 12′′' },
      { label: '3′ 0′′' },
      { label: '3′ 1′′' },
      { label: '3′ 2′′' },
      { label: '3′ 3′′' },
      { label: '3′ 4′′' },
      { label: '3′ 5′′' },
      { label: '3′ 6′′' },
      { label: '3′ 7′′' },
      { label: '3′ 8′′' },
      { label: ' 3′ 9′′' },
      { label: '3′ 10′′' },
      { label: ' 3′ 11′′' },
      { label: ' 3′ 12′′' },
      { label: '4′ 0′′' },
      { label: '4′ 1′′' },
      { label: '4′ 2′′' },
      { label: '4′ 3′′' },
      { label: '4′ 4′′' },
      { label: '4′ 5′′' },
      { label: '4′ 6′′' },
      { label: '4′ 7′′' },
      { label: '4′ 8′′' },
      { label: '4′ 9′′' },
      { label: '4′ 10′′' },
      { label: '4′ 11′′' },
      { label: ' 4′ 12′′' },
      { label: '5′ 0′′' },
      { label: '5′ 1′′' },
      { label: '5′ 2′′' },
      { label: '5′ 3′′' },
      { label: '5′ 4′′' },
      { label: '5′ 5′′' },
      { label: '5′ 6′′' },
      { label: '5′ 7′′' },
      { label: '5′ 8′′' },
      { label: '5′ 9′′' },
      { label: '5′ 10′′' },
      { label: '5′ 11′′' },
      { label: '5′ 12′′' },
      { label: '6′ 0′′' },
      { label: ' 6′ 1′′' },
      { label: '6′ 2′′' },
      { label: '6′ 3′′' },
      { label: '6′ 4′′' },
      { label: '6′ 5′′' },
      { label: '6′ 6′′' },
      { label: '6′ 7′′' },
      { label: '6′ 8′′' },
      { label: '6′ 9′′' },
      { label: '6′ 10′′' },
      { label: '6′ 11′′' },
      { label: '6′ 12′′' },
      { label: '7′ 0′′' },
      { label: '7′ 1′′' },
      { label: ' 7′ 2′′' },
      { label: '7′ 3′′' },
      { label: '7′ 4′′' },
      { label: '7′ 5′′' },
      { label: '7′ 6′′' },
      { label: ' 7′ 7′′' },
      { label: '7′ 8′′' },
      { label: '7′ 9′′' },
      { label: '7′ 10′′' },
      { label: '7′ 11′′' },
      { label: '7′ 12′′' },
      { label: '8′ 0′′' },
      { label: '8′ 1′′' },
      { label: '8′ 2′′' },
      { label: '8′ 3′′' },
      { label: '8′ 4′′' },
      { label: '8′ 5′′' },
      { label: ' 8′ 6′′' },
      { label: '8′ 7′′' },
      { label: '8′ 8′′' },
      { label: '8′ 9′′' },
      { label: '8′ 10′′' },
      { label: '8′ 11′′' },
      { label: '8′ 12′′' },
      { label: '9′ 0′′' },
      { label: '9′ 1′′' },
      { label: '9′ 2′′' },
      { label: '9′ 3′′' },
      { label: '9′ 4′′' },
      { label: '9′ 5′′' },
      { label: '9′ 6′′' },
      { label: '9′ 7′′' },
      { label: '9′ 8′′' },
      { label: '9′ 9′′' },
      { label: '9′ 10′′', }
    ]
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    // For top bar menu in edit profile 
    this.items = [
      { label: 'Personal', page: 'Personal', number: 0 },
      { label: 'Contact', page: 'Contact', number: 1 },
      { label: 'Professional', page: 'Professional', number: 2 },
      { label: 'Skills, Training & More', page: 'Skills, Training & More', number: 3 },
      { label: 'Attributes', page: 'Attributes', number: 4 },
      { label: 'Other Contributions', page: 'Equipment', number: 5 },
    ]
    let today = new Date();
    let month = today.getMonth();
    this.maxDate = new Date();
    this.maxDate.setMonth(month);

    // Form declaration
    this.personalForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      dob: new FormControl(''),
      tagLine: new FormControl(''),
      bio: new FormControl(''),
      socialMediaDescription: new FormControl(''),
      gender: new FormControl('', Validators.required),
      countryofOrigin: new FormControl(''),
      nationality: new FormControl('', Validators.required),
      multipleBirths: new FormControl(''),
      covidVaccination: new FormControl(0),
      //covidPassport: new FormControl(0),
      ethnicity: new FormControl(''),
      lifeChallenges: new FormControl(0),
      dualCitizenship: new FormControl(0),
      userName: new FormControl('', [Validators.minLength(4), Validators.required]),
      resumes: this.formBuilder.array([]),
    })
    this.resumes = this.personalForm.get('resumes') as FormArray;
    this.addResumes()

    // Form declaration
    this.contactForm = this.formBuilder.group({
      guardian: new FormControl(''),
      guardianPhone: new FormControl(''),
      guardianEmail: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      hasAgent: new FormControl('0'),
      agents: formBuilder.array([]),
      // agent: new FormControl(''),
      // agentPhone: new FormControl(''),
      // agentEmail: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      hasManager: new FormControl('0'),
      manager: new FormControl(''),
      managerPhone: new FormControl(''),
      managerEmail: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      hasAttorny: new FormControl('0'),
      attorney: new FormControl(''),
      attorneyPhone: new FormControl(''),
      attorneyEmail: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      hasPublicist: new FormControl('0'),
      publicist: new FormControl(''),
      publicistPhone: new FormControl(''),
      publicistEmail: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      cellPhone: new FormControl(''),
      otherPhone: new FormControl(''),
      whatsAppNumber: new FormControl(''),
      email: new FormControl(''),
      website: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      imdbLink: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      facebook: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      twitter: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      instagram: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      linkedin: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      imdbProLink: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      youtubeLink: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      tiktokLink: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      actorsAccess: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      castingNetworks: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      spotlight: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      castingFrontier: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      // nyCastings: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      applePodcasts: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      spotify: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      threads: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      showcast: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      workbook: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      enCast: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      youTubePodcasts: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      amazonMusic: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      castBox: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      spotifyPodcast: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
      amazonPodcasts: new FormControl('', Validators.compose([Validators.pattern("^https?:\/\/(.*)")])),
    })
    this.agents = this.contactForm.get('agents') as FormArray;
    this.addAgents()

    this.professionalForm = this.formBuilder.group({
      willTravel: new FormControl(''),
      visa: new FormControl(''),
      passport: new FormControl(''),
      //dayRate: new FormControl(''),
      union: new FormControl(''),
      otherCrewUnion: new FormControl(''),
      additionalInformation: new FormControl(''),
      hasKitOrGear: new FormControl(''),
      filmBudget: new FormControl(''),
      workingLocations: new FormControl(''),
      unionsAndGuilds: new FormControl(''),
      specialTalents: new FormControl(''),
      loanOutCompany: new FormControl(''),
    })

    //Form declaration
    this.skillAndTrainingsForm = this.formBuilder.group({
      actorTraining: new FormControl(''),
      vocalRange: new FormControl(''),
      dance: new FormControl(''),
      bands: new FormControl(''),
      cycling: new FormControl(''),
      militaryWeaponsAndFirearmsTraining: new FormControl(''),
      languages: new FormControl(''),
      gymnastic: new FormControl(''),
      outdoorSports: new FormControl(''),
      hobbies: new FormControl(''),
      racquetAndPaddleSports: new FormControl(''),
      rodeo: new FormControl(''),
      singingStyles: new FormControl(''),
      teamSports: new FormControl(''),
      extremeWinterSportsAndWaterSports: new FormControl(''),
      circusMagicClowning: new FormControl(''),
      dialects: new FormControl(''),
      equestrianSports: new FormControl(''),
      indoorAndOutdoorSports: new FormControl(''),
      driversPilotsCerificatesTransportation: new FormControl(''),
      stuntsMovesCombatMartialArts: new FormControl(''),
    })
    //Form declaration
    this.attributesForm = this.formBuilder.group({
      height: new FormControl(''),
      weight: new FormControl(''),
      eyeColor: new FormControl(''),
      tattoo: new FormControl(''),
      pregnant: new FormControl(''),
      dueDateFrom: new FormControl(''),
      dueDateTo: new FormControl(''),
      piercing: new FormControl(''),
      smoking: new FormControl(''),
      hairColor: new FormControl(''),
      hairLength: new FormControl(''),
      facialHair: new FormControl(''),
      complexion: new FormControl(''),
    })
    //Form declaration
    this.equipmentForm = this.formBuilder.group({
      equipment: new FormControl(''),
    })
  }
  /**
  * Get all item by input field in edit profile.
  * @method getFields
  * @returns { Object } Object
  */
  getFields() {
    this.pageLoader = true
    this.userService.getFiels().subscribe((data: any) => {
      this.pageLoader = false
      this.fieldsList = data
      //   let height:any = this.getRangeValue(30, 300);
      //   this.heights = [];
      //   for (let index = 0; index < height.length; index++) {
      //   let field: any = {
      //     label: this.toFeet(height[index])+'/'+height[index],
      //     value: height[index]
      //   }
      //   this.heights.push(field)
      // }
      this.getData()
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })

  }

  // onUnionChange(event: any) {
  //   if (this.professionalForm.value.union.includes(27)) {
  //     this.isOtherUnionVisible = true
  //   } else {
  //     this.isOtherUnionVisible = false
  //   }
  // }



  onAgentTypeChange(selectedTypeId: number, index: number,) {
    const agentGroup = this.agents.at(index);
    if (selectedTypeId === 17) {
      agentGroup.get('agentType')?.setValue('');
    }
  }

  // date picker and date format by moment 
  dobPick(event: any) {
    this.dob = moment(event).format('YYYY-MM-DD')
  }
  // get range value in input field 
  getRangeValue(start: number, end: number) {
    let values: any[] = [];
    for (let index = start; index <= end; index++) {
      values.push(index);
    }
    return values;
  }
  //for height convert into feet in attribute tab
  toFeet(n: number) {
    // let realFeet = ((n*0.393700) / 12);
    // let feet = Math.floor(realFeet);
    // let inches = Math.round((realFeet - feet) * 12);
    let totalInch = n / 2.54;
    let feet = Math.floor(totalInch / 12);
    let inches = (totalInch - (feet * 12)).toFixed(1)
    let amp = String.fromCodePoint(0x00002032);
    return feet + `${amp}` + ' ' + inches + `${amp}${amp}`;
  }
  /**
  * for username using specilal characters and validate input field.
  * @method checkUserName
  * @returns { Object } Object
  */
  checkUserName(event: any) {
    this.isCorrectUserName = false
    this.userNameError = false
    if (event.target.value.length > 3) {
      if (this.containsSpecialChars(event.target.value) == true) {
        this.isValidUserName = true
        this.userNameError = true
        return
      }
      this.isValidUserName = true
      this.isValidUserNameLoader = true
      let obj: any = {}
      obj['userName'] = (event.target.value).toLowerCase();
      this.userService.checkUserName(obj).subscribe((x: any) => {
        this.isValidUserName = false
        this.isCorrectUserName = true
        this.isValidUserNameLoader = false
        this.personalForm.controls["userName"].setValue((event.target.value).toLowerCase());
      }, (error: any) => {
        this.isValidUserName = true
        this.isCorrectUserName = false
        this.isValidUserNameLoader = false
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      })
    }
  }
  // for username special charecter pattern are used
  containsSpecialChars(str: any) {
    const specialChars = `\`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~ `;

    const result = specialChars.split('').some(specialChar => {
      if (str.includes(specialChar)) {
        return true;
      }

      return false;
    });

    return result;
  }

  //   omit_number(event:any) {
  //     let key;
  //     key = event.charCode;  //         key = event.keyCode;  (Both can be used)
  //     return (event.charCode > 96 && event.charCode < 123) || (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 && event.charCode <= 57) || event.charCode <= 31
  // }

  onPaste(e: any) {
    e.preventDefault();
    return false;
  }
  // for dashoard menu select
  listClick(event: any, newValue: any) {
    this.activeTab = newValue.number;
    sessionStorage.setItem('selectedItemTab', JSON.stringify(this.activeTab))
  }
  // for form controls
  get f() { return this.personalForm.controls; }
  get g() { return this.contactForm.controls; }
  get h() { return this.professionalForm.controls; }
  get i() { return this.skillAndTrainingsForm.controls; }
  get j() { return this.attributesForm.controls; }
  get k() { return this.equipmentForm.controls; }

  ngOnInit(): void {
    // if(sessionStorage['selectedItemTab']){
    //   let item = JSON.parse(sessionStorage['selectedItemTab']);
    //   this.activeTab = item 
    // }else{
    //   this.activeTab =  this.items[0]
    // }
    this.common.profileImageUpdate$.subscribe((profileImage) => this.profileImage = profileImage);
    //this.contactForm.controls['email'].disable();
    //this.personalForm.controls['countryofOrigin'].disable();
    this.getFields();
    // this.contactForm.get('hasAgent')?.valueChanges.subscribe((value: any) => {
    //   if (value) {
    //     this.clearAgentValidators();
    //   } else {
    //     this.setAgentValidators();
    //   }
    // });

  }

  ngAfterViewInit() {

  }

  // Form control
  getResumesControls() {
    return (this.personalForm.get('resumes') as FormArray).controls;
  }

  // Add new category
  addResumes(): void {
    this.resumes = this.personalForm.get('resumes') as FormArray;
    this.resumes.push(this.createCategory());
  }

  // Create form object
  createCategory(): FormGroup {
    this.submittedPersonalForm = false;
    return this.formBuilder.group({
      title: new FormControl(''),
      resume: new FormControl(''),
    });
  }

  getAgentsControls() {
    return (this.contactForm.get('agents') as FormArray).controls;
  }


  addAgents(item?: any) {

    this.agents = this.contactForm.get('agents') as FormArray;
    this.agents.push(this.createAgents(item));

  }


  createAgents(item?: any): FormGroup {
    // this.submittedContactForm = false
    return this.formBuilder.group({
      //   agentName: [item?.agentName ?? '', Validators.required],
      //   agentType: [item?.agentType ?? '', Validators.required],
      //   agentPhone: [item?.agentPhone ?? '', Validators.required],
      //   agentEmail: [item?.agentEmail ?? '', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      //   country: [item?.country ?? '', Validators.required],
      //   state: [item?.state ?? '', Validators.required],
      // })
      agentName: [item?.agentName ?? ''],
      agentTypeId: [item?.agentTypeId ?? null],
      agentType: [item?.agentType ?? ''],
      agentPhone: [item?.agentPhone ?? ''],
      agentEmail: [item?.agentEmail ?? ''],
      location: [item?.location ?? '']
    })

  }

  /**
   * for remove previous on click button at resume.  
   * @method removeItem
   * @method removeAgent
   * @returns { Object } Object
   */

  removeAgent(index: any) {
    this.confirmationService.confirm({
      message: 'Do yo really wish to delete?',
      accept: async () => {
        this.agents.removeAt(index)
        console.log(">>>>>", index);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record deleted Successfully' });
      }, reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No changes made' });
      }
    });
  }


  removeItem(index: any, item: any, id: any) {
    this.confirmationService.confirm({
      message: 'Do yo really wish to delete?',
      accept: async () => {
        this.selectedRow = index;
        //let resumeid = item.controls['id'].value 
        let resumeid = id
        if (resumeid == undefined) {
          this.resumes.removeAt(index);
        } else {
          this.userService.deleteResume(resumeid).subscribe((data: any) => {
            this.getData()
          }, (error: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
          })
        }
        // let l: any = this.personalForm.get('resumes') as FormArray;
        // l.controls[this.selectedRow].get('delete').setValue(1)
        // l.removeAt(index);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record deleted Successfully' });
      }, reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No changes made' });
      }
    });
  }
  // for calculate the age 
  public calculateAge(birthdate?: any): number {
    let age = moment().diff(birthdate, 'years');
    return age;
  }



  /**
   * Get all item in input field and set the value of input field.
   * @method getData 
   * @returns { Object } Object
   */
  getData() {
    this.resumes = this.personalForm.get('resumes') as FormArray;
    this.resumes.controls = [];

    this.agents = this.contactForm.get('agents') as FormArray;
    this.agents.controls = [];
    this.pageLoader = true
    this.userService.getUserData().subscribe((data: any) => {
      //console.log("userdata", data)
      this.pageLoader = false
      this.userData = data
      this.age = this.calculateAge(this.userData.dob)
      if (data.personalInfo.resumes.length > 0) {
        const promise = new Promise(async (resolve, reject) => {
          //create form array 
          await data.personalInfo.resumes.map(async (item: any, index: any) => {
            const tempRows: any = {};
            tempRows['id'] = new FormControl(item.id);
            tempRows['resumeSrc'] = new FormControl(item.resumeSrc);
            tempRows['title'] = new FormControl(item.title);
            tempRows['resume'] = new FormControl(item.resume);
            tempRows['myClass'] = new FormControl(true);
            //this.resumeData =  window.open(item.resumeSrc, '_blank');
            this.resumes.push(new FormGroup(tempRows));
          })
          resolve('Success!');
        });
      } else {
        this.addResumes()
      }

      if (data.contactInfo.agents && data.contactInfo.agents.length > 0) {
        data.contactInfo.agents.forEach((agent: any) => {
          this.agents.push(this.createAgents(agent));
          this.isAgentTypeVisible = true
        });
      } else {
        this.addAgents()
      }


      // if (data.contactInfo.agents.length > 0) {

      //      data.contactInfo.agents.map(async (item: any, index: any) => {
      //       const tempData: any = {};
      //       tempData['agentName'] = new FormControl(item.agentName);
      //       tempData['agentPhone'] = new FormControl(item.agentPhone);
      //       tempData['agentEmail'] = new FormControl(item.agentEmail);
      //       tempData['country'] = new FormControl(item.country);
      //       tempData['state'] = new FormControl(item.state);
      //       tempData['agentType'] = new FormControl(item.agentType);
      //       // tempData['myClass'] = new FormControl(true);
      //       this.agents.push(new FormGroup(tempData));
      //     })


      // } else {
      //   this.addAgents()
      // }


      //this.fileData = data.personalInfo['profileImageSrc']
      this.common.profileImageUpdate$.next(data.personalInfo['profileThumbnail']);
      sessionStorage.setItem("profileImage", (data.personalInfo['profileThumbnail']))
      this.profileImage = sessionStorage.getItem("profileImage")
      this.fileImage = data.personalInfo['profileImage']
      this.socialMediaImage = data.personalInfo['socialMediaImage']
      if (this.userData.personalInfo.socialMediaImageSrc) this.socialMediaImageFileData = this.userData.personalInfo.socialMediaImageSrc
      this.personalForm.controls['name'].setValue(data.personalInfo['name'] != undefined ? data.personalInfo['name'] : '');
      this.personalForm.controls['tagLine'].setValue(data.personalInfo['tagLine'] != undefined ? data.personalInfo['tagLine'] : '');
      this.personalForm.controls['bio'].setValue(data.personalInfo['bio'] != undefined ? data.personalInfo['bio'] : '');
      this.personalForm.controls['socialMediaDescription'].setValue(data.personalInfo['socialMediaDescription'] != undefined ? data.personalInfo['socialMediaDescription'] : '');
      this.personalForm.controls['gender'].setValue(data.personalInfo['gender'] != undefined ? data.personalInfo['gender'] : '');
      // this.personalForm.controls['countryofOrigin'].setValue(data.address['country'] != undefined ? data.address['country'] : '');
      this.personalForm.controls['nationality'].setValue(data.personalInfo['nationality'] != undefined ? data.personalInfo['nationality'] : '');
      this.personalForm.controls['covidVaccination'].setValue(data.personalInfo['covidVaccination'] != undefined ? data.personalInfo['covidVaccination'] : '');
      // this.personalForm.controls['covidPassport'].setValue(data.personalInfo['covidPassport'] != undefined ? data.personalInfo['covidPassport'] : '');
      this.personalForm.controls['ethnicity'].setValue(data.personalInfo['ethnicity'] != undefined ? data.personalInfo['ethnicity'] : '');
      this.personalForm.controls['lifeChallenges'].setValue(data.personalInfo['lifeChallenges'] != undefined ? data.personalInfo['lifeChallenges'] : '');
      this.personalForm.controls['dualCitizenship'].setValue(data.personalInfo['dualCitizenship'] != undefined ? data.personalInfo['dualCitizenship'] : '');
      this.personalForm.controls['multipleBirths'].setValue(data.personalInfo['multipleBirths'] != undefined ? data.personalInfo['multipleBirths'] : '');
      this.personalForm.controls['userName'].setValue(data.personalInfo['userName'] != undefined ? data.personalInfo['userName'] : '');
      this.personalForm.controls['dob'].setValue(data.personalInfo['dob'] != undefined ? data.personalInfo['dob'] : '');

      this.contactForm.controls['cellPhone'].setValue(data.contactInfo['cellPhone'] != undefined ? data.contactInfo['cellPhone'] : '');
      this.contactForm.controls['otherPhone'].setValue(data.contactInfo['otherPhone'] != undefined ? data.contactInfo['otherPhone'] : '');
      this.contactForm.controls['whatsAppNumber'].setValue(data.contactInfo['whatsAppNumber'] != undefined ? data.contactInfo['whatsAppNumber'] : '');
      this.contactForm.controls['email'].setValue(data.contactInfo['email'] != undefined ? data.contactInfo['email'] : '');
      this.contactForm.controls['website'].setValue(data.contactInfo['website'] != undefined ? data.contactInfo['website'] : '');
      this.contactForm.controls['imdbLink'].setValue(data.contactInfo['imdbLink'] != undefined ? data.contactInfo['imdbLink'] : '');
      this.contactForm.controls['facebook'].setValue(data.contactInfo['facebook'] != undefined ? data.contactInfo['facebook'] : '');
      this.contactForm.controls['twitter'].setValue(data.contactInfo['twitter'] != undefined ? data.contactInfo['twitter'] : '');
      this.contactForm.controls['instagram'].setValue(data.contactInfo['instagram'] != undefined ? data.contactInfo['instagram'] : '');
      this.contactForm.controls['linkedin'].setValue(data.contactInfo['linkedin'] != undefined ? data.contactInfo['linkedin'] : '');
      this.contactForm.controls['imdbProLink'].setValue(data.contactInfo['imdbProLink'] != undefined ? data.contactInfo['imdbProLink'] : '');
      this.contactForm.controls['youtubeLink'].setValue(data.contactInfo['youtubeLink'] != undefined ? data.contactInfo['youtubeLink'] : '');
      this.contactForm.controls['tiktokLink'].setValue(data.contactInfo['tiktokLink'] != undefined ? data.contactInfo['tiktokLink'] : '');
      this.contactForm.controls['actorsAccess'].setValue(data.contactInfo['actorsAccess'] != undefined ? data.contactInfo['actorsAccess'] : '');
      this.contactForm.controls['castingNetworks'].setValue(data.contactInfo['castingNetworks'] != undefined ? data.contactInfo['castingNetworks'] : '');
      this.contactForm.controls['spotlight'].setValue(data.contactInfo['spotlight'] != undefined ? data.contactInfo['spotlight'] : '');
      this.contactForm.controls['castingFrontier'].setValue(data.contactInfo['castingFrontier'] != undefined ? data.contactInfo['castingFrontier'] : '');
      // this.contactForm.controls['nyCastings'].setValue(data.contactInfo['nyCastings'] != undefined ? data.contactInfo['nyCastings'] : '');
      this.contactForm.controls['applePodcasts'].setValue(data.contactInfo['applePodcasts'] != undefined ? data.contactInfo['applePodcasts'] : '');
      this.contactForm.controls['spotify'].setValue(data.contactInfo['spotify'] != undefined ? data.contactInfo['spotify'] : '');
      this.contactForm.controls['threads'].setValue(data.contactInfo['threads'] != undefined ? data.contactInfo['threads'] : '');
      this.contactForm.controls['showcast'].setValue(data.contactInfo['showcast'] != undefined ? data.contactInfo['showcast'] : '');
      this.contactForm.controls['workbook'].setValue(data.contactInfo['workbook'] != undefined ? data.contactInfo['workbook'] : '');
      this.contactForm.controls['enCast'].setValue(data.contactInfo['enCast'] != undefined ? data.contactInfo['enCast'] : '');
      this.contactForm.controls['youTubePodcasts'].setValue(data.contactInfo['youTubePodcasts'] != undefined ? data.contactInfo['youTubePodcasts'] : '');
      this.contactForm.controls['amazonMusic'].setValue(data.contactInfo['amazonMusic'] != undefined ? data.contactInfo['amazonMusic'] : '');
      this.contactForm.controls['castBox'].setValue(data.contactInfo['castBox'] != undefined ? data.contactInfo['castBox'] : '');
      this.contactForm.controls['spotifyPodcast'].setValue(data.contactInfo['spotifyPodcast'] != undefined ? data.contactInfo['spotifyPodcast'] : '');
      this.contactForm.controls['amazonPodcasts'].setValue(data.contactInfo['amazonPodcasts'] != undefined ? data.contactInfo['amazonPodcasts'] : '');

      this.contactForm.controls['guardian'].setValue(data.contactInfo['guardian'] != undefined ? data.contactInfo['guardian'] : '');
      this.contactForm.controls['guardianPhone'].setValue(data.contactInfo['guardianPhone'] != undefined ? data.contactInfo['guardianPhone'] : '');
      this.contactForm.controls['guardianEmail'].setValue(data.contactInfo['guardianEmail'] != undefined ? data.contactInfo['guardianEmail'] : '');
      this.contactForm.controls['hasAgent'].setValue(data.contactInfo['hasAgent'] == 1 ? false : true);
      // this.contactForm.controls['agent'].setValue(data.contactInfo['agent'] != undefined ? data.contactInfo['agent'] : '');
      // this.contactForm.controls['agentPhone'].setValue(data.contactInfo['agentPhone'] != undefined ? data.contactInfo['agentPhone'] : '');
      // this.contactForm.controls['agentEmail'].setValue(data.contactInfo['agentEmail'] != undefined ? data.contactInfo['agentEmail'] : '');
      this.contactForm.controls['hasManager'].setValue(data.contactInfo['hasManager'] == 1 ? false : true);
      this.contactForm.controls['manager'].setValue(data.contactInfo['manager'] != undefined ? data.contactInfo['manager'] : '');
      this.contactForm.controls['managerPhone'].setValue(data.contactInfo['managerPhone'] != undefined ? data.contactInfo['managerPhone'] : '');
      this.contactForm.controls['managerEmail'].setValue(data.contactInfo['managerEmail'] != undefined ? data.contactInfo['managerEmail'] : '');
      this.contactForm.controls['hasAttorny'].setValue(data.contactInfo['hasAttorny'] == 1 ? false : true);
      this.contactForm.controls['attorney'].setValue(data.contactInfo['attorney'] != undefined ? data.contactInfo['attorney'] : '');
      this.contactForm.controls['attorneyPhone'].setValue(data.contactInfo['attorneyPhone'] != undefined ? data.contactInfo['attorneyPhone'] : '');
      this.contactForm.controls['attorneyEmail'].setValue(data.contactInfo['attorneyEmail'] != undefined ? data.contactInfo['attorneyEmail'] : '');
      this.contactForm.controls['hasPublicist'].setValue(data.contactInfo['hasPublicist'] == 1 ? false : true);
      this.contactForm.controls['publicist'].setValue(data.contactInfo['publicist'] != undefined ? data.contactInfo['publicist'] : '');
      this.contactForm.controls['publicistPhone'].setValue(data.contactInfo['publicistPhone'] != undefined ? data.contactInfo['publicistPhone'] : '');
      this.contactForm.controls['publicistEmail'].setValue(data.contactInfo['publicistEmail'] != undefined ? data.contactInfo['publicistEmail'] : '');

      this.professionalForm.controls['willTravel'].setValue(data.professionalInfo['willTravel'] != undefined ? data.professionalInfo['willTravel'] : '');
      this.professionalForm.controls['visa'].setValue(data.professionalInfo['visa'] != undefined ? data.professionalInfo['visa'] : '');
      this.professionalForm.controls['passport'].setValue(data.professionalInfo['passport'] != undefined ? data.professionalInfo['passport'] : '');
      // this.professionalForm.controls['dayRate'].setValue(data.professionalInfo['dayRate'] != undefined ? data.professionalInfo['dayRate'] : '');
      this.professionalForm.controls['union'].setValue(data.professionalInfo['union'] != undefined ? data.professionalInfo['union'] : '');
      this.professionalForm.controls['otherCrewUnion'].setValue(data.professionalInfo['otherCrewUnion'] != undefined ? data.professionalInfo['otherCrewUnion'] : '');

      // if(data.professionalInfo['union']){
      //   this.isOtherUnionVisible=true
      //   this.professionalForm.controls['otherCrewUnion'].setValue(data.professionalInfo['otherCrewUnion'] )
      // }


      this.professionalForm.controls['additionalInformation'].setValue(data.professionalInfo['additionalInformation'] != undefined ? data.professionalInfo['additionalInformation'] : '');
      this.professionalForm.controls['hasKitOrGear'].setValue(data.professionalInfo['hasKitOrGear'] != undefined ? data.professionalInfo['hasKitOrGear'] : '');
      this.professionalForm.controls['filmBudget'].setValue(data.professionalInfo['filmBudget'] != undefined ? data.professionalInfo['filmBudget'] : '');
      this.professionalForm.controls['workingLocations'].setValue(data.professionalInfo['workingLocations'] != undefined ? data.professionalInfo['workingLocations'] : '');
      this.professionalForm.controls['unionsAndGuilds'].setValue(data.professionalInfo['unionsAndGuilds'] != undefined ? data.professionalInfo['unionsAndGuilds'] : 1);
      this.professionalForm.controls['specialTalents'].setValue(data.professionalInfo['specialTalents'] != undefined ? data.professionalInfo['specialTalents'] : '');
      this.professionalForm.controls['loanOutCompany'].setValue(data.professionalInfo['loanOutCompany'] != undefined ? data.professionalInfo['loanOutCompany'] : '');

      this.attributesForm.controls['height'].setValue(data.attributes['height'] != undefined ? data.attributes['height'] : '');
      this.attributesForm.controls['weight'].setValue(data.attributes['weight'] != undefined ? data.attributes['weight'] : '');
      this.attributesForm.controls['eyeColor'].setValue(data.attributes['eyeColor'] != undefined ? data.attributes['eyeColor'] : '');
      this.attributesForm.controls['tattoo'].setValue(data.attributes['tattoo'] != undefined ? data.attributes['tattoo'] : '');
      this.attributesForm.controls['pregnant'].setValue(data.attributes['pregnant'] != undefined ? data.attributes['pregnant'] : '');
      this.attributesForm.controls['dueDateFrom'].setValue(data.attributes['dueDateFrom'] != undefined ? data.attributes['dueDateFrom'] : '');
      this.attributesForm.controls['dueDateTo'].setValue(data.attributes['dueDateTo'] != undefined ? data.attributes['dueDateTo'] : '');
      this.attributesForm.controls['piercing'].setValue(data.attributes['piercing'] != undefined ? data.attributes['piercing'] : '');
      this.attributesForm.controls['smoking'].setValue(data.attributes['smoking'] != undefined ? data.attributes['smoking'] : '');
      this.attributesForm.controls['hairColor'].setValue(data.attributes['hairColor'] != undefined ? data.attributes['hairColor'] : '');
      this.attributesForm.controls['hairLength'].setValue(data.attributes['hairLength'] != undefined ? data.attributes['hairLength'] : '');
      this.attributesForm.controls['facialHair'].setValue(data.attributes['facialHair'] != undefined ? data.attributes['facialHair'] : '');
      this.attributesForm.controls['complexion'].setValue(data.attributes['complexion'] != undefined ? data.attributes['complexion'] : '');

      this.skillAndTrainingsForm.patchValue(data.skillAndTrainings)
      // this.equipmentForm.patchValue(data.equipment)
      this.equipmentForm.controls['equipment'].setValue(data.equipment != undefined ? data.equipment : '');

    }, (error: any) => {
      this.pageLoader = false
      this.addResumes()
      this.addAgents()
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  trainningCheck(event: any, i: any, item: any) {
    if (!this.selectedValueCheck.includes(item.value.toString())) {
      let findIndex = this.selectedList.indexOf(item.value)
      this.selectedList.splice(findIndex, 1);
      this.selectedValueRadio[item.value] = ''
    } else {
      this.selectedList.push(item.value)
    }
  }

  /**
   * for showing the dialog box table used in edit profile page.
   * @method showTable
   * @returns { Object } Object
   */
  showTable(displayName: any, fieldKey: any) {
    this.selectedValueCheck = []
    this.selectedValueRadio = [];
    this.fieldKey = fieldKey;
    this.displayName = displayName
    this.loading = true;
    this.userService.getSkilledCheckValue(this.fieldKey).subscribe((data: any) => {
      this.display = true;
      this.loading = false;
      data.forEach((x: any) => {
        if (x.selected == true) {
          this.selectedValueCheck.push((x.value).toString())
          if (x.selectedValue != null) {
            this.selectedValueRadio[x.value] = ((x.selectedValue).toString())
          }
        } else {
          this.selectedValueRadio[x.value] = '';
        }
      })
      this.displayValue = data
    }, (error: any) => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
    * for close the dialogbox 
    * @method  closeDialog
    * @returns { Object } Object
   */
  closeDialog(name: any) {
    this.selectedValues = []
    this.selectedValueCheck.forEach((x: any) => {
      let temp: any = {
        value: parseInt(x),
        selectedValue: parseInt(this.selectedValueRadio[x])
      }
      this.selectedValues.push(temp)
    })
    let labels: any = []
    let values: any = []
    this.displayValue.forEach((value1: any, key1: any) => {
      this.selectedValues.forEach((value2: any, key2: any) => {
        if (value1.value === parseInt(value2.value)) {
          labels.push(value1.label)
          values.push(value1.value)
        }
      });
    });
    this.skillAndTrainingsForm.controls[this.fieldKey].setValue(labels);
    let obj: any = {}
    obj['fieldKey'] = this.fieldKey
    obj['fieldValue'] = this.selectedValues
    this.userService.saveSkilledCheckValue(obj).subscribe((data: any) => {
      this.display = false
      this.selectedValueCheck = []
      this.selectedValueRadio = [];
      this.displayValue = []
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
   * function for all Personal info item by type and save the details.
   * @method  savePersonalDetails
   * @returns { Object } Object
   */
  savePersonalDetails() {
    this.submittedPersonalForm = true
    if (this.personalForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields' });
      return;
    }
    // this.personalForm.value.resumes.every((element: any, index: any) => {
    for (const [index, element] of this.personalForm.value.resumes.entries()) {
      //console.log(element)
      if (element.title != '' && element.resume == '') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload your resume' });
        return;
      }
      if (element.title == '' && element.resume != '') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please put your resume name' });
        return;
      }
      if (element.title == '' && element.resume == '') {
        for (var key in element) {
          if (element[key] === "") {
            delete element[key]
            this.personalForm.value.resumes.splice(index, 1);
          }
        }
      }
    }
    // });
    this.pageLoader = true
    let obj: any = {}
    obj = this.personalForm.value
    obj['profileImage'] = this.fileImage
    obj['socialMediaImage'] = this.socialMediaImage
    // console.log(obj);
    // return
    this.userService.addPersonalInfo(obj).subscribe((x: any) => {
      this.pageLoader = false
      this.getData()
      sessionStorage.setItem("profileImage", (this.fileData))
      this.common.profileImageUpdate$.next(this.fileData);
      this.fileImage = ''
      this.clearImage = 0
      this.activeTab = 1
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record updated successfully' });
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
   * function for all contact info item by type and save the details.
   * @method  saveContactDetails
   * @returns { Object } Object
  */
  saveContactDetails() {
    this.submittedContactForm = true
    if (this.submittedContactForm) {
      this.agents.controls.forEach((control: any) => {
        Object.keys(control.controls).forEach(key => {
          control.get(key).clearValidators();
          control.get(key).updateValueAndValidity();
        });
      });
    }
    if (this.contactForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields' });
      return;
    }
    this.pageLoader = true
    //Remove Masking
    let obj: any = {}
    obj = this.contactForm.value
    obj['hasAgent'] = this.contactForm.value.hasAgent == 1 ? 0 : 1
    obj['hasManager'] = this.contactForm.value.hasManager == 1 ? 0 : 1
    obj['hasAttorny'] = this.contactForm.value.hasAttorny == 1 ? 0 : 1
    obj['hasPublicist'] = this.contactForm.value.hasPublicist == 1 ? 0 : 1
    // obj['cellPhone'] = (this.contactForm.value.cellPhone).replace(/\D/g, '')
    // obj['otherPhone'] = (this.contactForm.value.otherPhone).replace(/\D/g, '')
    // obj['whatsAppNumber'] = (this.contactForm.value.whatsAppNumber).replace(/\D/g, '')
    obj['cellPhone'] = this.contactForm.value.cellPhone?.internationalNumber ? this.contactForm.value.cellPhone?.internationalNumber : ''
    obj['otherPhone'] = this.contactForm.value.otherPhone?.internationalNumber ? this.contactForm.value.otherPhone?.internationalNumber : ''
    obj['whatsAppNumber'] = this.contactForm.value.whatsAppNumber?.internationalNumber ? this.contactForm.value.whatsAppNumber?.internationalNumber : ''
    obj['guardianPhone'] = this.contactForm.value.guardianPhone?.internationalNumber ? this.contactForm.value.guardianPhone?.internationalNumber : ''
    // obj['agentPhone'] = this.contactForm.value.agentPhone?.internationalNumber ? this.contactForm.value.agentPhone?.internationalNumber : ''
    obj['managerPhone'] = this.contactForm.value.managerPhone?.internationalNumber ? this.contactForm.value.managerPhone?.internationalNumber : ''
    obj['attorneyPhone'] = this.contactForm.value.attorneyPhone?.internationalNumber ? this.contactForm.value.attorneyPhone?.internationalNumber : ''
    obj['publicistPhone'] = this.contactForm.value.publicistPhone?.internationalNumber ? this.contactForm.value.publicistPhone?.internationalNumber : ''
    if (this.contactForm.value.hasAgent != 1) {
      obj['agents'] = []
    } else {
      const validAgents = this.contactForm.value.agents.filter((agent: any) => agent.agentEmail || agent.agentPhone);
      obj['agents'] = validAgents.map((agent: any) => ({
        agentTypeId: agent.agentTypeId,
        agentType: agent.agentType,
        agentName: agent.agentName,
        agentPhone: agent.agentPhone?.internationalNumber ? agent.agentPhone?.internationalNumber : '',
        agentEmail: agent.agentEmail,
        location: agent.location
      })
      );

    }
    this.contactForm.value.agents.map((agent: any) => {
      if (agent.agentTypeId !== 17) {
        agent.agentType = '';
      }
      return agent;
    });
    // }
    // console.log("contact info", obj)
    // return
    this.userService.addContactInfo(obj).subscribe((x: any) => {
      this.pageLoader = false
      this.getData()
      this.activeTab = 2
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record updated successfully' });
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
  * function for all Professional info item by type and save the details.
  * @method  saveProfessionalDetails
  * @returns { Object } Object
  */
  saveProfessionalDetails() {
    // this.pageLoader = true
    this.submittedProfessionalForm = true
    // if(this.professionalForm.value.unionsAndGuilds == 1){
    //   // this.professionalForm.controls['dayRate'].setValue('');
    //   // this.professionalForm.controls['dayRate'].clearValidators()
    //   this.professionalForm.controls['union'].addValidators([Validators.required])
    //   console.log("work1")
    //   return;
    // } 
    // if (this.professionalForm.value.unionsAndGuilds == 0){
    //   // this.professionalForm.controls['union'].setValue('');
    //   // this.professionalForm.controls['union'].clearValidators()
    //   this.professionalForm.controls['dayRate'].addValidators([Validators.required])
    //   console.log("work2")
    //   return;
    // }
    // if (this.professionalForm.invalid) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields' });
    //   return;
    // }
    // const selectedValues = this.professionalForm.get('union')?.value;

    // if (selectedValues.length === 1 && selectedValues.includes(27)) {
    //   const otherUnionValue = this.professionalForm.get('otherCrewUnion')?.value;
    //   this.professionalForm.get('union')?.setValue(otherUnionValue)
    //   this.isOtherUnionVisible=true
    // } else {
    //   const selectedUnionsExceptOther = selectedValues.filter((value: number) => value !== 27);
    //   this.professionalForm.get('union')?.setValue(selectedUnionsExceptOther)
    //   this.isOtherUnionVisible=false
    // }

    let obj: any = {}
    obj = this.professionalForm.value
    if (this.professionalForm.value.unionsAndGuilds == 1) {
      obj['dayRate'] = ''
    } else {
      obj['union'] = ''
      // obj['otherCrewUnion'] = ''
      // this.isOtherUnionVisible=false
    }
    // if (!this.professionalForm.value.union.includes(27)) {
    //   obj['otherCrewUnion'] = ''
    // }
    // console.log(obj);
    // return
    this.userService.addProfessionalInfo(obj).subscribe((x: any) => {
      this.pageLoader = false
      this.getData()
      this.activeTab = 3
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record updated successfully' });
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }
  /**
  * function for all SkillAndTraining item by type and save the details.
  * @method  saveSkillAndTrainingDetails
  * @returns { Object } Object
  */
  saveSkillAndTrainingDetails() {
    this.submittedSkillAndTrainingsForm = true
    // if (this.skillAndTrainingsForm.invalid) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields' });
    //   return;
    // }
    this.userService.addSkillInfo(this.skillAndTrainingsForm.value).subscribe((x: any) => {
      this.pageLoader = false
      this.getData()
      this.activeTab = 4
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record updated successfully' });
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }
  // date picker and date formating in attribute due date 
  duePick(event: any) {
    this.dueDateFrom = moment(event).format('MM/DD/YYYY')
    //this.signupForm.controls["dob"].setValue(dob);
  }
  // date picker date formating in attribute to date
  toPick(event: any) {
    this.dueDateTo = moment(event).format('MM/DD/YYYY')
    //this.signupForm.controls["dob"].setValue(dob);
  }
  /**
   * function for all attribute item by type and save the details.
   * @method  saveattributeDetails
   * @returns { Object } Object
   */
  saveattributeDetails() {
    this.submittedAttributeForm = true
    // if (this.attributesForm.invalid) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields' });
    //   return;
    // }
    this.pageLoader = true
    let obj: any = {}
    obj = this.attributesForm.value
    if (this.attributesForm.value.pregnant == 2) {
      obj['dueDateFrom'] = this.dueDateFrom
      obj['dueDateTo'] = this.dueDateTo
    } else {
      obj['dueDateFrom'] = ''
      obj['dueDateTo'] = ''
    }
    this.userService.addAttributesInfo(obj).subscribe((x: any) => {
      this.pageLoader = false
      this.getData()
      this.activeTab = 5
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Record updated successfully'
      });
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  /**
   * function for all equipment item by type and save the details.
   * @method  saveequipmentDetails
   * @returns { Object } Object
   */
  saveequipmentDetails() {
    // this.submittedequipment = true
    // if (this.equipmentForm.invalid) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill up all the required fields' });
    //   return;
    // }
    this.pageLoader = true
    const obj = this.equipmentForm.value
    obj.equipment = this.equipmentForm.value.equipment.trim()
    this.userService.addEquipmentInfo(obj).subscribe((x: any) => {
      this.pageLoader = false
      this.getData()
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Record updated successfully'
      });
      this.router.navigateByUrl('/crew-profile')
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  /**
   * upload profile image through onUpload event.
   * @param { type } type - Type 1 = photo
   * @method  onUpload
   * @returns { Object } Object
   */
  onUpload(event: any) {
    if (event.currentFiles.length == 0) {
      return
    }
    let file = event.currentFiles[0]
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.pageLoader = true
    this.helper.uploadFile(formData, 1).subscribe(data => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'info', summary: 'Info',
        detail: 'File added, now press the save button to save it'
      });
      this.fileData = data.url
      this.fileImage = data.filename
      this.common.profileImageUpdate$.next(this.fileData);
      this.clearImage = 1
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  // for clearing image on profile page 
  clearUploadImage() {
    this.fileUpload.clear()
    this.fileData = ''
    this.fileImage = ''
    this.getData()
    this.clearImage = 0
  }
  /**
   * upload profile resume through onUpload event.
   * @param { type } type - Type 1 = resume
   * @method   onUploadResume
   * @returns { Object } Object
   */
  onUploadResume(event: any, i: any) {
    if (event.currentFiles.length == 0) {
      return
    }
    let file = event.currentFiles[0]
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.pageLoader = true
    this.helper.uploadFile(formData, 2).subscribe(data => {
      this.pageLoader = false
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'File added, now press the save button to save it' });
      this.resumeData = data.url
      this.resumeImage = data.filename
      this.resumes.controls[i].get('resume')?.setValue(this.resumeImage)
    }, (error: any) => {
      this.pageLoader = false
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }

  // onChange(event:any) {
  //   let file = event.target.files[0];
  //   let formData: FormData = new FormData();
  //   formData.append('file', file, file.name);
  //   this.userService.uploadFile(formData,3).subscribe(data => {
  //     this.fileData = data.url
  //     this.fileImage = data.filename
  //     localStorage.setItem('serviceName',data.filename)
  //   })
  // }

  //Set google address


  public handleAddressChange(address: any, i?: any) {
    console.log(address)
    let addDetails = this.extract(address);
    // this.fullAddress = addDetails.full_address;
    // this.cityName = addDetails.detail.city;
    // this.latituded = addDetails.latlng.lat;
    // this.longituded = addDetails.latlng.lng;
    // this.street = addDetails.detail.street;
    // this.zip = addDetails.detail.zip;
    // this.locationName = addDetails.detail.location;
    this.countryNames = addDetails.detail.country.name;
    this.stateName = addDetails.detail.state;
    const location = `${this.stateName}, ${this.countryNames}`;
    const agentsArray = this.contactForm.get('agents') as FormArray;
    const agentGroup = agentsArray.at(i) as FormGroup;
    agentGroup.controls['location'].setValue(location);

    console.log(addDetails);
  }

  //Google address set small part
  public extract(receivedAddresses: any) {
    let address_components = receivedAddresses.address_components;
    let address = {
      // full_address: receivedAddresses.formatted_address,
      // latlng: {
      //   lat: receivedAddresses.geometry.location.lat(),
      //   lng: receivedAddresses.geometry.location.lng(),
      // },
      detail: {
        // location: '',
        // street: '',
        // city: '',
        // department: '',
        state: '',
        country: {
          name: '',
          short_name: '',
        },
        // zip: '',
      },
    };
    address_components.forEach((component: any) => {
      // if (component.types.indexOf("street_number") > -1) {
      //   // set numero
      //   address.detail['location'] = component.long_name;
      // }
      // if (component.types.indexOf("route") > -1) {
      //   // set voie
      //   address.detail['street'] = component.long_name;
      // }
      // if (component.types.indexOf("locality") > -1) {
      //   // set ville
      //   address.detail['city'] = component.long_name;
      // }
      // if (component.types.indexOf("administrative_area_level_2") > -1) {
      //   // set departement
      //   address.detail['department'] = component.long_name;
      // }
      if (component.types.indexOf("administrative_area_level_1") > -1) {
        // set region
        address.detail['state'] = component.long_name;
      }
      if (component.types.indexOf("country") > -1) {
        // set pays

        address.detail['country'] = {
          name: component.long_name,
          short_name: component.short_name,
        }
      }
      // if (component.types.indexOf("postal_code") > -1) {
      //   // set code postal
      //   address.detail['zip'] = component.long_name;
      // }
    });
    return address;
  }

  //imagecroppermodal

  openCropperDialog(event: Event) {
    this.cropped = null!;
    this._dialog.open<CrewProfileImageCropperModalComponent, Event>(CrewProfileImageCropperModalComponent, {
      data: event,
      width: 426,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      if (result) {
        this.cropped = result.dataURL;
        this._cd.markForCheck();
        this.pageLoader = true
        this.helper.uploadBase64File({ content: result.dataURL }, 7).subscribe(data => {
          this.pageLoader = false
          this.messageService.add({
            severity: 'info', summary: 'Info',
            detail: 'File added, now press the save button to save it'
          });
          this.socialMediaImageFileData = data.url
          // this.fileImage = data.filename
          this.socialMediaImage = data.filename
          // this.common.profileImageUpdate$.next(this.fileData);
          // this.clearImage = 1
        }, (error: any) => {
          this.pageLoader = false
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.error.message
          });
        })
      }
    });
  }
}
