import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../../dashboard/dashboard.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profileImage: any
  showMenu = true
  feature: any = []
  rootUrl: any
  keyWord: any = ''
  searchKeyValue: any = ''
  cross: boolean = false
  registrationStatus: any
  /**
  * @constructor
  * @param {Router} router - For getting router details.
  * @param {AuthGuard} auth - AuthGuard is used to protect the routes from unauthorized access in angular.
  * @param {CommonService} common - The service for call API.
  * @param {UserService} userService - The userService service for call API.
  * @param {DashboardService} dService - The dServiceservice for call API.
  */
  constructor(
    private common: CommonService,
    private auth: AuthGuard,
    private router: Router,
    private dService: DashboardService,
    private userService: UserService
  ) {


  }

  ngOnInit(): void {
    this.common.registrationStatus$.subscribe((status) => this.registrationStatus = status);
    this.registrationStatus = sessionStorage.getItem("registrationStatus")
    console.log(this.registrationStatus)
    if (this.registrationStatus == 1) {
      this.feature = [
        {
          label: 'My Account', page: 'My Account', number: 1, command: () => {
            this.goToDashboard()
          }
        },
        {
          label: 'Log Out', page: 'Logout', number: 2, command: () => {
            this.logout()
          }
        },
      ];
    } else {
      this.feature = [
        {
          label: 'Log Out', page: 'Logout', number: 2, command: () => {
            this.logout()
          }
        },
      ];
    }
    this.common.profileImageUpdate$.subscribe((profileImage) => this.profileImage = profileImage);
    this.profileImage = sessionStorage.getItem("profileImage")
    this.rootUrl = environment.rootUrl
  }

  //Navigate the path.
  goToDashboard() {
    this.dService.getUserData().subscribe((x: any) => {
      if (x.subscribed == 0 && (x.address.countryCode == 'US' || x.address.countryCode == 'CA')) {
        this.userService.renewState$.subscribe((data: any) => {
          if (data == 0) {
            this.router.navigate(['/dashboard', 3])
          }
        });
      } else {
        this.router.navigate(['/dashboard'])
      }
    })
  }

  //For logout
  logout() {
    this.auth.signOut()
  }

  //For dialog box
  onClick() {
    this.showMenu = false
  }

  // For navigation the path
  home() {
    window.location.href =
      environment.rootUrl
    //this.router.navigate(['/crew-profile'])
  }

  // For search 
  onSearch(ev: any) {
    this.searchKeyValue = ev.target.value
    if (this.searchKeyValue != '') {
      this.cross = true
      window.location.href = environment.rootUrl + `crew-list/?searchStr=`+ this.searchKeyValue;
    }
  }

  // For clear input field
  inputClear() {
    this.searchKeyValue = ''
    this.keyWord = ''
    this.cross = false
    // this.router.navigate(['crew-list']);
    window.location.href = environment.rootUrl + `crew-list/`
  }

  // For navigation the path
  goList() {
    window.location.href = environment.rootUrl + `crew-list/`
  }
}
