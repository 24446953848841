import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import {MessageService} from 'primeng/api';
@Component({
  selector: 'app-crew-transaction-history',
  templateUrl: './crew-transaction-history.component.html',
  styleUrls: ['./crew-transaction-history.component.scss']
})
export class CrewTransactionHistoryComponent implements OnInit {
  products:any = []
  totalRecords: any
  loading = false;
  first = 0;
  rows = 10;

  /**
  * @constructor
  * @param {UserService} userService - The subservice service for call API.
  * @param {MessageService} messageService - For showing message.
  */
  constructor(
    private messageService: MessageService,
    private userService:UserService) { }

  ngOnInit(): void {
    this.getTransactionsHistoryData()
  }

   /**
   * For geting the data.
   * @method getTransactionsHistoryData
   * @returns { Array } Array
   */
  getTransactionsHistoryData(){
    this.products = []
    this.loading = true;
    this.userService.transactionHistory().subscribe((x:any)=>{
      this.loading = false;
      for (const iterator of x) {
        let item: any = Object.assign({}, iterator);
        this.products.push(item);
        }
      this.totalRecords = this.products.length
    },(error : any) =>{
      this.loading = false;
      this.totalRecords = this.products.length
      this.messageService.add({ severity: 'error', 
      summary: 'Error', 
      detail: error.error.error.message });
    })
  }

}
