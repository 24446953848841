import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';

import { LySliderChange, STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import {
  STYLES as CROPPER_STYLES,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent
} from '@alyle/ui/image-cropper';
import { StyleRenderer, ThemeRef, ThemeVariables, lyl } from '@alyle/ui';
import { LY_DIALOG_DATA, LyDialogRef } from '@alyle/ui/dialog';
import { STYLES as BUTTON_STYLES } from '@alyle/ui/button';
const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);
  const button = ref.selectorsOf(BUTTON_STYLES);
  const { after } = _theme;
  return {
    root: lyl`{
      ${cropper.root} {
        // max-width: 320px
        height: 332px
      }
    }`,

    sliderContainer: lyl`{
      text-align: center
      // max-width: 400px
      margin: auto
    }`,
    slider: lyl`{
      padding: 1em
    }`,
    flex: lyl`{
      display:flex
    }`,
    accesscenter: lyl `{
      display: flex
      justify-content: center
      ${button.root} {
        margin-${after}: 1em
      }
    }`,
  };
};

@Component({
  selector: 'app-crew-profile-image-cropper-modal',
  templateUrl: './crew-profile-image-cropper-modal.component.html',
  styleUrls: ['./crew-profile-image-cropper-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrewProfileImageCropperModalComponent implements OnInit {

  readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  // croppedImage?: string | null = null;
  croppedImage: any;
  ready: boolean = false;
  scale!: number;
  minScale!: number;
  @ViewChild(LyImageCropper, { static: true }) cropper!: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 250,
    height: 131,
    type: 'image/png',
    keepAspectRatio: false,
    minWidth: 1200,
    minHeight: 630,
    responsiveArea: true,
    output: {
      width: 1200,
      height: 630
    },
    // resizableArea: true,
    // round: true,
  };
  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef
  ) {

  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    // this.dialogRef.afterOpened.subscribe(() => {
    //   this.cropper.selectInputEvent(this.event);
    // });
  }

  onCropped(e: ImgCropperEvent) {
    // this.croppedImage = e.dataURL;
    // let cropimage = {
    //   content: e.dataURL,
    //   name: e.name,
    //   type: e.type,
    //   // originalDataURL: e.originalDataURL
    // }
    // alert( e.dataURL)
    // this.croppedImage = cropimage.dataURL
    // console.log('cropped img file: ', this.croppedImage);
    // console.log('cropped img filename: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    // this.croppedImage = e.originalDataURL;
    // console.log('img loaded', this.croppedImage);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
    this.dialogRef.close();
  }
  onSliderInput(event: LySliderChange) {
    this.scale = event.value as number;
  }


}
