import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CommonService } from '../common.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  features: any[] = [];
  logForm: FormGroup;
  logForm2: FormGroup;
  logForm3: FormGroup;
  submitted: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  div1 = true
  div2 = false
  div3 = false
  id: any
  ResendOTPDiv: boolean = false
  timerDiv: boolean = false
  timeLeft: number = 60;
  interval: any;
  display5: boolean = false;
  showPassword = false;
  showConnPassword = false;
  password: string = 'password';
  connPassword: string = 'password';

  /**
  * @constructor
  * @param {CommonService} commonservice - The commonsservice service for call API.
  * @param {MessageService} messageService - For showing message.
  * @param {Router} router - For getting router details.
  * @param {ActivatedRoute} activated - For getting router details.
  * @param {FormBuilder} formBuilder - For getting for form control.
  */
  constructor(
    private router: Router,
    private activated: ActivatedRoute,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private commonservice: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // Form declaration
    this.logForm = this.formBuilder.group({
      email: new FormControl('', Validators.required),
    });
    this.logForm3 = this.formBuilder.group({
      otp: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
    this.logForm2 = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$/)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    },
      { validator: this.checkPasswords }
    );
  }

  // For form controls
  get f() { return this.logForm.controls; }
  get g() { return this.logForm2.controls; }
  get h() { return this.logForm3.controls; }

  // For password check and match
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    return pass === confirmPass ? null : { notSame: true }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  ngOnInit(): void {
    // this.features = [
    //   { title: 'Please provide important text', image: 'live-collaboration.svg', text: 'Please provide content' },
    //   { title: 'Please provide important text', image: 'security.svg', text: 'Please provide content' },
    //   { title: 'Please provide important text', image: 'subscribe.svg', text: 'Please provide content' }
    // ];
  }

  /**
   * Toggles the visibility of the password input field.
   * @param {number} id - The id of the input field to toggle.
   */
  onClick(id: number) {
    if (id == 1) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
    if (id == 2) {
      this.showConnPassword = !this.showConnPassword;
      this.connPassword = this.showConnPassword ? 'text' : 'password';
    }
  }


  /**
   * First steps.
   * @method onSubmit
   * @returns { Object } Object
   */
  onSubmit() {
    this.submitted = true;
    if (this.logForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return;
    }
    this.commonservice.forgotPassword(this.logForm.value).subscribe((x) => {
      if (x) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'An email has been sent to the supplied email address. Follow the instruction in the email to reset your password'
        });
        this.div2 = true;
        this.div1 = false;
        this.timerDiv = true
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.timerDiv = false
            this.ResendOTPDiv = true;
            this.timeLeft = 60;
          }
        }, 1000)
      }
    }, (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    }
    )
  }

  /**
   * Second step otp send to email.
   * @method getOtp
   * @returns { Object } Object
   */
  getOtp() {
    this.submitted3 = true;
    if (this.logForm3.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return;
    }
    let obj: any = {}
    let otp = this.logForm3.value.otp;
    obj['otp'] = parseInt(otp.replace(/\s/g, ""));
    this.commonservice.verifyOtp(obj).subscribe((x: any) => {
      if (x) {
        this.id = x['id']
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'One time password verified successfully'
        });
        this.div2 = false;
        this.div3 = true
      }
    },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error', detail: error.error.error.message
        });
      }
    )

  }

  /**
   * Third step for change password.
   * @method changePassword
   * @returns { Object } Object
   */
  changePassword() {
    this.submitted2 = true;
    if (this.logForm2.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill up all the required fields'
      });
      return;
    }
    let obj: any = {}
    obj = this.logForm2.value
    obj['id'] = this.id
    this.commonservice.completePassword(obj).subscribe((x) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Record updated successfully'
      });
      //this.router.navigate(['/']);  // redirect to sign in page
      setTimeout(() => {
        this.router.navigateByUrl('/')
      }, 1000)
    },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message
        });
      }
    )
  }

  /**
    * Resend otp.
    * @method resendOTP
    * @returns { Object } Object
    */
  resendOTP() {
    let obj: any = {}
    obj['email'] = this.logForm.value.email
    this.commonservice.forgotPassword(obj).subscribe((x: any) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'An email has been sent to the supplied email address. Follow the instruction in the email to reset your password'
      });
    }, (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }

  // Navigate path to sign in
  backToSignUp() {
    clearInterval(this.interval);
    this.timeLeft = 60;
    this.ResendOTPDiv = false;
    this.div2 = false
    this.div1 = true
  }

  // Show dialog box
  showDialog4() {
    this.display5 = true;
  }

}