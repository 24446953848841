import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserService } from '../../user.service';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
  selector: 'app-crew-profile',
  templateUrl: './crew-profile.component.html',
  styleUrls: ['./crew-profile.component.scss']
})
export class CrewProfileComponent implements OnInit {
  display1: boolean = false;
  display2: boolean = false;
  display3: boolean = false;
  profileData: any = {}
  profileId: any
  address: any;
  userId: any
  pageLoader: boolean = false
  mediaType: any;
  showMore: boolean = false;
  status: boolean = false;
  professionalInfo: any
  personalInfo: any
  skillInfo: any
  attribute: any
  contactInfo: any
  displayCustom: boolean = false;
  activeIndex: number = 0;
  photos: any = []
  age: any
  userSubscribe: any = false

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  /**
  * @constructor
  * @param {UserService} userservice - The userservice service for call API.
  * @param {MessageService} messageservice - Message is used to display alerts inline.
  * @param {Location} _location - For back API go to last page.
  */
  constructor(private userservice: UserService,
    private messageservice: MessageService,
    private _location: Location
  ) {
    // this.route.paramMap.subscribe((paramMap: ParamMap) => {
    //   this.userId = paramMap.get("id");
    //   console.log(this.userId)
    //   if(this.userId != null){
    //     this.getProfileData()
    //   }
    // });
  }

  ngOnInit(): void {
    this.getInfo()
    this.getProfileData()

  }

  // for click on image shown in gallery pop up
  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
    this.pageLoader = true
    setTimeout(() => {
      this.pageLoader = false;
    }, 2000)
  }

  imageHides(event: any) {
    this.status = false
  }

  // for dialgbox.
  showDialog() {
    this.display1 = true;
  }

  // for dialgbox. 
  show() {
    this.display2 = true;
  }

  // back to previous page.
  backClicked() {
    this._location.back();
  }

  // for dailog box of connect button.
  showConnect() {
    this.display3 = true;
  }

  public calculateAge(birthdate?: any): number {
    let age = moment().diff(birthdate, 'years');
    return age;
  }


  /**
  * Get information of logged in user.
  * @method getInfo
  * @returns { Object } Object
  */
  getInfo() {
    this.userservice.getUserData().subscribe((x: any) => {
      if (x.subscribed == 0 && (x.address.countryCode == 'US' || x.address.countryCode == 'CA')) {
        this.userSubscribe = true
      } else {
        this.userSubscribe = false
      }
    })
  }
  /**
   * Get information.
   * @method getProfileData
   * @returns { Object } Object
   */
  getProfileData() {
    this.pageLoader = true
    this.userservice.getProfileDetailsId().subscribe((data: any) => {      
      this.pageLoader = false
      let tempData: any = {}
      tempData = data
      if (tempData.contactInfo.email != null || tempData.contactInfo.email != '') {
        delete tempData.contactInfo.email
        this.profileData = tempData
        this.age = this.calculateAge(this.profileData.dob)
        //console.log(this.age)
      }
      this.personalInfo = Object.keys(this.profileData.personalInfo).length
      this.professionalInfo = Object.keys(this.profileData.professionalInfo).length
      this.skillInfo = Object.keys(this.profileData.skillAndTrainings).length
      this.attribute = Object.keys(this.profileData.attributes).length
      this.contactInfo = Object.keys(this.profileData.contactInfo).length
      let videos: any[] = [];
      let photos: any[] = [];
      let voices: any[] = [];
      if (this.profileData.medias.length > 0) {
        this.profileData.medias.forEach((item: any) => {
          if (item.mediaType == '1' && photos.length < 6)
            photos.push(item)
          if (item.mediaType == '2' || item.mediaType == '4')
            videos.push(item)
          if (item.mediaType == '3' || item.mediaType == '5')
            voices.push(item)
        });
      }
      this.profileData.photos = photos;
      this.profileData.videos = videos;
      this.profileData.voices = voices;
      this.photos = photos
      //console.log("Profile Data",this.profileData)
    }, (error: any) => {
      this.pageLoader = false
      this.messageservice.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.error.message
      });
    })
  }
  // imageOpen(event : any){
  //   this.status = true    
  // }
  // imageHide(event : any){
  //   this.status = false    
  // }
  // get id of youtube video
  getId(url: any) {
    var urlsplit = url.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
    return urlsplit[3]
  }
  // convert into feet.
  toFeet(n: number) {
    let realFeet = ((n * 0.393700) / 12);
    let feet = Math.floor(realFeet);
    let inches = Math.round((realFeet - feet) * 12);
    let amp = String.fromCodePoint(0x00002032);
    return feet + `${amp}` + ' ' + inches + `${amp}${amp}` + ` / ` + n + `cm`;
  }
  getWhatsAppLink(): string {
    const whatsappNumber = this.profileData.contactInfo.whatsAppNumber;
    const phoneNumber = whatsappNumber.replace(/\D/g, '');
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    return whatsappLink;
  }

}
