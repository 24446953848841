<div class="surface-ground px-4 py-7 md:px-6 lg:px-8">
    <div class="flex flex-wrap shadow-2 container">
        <div class="w-full md:w-6 bg-blue-50">
            <!-- <img src="assets/images/bastion-700.svg" alt="Image" height="35" class="mb-6"> -->
            <!-- <p-carousel [value]="features">
                <ng-template let-feature pTemplate="item">
                    <div class="text-center mb-8">
                        <img src="assets/images/blocks/illustration/{{feature.image}}" alt="Image" class="mb-6 w-6">
                        <div class="mx-auto text-xl mb-4 text-blue-900">{{feature.title}}</div>
                        <p class="m-0 text-blue-700 line-height-3">{{feature.text}}</p>
                    </div>
                </ng-template>
            </p-carousel> -->
            <div class="text-center relative h-full">
                <img src="assets/images/signup.jpg" alt="Join the World's Largest Crew Resume Database!" class="signup-banner">
                <div class="banner-text p-3">
                    <div class="mx-auto">
                        <div class="text-2-line">Each Crew Resumes webpage is 
                        designed to 
                        give the producer quick results leaving no question unanswered and 
                        therefore eliminating time consuming calls, emails and google searches 
                        to hire the crew they need.
                        <br><br>International Cast and Crew  outside of USA and 
                        Canada are completely free to become a member.
                        </div>
                        <div class="flex mt-3 justify-content-center">
                            <button class="p-button-outlined" pButton label="Read More" (click)="showDialog4()"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full md:w-6 p-4 lg:p-7 surface-card" *ngIf="signupDiv">
            <div class="flex align-items-center justify-content-between mb-7">
                <span class="text-2xl text-900">Sign Up</span>
                <a tabindex="0" class="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" style="text-decoration: none;" (click)="goToLogin()">Log In</a>
            </div>
            <!-- [formGroup]="signupForm" used-->
            <form [formGroup]="signupForm">
                <div class="mb-2">
                    <label for="name" class="block text-900 mb-2">Name</label>
                    <input id="name" type="text" pInputText class="w-full" autocomplete="off"
                    formControlName="name" placeholder="Enter Name" [ngClass]="{ 'ng-dirty': submitted1 && f['name'].errors }">
                    <div *ngIf="submitted1 && f['name'].errors" class="invalid-feedback">
                        <small *ngIf="f['name'].errors['required']" class="p-error block">Name is required</small>
                    
                    </div>
                </div>
                <div class="mb-2">
                    <label for="dob" class="block text-900 mb-2">Date of Birth <small class="text-600"><i>This information is not displayed on your profile</i></small></label>
                    <!-- <input pInputText type="date" class="w-full"
                    formControlName="dob"
                    [ngClass]="{ 'ng-dirty': submitted1 && f['dob'].errors }"> -->
                    <!-- <p-calendar  placeholder="Enter Date of Birth" styleClass="w-full"
                        formControlName="dob" (onSelect)="dobPick($event)" [maxDate]="maxDate"
                        [ngClass]="{ 'ng-dirty': submitted1 && f['dob'].errors }">
                    </p-calendar> -->
                    <!-- <p-calendar styleClass="w-full"
                        [maxDate]="maxDate"
                        formControlName="dob"
                        (onSelect)="dobPick($event)"
                        appendTo="body" 
                        [showIcon]="true"
                        (onInput)="onKeyDate($event)"
                        [showOnFocus]="false"
                        [ngClass]="{ 'ng-dirty': submitted1 && f['dob'].errors }"
                        placeholder="Enter Date of Birth (MM/DD/YYYY)">
                    </p-calendar> -->

                    <p-inputMask   formControlName="dob" mask="99/99/9999" autocomplete="off"
                    [ngClass]="{ 'ng-dirty': submitted1 && f['dob'].errors }"
                    styleClass="w-full"  placeholder="Enter Date of Birth"></p-inputMask>

                    <div *ngIf="submitted1 && f['dob'].errors" class="invalid-feedback">
                   <small *ngIf="f['dob'].errors['required']" class="p-error block">Date of Birth is
                       required</small>
               </div>
                </div>
                <div class="mb-2">
                    <label for="name" class="block text-900 mb-2">Email</label>
                    <input id="name" type="text" pInputText class="w-full" autocomplete="off"
                    formControlName="email" placeholder="Enter Email" [ngClass]="{ 'ng-dirty': submitted1 && f['email'].errors }" pInputText>
                    <div *ngIf="submitted1 && f['email'].errors" class="invalid-feedback">
                        <small *ngIf="f['email'].errors['required']" class="p-error block">Email is required</small>
                       
                    </div>
                </div>
               
                <div class="mb-2">
                    <label for="password" class="block text-900 mb-2">Password</label>
                    <input id="password" [type]="password" pInputText class="w-full" autocomplete="off"
                    formControlName="password" placeholder="Enter Password"  [ngClass]="{ 'ng-dirty': submitted1 && f['password'].errors } " pInputText>
                    <span class="eye-tpoggle" (click)="onClick(1)">
                        <i class="pi pi-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                        <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
                    </span>
                    <small class="text-600"><i>Password must be at least 8
                        characters</i></small>
                    <div *ngIf="submitted1 && f['password'].errors" class="invalid-feedback">
                        <small *ngIf="f['password'].errors['required']" class="p-error block">Password is required</small>
                        <!-- <small *ngIf="f['password'].value.length < 8" class="p-error block">Password must be at least 8
                            characters</small> -->
                    </div>
                    <div *ngIf="f['password'].errors" class="invalid-feedback">
                        <small *ngIf="f['password'].errors['pattern']" class="p-error block">Password must contain more than eight characters, at least one uppercase letter, at least one lowercase letter, at least one number, at least one special character($@$!%*?&)
                        </small>
                    </div>
                    <!-- <div  *ngIf="submitted1 && signupForm.controls['password'].value.length < 8">
                        <small class="p-error block">Password length must be minimum 8 characters</small>
                    </div>  -->
                </div>
                
                <div class="mb-4">
                    <label for="confirmPassword" class="block text-900 mb-2">Confirm Password</label>
                <input id="confirmPassword" [type]="connPassword" pInputText class="w-full" autocomplete="off"
                formControlName="confirmPassword" placeholder="Enter Confirm Password" [ngClass]="{ 'ng-dirty': submitted1 && f['confirmPassword'].errors }" pInputText>
                <span class="eye-tpoggle" (click)="onClick(2)">
                    <i class="pi pi-eye" aria-hidden="true" *ngIf="!showConnPassword"></i>
                    <i class="pi pi-eye-slash" aria-hidden="true" *ngIf="showConnPassword"></i>
                </span>
                <small class="text-600"><i>Password must be at least 8
                    characters</i></small>
                <div *ngIf="submitted1 && f['confirmPassword'].errors" class="invalid-feedback">
                    <small *ngIf="f['confirmPassword'].errors['required']" class="p-error block">Confirm Password is required</small>
                </div>
                <div *ngIf="submitted1 && signupForm.hasError('notSame')" class="invalid-feedback">
                    <small class="p-error block">Passwords do not match</small> 
                 </div> 
                </div>
                <div class="mb-2">
                    <div class="flex ">
                        <p-checkbox styleClass="postion-start" formControlName="termsAgreed" [binary]="true"
                            inputId="termsAgreed" [ngClass]="{ 'ng-dirty': submitted1 && f['termsAgreed'].errors}"></p-checkbox>
                        <label for="termsAgreed" class="font-weight-bold">I agree to the <a
                            class="no-underline text-blue-500 cursor-pointer" (click)="redirect('term-of-service')">Terms of Service.</a></label>
                        </div>
                    
                            <div *ngIf="submitted1 && (f['termsAgreed'].errors || f['termsAgreed'].value == false)" class="invalid-feedback mt-1">
                                <small class="p-error block">Please agree with our terms of service</small>
                            </div>
                            
                </div>

                <div class="mb-2">
                    <div class="flex">
                        <p-checkbox styleClass="postion-start" formControlName="newsletterSubscribed" [binary]="true"
                            inputId="newsletterSubscribed"></p-checkbox>
                        <label for="newsletterSubscribed" class="font-weight-bold">I agree to receive improtant account updates and promotions offer from
                            Crew Resumes (Optional).</label>
                    </div>
                </div>
               <!-- on  click signUp -->
                <div>
                    <button pButton pRipple label="Sign Up" [disabled]="isdisabled" (click)="signUp()" class="w-full"></button>
                </div>
            </form>
        </div>

        <div class="w-full lg:w-6 p-4 lg:p-7 surface-card" *ngIf="otpDiv">
            <div class="flex align-items-center justify-content-between mb-7">
                <span class="text-2xl text-900">OTP Verification</span>
                <a tabindex="0" class="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" style="text-decoration: none;"  (click)="backToLogin()">Back to Log In</a>
            </div>
            <!-- [formGroup]="otpForm" (ngSubmit)="otpVerify()" -->
           <form [formGroup]="otpForm" (ngSubmit)="otpVerify()">
                <div class="form-group mb-4">
                    <div class="field mb-4 mt-2">
                        <div class="text-700">Check your email inbox and spam folder too. We've sent you the OTP at <b>{{f['email'].value}}</b>.<br>Wrong Email ID? Click <a href="javascript:void(0)" (click)="backToSignUp()">here</a> to go back.</div>
                    </div>
                    <label for="otp" class="block text-900 mb-2">OTP</label>
                    <p-inputMask  formControlName="otp" mask="9 9 9 9 9 9" autocomplete="off"
                    [ngClass]="{ 'ng-dirty': submitted3 && h['otp'].errors }"
                    styleClass="w-full form-control" placeholder="Enter OTP"></p-inputMask>
                    <div *ngIf="submitted3 && h['otp'].errors" class="invalid-feedback">
                        <small *ngIf="h['otp'].errors['required']" class="p-error block">OTP is required</small>
                    </div>
                    <div class="field mb-4 mt-2 text-right" >
                        <span class="text-700" *ngIf="timerDiv">Resend OTP in {{timeLeft}} Seconds.</span>
                        <span class="text-red-600 cursor-pointer" (click)="resendOTP()" *ngIf="ResendOTPDiv">Resend OTP</span>
                    </div>
                </div>
                <p></p>
                <!-- on click submit button  -->
                <div class="form-group">
                    <button pButton pRipple label="Verify OTP" [disabled]="isdisabled" class="w-full "></button>
                </div>  
            </form>      
        </div>
    </div>
</div>
<!-- read more dialog box -->
<p-dialog header="Welcome to Crew Resumes" [(visible)]="display5" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div>
       <p>Each Crew Resumes webpage is 
        designed to 
        give the producer quick results leaving no question unanswered and 
        therefore eliminating time consuming calls, emails and google searches 
        to hire the crew they need.
        </p>
        <div class="mt-2">International Cast and Crew  outside of USA and 
        Canada are completely free to become a member.</div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display5=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- toast msg alert -->
<p-toast position="top-right"></p-toast>